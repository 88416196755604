import ReviewCartView from "../../../Views/CheckoutV2/ReviewCart/ReviewCartView";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { env } from "../../../env";
import {
  getDateNowInUtc,
  generateTransactionId,
  generateUniqueNumberString,
  calculateOverSeasPrice,
  formatToTwoFractionalDigits,
  getToFixeValue,
  isDateWithinNextTwoDays,
  removePastDatesByNameAndDate,
  getUpcomingDate,
  getDateFormatedForPujaDetails,
  getNormalizedDateTime,
} from "../../../utils/HelperFunctions";
import { OrderService, CommonService, CouponService } from "td_server";
import {
  setPujaMembers,
  setSelectedPuja,
  setSelectedTempleDetailsForPuja,
} from "../../../store/slices/pujaSlice";
import {
  emptyCartAction,
  updateCartAction,
} from "../../../store/actions/cartAction";
import { setSelectedAddressInCart } from "../../../store/slices/cartSlice";
import { placeOrderAction } from "../../../store/actions/orderAction";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  Snackbar,
  Typography,
} from "@mui/material";
import successIcon from "../../../assets/success.png";
import errorIcon from "../../../assets/error.png";
import CartNoItem from "../../../components/card/Cart/NoItemInCart";
import { updateCouponsUsageAction } from "../../../store/actions/CouponCodeAction";
import templeLogo from "../../../assets/temple-logo-icon.png";
import { addAnalytics } from "../../../store/actions/AnalyticsAction";
import { orderType } from "../../../utils/Constants";
import { setSelectedAddressId } from "../../../store/slices/userSlice";
import BookingDateConfirmDialog from "../../../components/dialog/BookingDateConfirmDialog";
import { LOCALIZATION } from "../../../utils/localization";
import { t } from "i18next";

function ReviewCartScreen() {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const [chooseAddressBox, setChooseAddressBox] = useState(false);
  const [orderCreatedScreen, setOrderCreatedScreen] = useState(false);
  const [orderResultDetails, setOrderResultDetails] = useState("");
  const { cartInfo, selectedAddressInCart } = useSelector(
    (state: any) => state.cart
  );

  const [errorPopup, setErrorPopUp]: any = useState({
    isError: false,
    errorMessages: [],
  });
  const [bookingFailedPopup, setBookingFailedPopUp]: any = useState(false);
  const [couponPopup, setCouponPopUp]: any = useState(false);
  const [selectedCartItem, setSelectedCartItem]: any = useState(null);
  const [allActiveCoupons, setAllActiveCoupons] = useState<any>([]);
  const [panditDakshina, setPanditDakshina] = useState<number>(0);
  const [showDeliveryAlert, setShowDeliveryAlert] = useState(false);
  const [bookingDateConfirmDialogOpen, setBookingDateConfirmDialogOpen] =
    useState<boolean>(false);
  const [bookingDateInConfirmDialog, setbookingDateInConfirmDialog] =
    useState<string>("");
  const [newDateDateInConfirmDialog, setNewDateDateInConfirmDialog] =
    useState<string>("");
  const [
    isBookingConfirmedOnUpcomingDate,
    setIsBookingConfirmedOnUpcomingDate,
  ] = useState<boolean>(false);
  const [outSideIndiaMessagePopup, setOutSideIndiaMessagePopup] =
    useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (couponPopup) {
      // setIsPackageCouponCode(false);
    }
  }, [couponPopup]);

  const [chadhavaList, setChadhavaList] = useState<any>([]);

  const { templeChadhavaList } = useSelector((state: any) => state.chadhawa);
  const { templePrasadList } = useSelector((state: any) => state.prasad);

  useEffect(() => {
    if (cartInfo[0] && cartInfo[0]?.orderType === orderType.chadhava) {
      if (templeChadhavaList?.length > 0) {
        const updatedResponse = templeChadhavaList.map((item: any) => ({
          ...item,
          count: 0,
        }));
        setChadhavaList(updatedResponse);
        //setChadhavaList(templeChadhavaList);
      }
    }
  }, [templeChadhavaList]);

  // Place order upon suggesting new booking date:

  useEffect(() => {
    if (isBookingConfirmedOnUpcomingDate) {
      placeOrder();
    }
  }, [isBookingConfirmedOnUpcomingDate]);

  useEffect(() => {
    if (cartInfo[0] && cartInfo[0]?.orderType === orderType.prasad) {
      if (templePrasadList?.length > 0) {
        const updatedResponse = templePrasadList.map((item: any) => ({
          ...item,
          count: 0,
          disabled: false,
        }));
        setChadhavaList(updatedResponse);
      }
    }
  }, [templePrasadList]);

  // useEffect(() => {
  //   if (selectedCartItem?.templeDetails?.id) {

  //     dispatch(getChadhavaForTempleList(selectedCartItem.templeDetails.id));
  //     //getChadhavaForTemple(templeId);
  //   }
  // }, [selectedCartItem?.templeDetails?.id]);

  // const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [couponApplyResponse, setCouponApplyResponse] = useState({
    isValid: false,
    isApplied: false,
    finalPrice: 0,
    couponData: null,
    isError: false,
    errorDetails: "",
    actualPujaPrice: 0,
    discount: 0,
    gst: 0,
    priceBreakup: {},
  });
  const [couponCodeData, setCouponCodeData]: any = useState("");
  const [priceBreakupData, setPriceBreakupData]: any = useState({
    actualPujaPrice: 0,
    gst: 0,
    finalPrice: 0,
    finalPriceOv: 0,
    priceBreakup: null,
  });
  const [previousPriceData, setPreviousPriceData]: any = useState("");
  const [avialableCouponCode, setAvailableCouponCode]: any = useState(null);
  const { selectedPuja, selectedTempleDetailsForPuja, pujaMembers } =
    useSelector((state: any) => state.puja);

  var { userInfo, selectedAddressId } = useSelector((state: any) => state.user);
  const [openChooseAddressBoxForPrasad, setOpenChooseAddressBoxForPrasad] =
    useState<boolean>(false);

  const userPersonalInfo =
    userInfo && userInfo.personalInfo ? userInfo.personalInfo : {};
  const pujaDetails = selectedCartItem ? selectedCartItem.pujaDetails : null;
  const templeDetails = selectedCartItem
    ? selectedCartItem.templeDetails
    : null;
  const pujaPackage = selectedCartItem ? selectedCartItem.pujaPackage : null;
  const memberDetails = selectedCartItem ? selectedCartItem.members : [];
  const windowRefrence: any = window;

  const [currencySign, setCurrencySign] = useState<string>("₹");

  const priceConversionState = useSelector(
    (state: any) => state.priceConversion
  );
  const {
    rate = 1,
    currencySymbols = "₹",
    isOverseas = false,
    currencyCode = "INR",
    countryCode = null,
  } = priceConversionState.priceConversionRate || {};
  const [isPrasad, setIsPrasad] = useState<boolean>(false);
  const getOverSeasPriceByRateText = (
    inrPrice: number,
    isToConvertPrice = false
  ) => {
    if (cartInfo[0]?.orderType === orderType.prasad) {
      return `₹ ${inrPrice}`;
    }
    return `${currencySymbols} ${
      isToConvertPrice ? inrPrice : calculateOverSeasPrice(inrPrice, rate)
    }`;
  };
  const getSymbolWithPrice = (priceValue: any) => {
    if (cartInfo[0]?.orderType === orderType.prasad) {
      return `₹ ${priceValue}`;
    } else {
      return `${currencySymbols} ${priceValue}`;
    }
  };
  useEffect(() => {
    if (cartInfo[0]?.orderType === orderType.prasad) {
      setCurrencySign("₹");
    } else {
      setCurrencySign(currencySymbols);
    }
  }, [currencySymbols]);

  // useEffect(()=>{
  //   if(isPrasad && isOverseas){
  //     setChooseAddressBox(true)
  //   }
  // },[isPrasad,isOverseas])

  useEffect(() => {
    if (env === "prod") {
      windowRefrence?.fbq("track", "AddToCart");
    }
    setSelectedCartItem(cartInfo[0]);
    if (userPersonalInfo.address && userPersonalInfo.address.length > 0) {
      let selectedUserAddress: any = null;
      if (cartInfo[0]?.orderType !== orderType.puja) {
        selectedUserAddress = userPersonalInfo?.address?.filter(
          (item: any) =>
            item?.id === selectedAddressId && item?.countryCode == "IN"
        );
      } else {
        selectedUserAddress = userPersonalInfo?.address?.filter(
          (item: any) => item.id === selectedAddressId && item?.countryCode
        );
      }

      //debugger;
      // const selectedUserAddress = userPersonalInfo?.address?.filter(
      //   (item: any) =>
      //     item?.id === selectedAddressId && item?.countryCode == "IN"
      // );
      if (selectedUserAddress) {
        dispatch(setSelectedAddressInCart(selectedUserAddress[0]));
      }
      if (!selectedAddressId) {
        const addressWith = userPersonalInfo?.address?.filter(
          (item: any) => item?.countryCode
        );
        if (addressWith.length > 0) {
          dispatch(setSelectedAddressInCart(userPersonalInfo?.address[0]));
          localStorage.setItem(
            "selectedAddressId",
            userPersonalInfo?.address[0]?.id
          );
          dispatch(setSelectedAddressId(userPersonalInfo?.address[0]?.id));
        }
      }
    }
  }, [cartInfo, userPersonalInfo]);

  useEffect(() => {
    if (
      cartInfo[0] &&
      [orderType.chadhava, orderType.prasad].includes(cartInfo[0]?.orderType)
    ) {
      setCouponApplyResponse({
        ...couponApplyResponse,
        isApplied: false,
        isValid: false,
      });
      setPriceQuoteForChadhava();
    }
  }, [cartInfo]);

  // useEffect(() => {
  //   CouponService.getAllActiveCouponsByDate().then((res: any) => {
  //     setAllActiveCoupons(res?.couponList);
  //   });
  // }, []);

  useEffect(() => {
    if (allActiveCoupons && allActiveCoupons.length > 0) {
      //let couponData = allActiveCoupons?.filter((item: any) => item?.shouldBeAutoApply === true)[0];
      let couponData = allActiveCoupons[0];
      if (couponData) {
        checkValidityOfcoupon(couponData.couponCode);
      }
    }
  }, [allActiveCoupons]);

  useEffect(() => {
    if (selectedCartItem) {
      if (
        [orderType.chadhava, orderType.prasad]?.includes(
          selectedCartItem?.orderType
        )
      ) {
        if (couponApplyResponse.isApplied) {
          setPriceQuoteForChadhavaWithCouponData(couponApplyResponse);
        } else {
          setPriceQuoteForChadhava();
        }
      } else {
        if (couponApplyResponse.isApplied) {
          setPriceQuoteForPujaWithCouponData(couponApplyResponse);
        } else {
          setPriceQuoteForPuja();
        }
      }
    }
  }, [couponApplyResponse, selectedCartItem]);

  useEffect(() => {
    if (selectedCartItem && priceConversionState.priceConversionRate) {
      if (
        [orderType.chadhava, orderType.prasad]?.includes(
          selectedCartItem?.orderType
        )
      ) {
        setPriceQuoteForChadhava();
      } else {
        setPriceQuoteForPuja();
      }
    }
  }, [isPrasad, priceConversionState?.priceConversionRate]);

  //  useEffect(() => {
  //   if (selectedCartItem) {
  //     if (selectedCartItem?.orderType === orderType.chadhava) {
  //       if (couponApplyResponse.isApplied) {
  //         setPriceQuoteForChadhavaWithCouponData(couponApplyResponse);
  //       }
  //     } else {
  //       if (couponApplyResponse.isApplied) {
  //         setPriceQuoteForPujaWithCouponData(couponApplyResponse);
  //       }
  //     }
  //   }

  //  }, [priceBreakupData])

  const fetchCouponForPuja = async (
    templeDetails: any,
    pujaDetails: any,
    pujaPackage: any
  ) => {
    let couponsList: any = [];
    if (templeDetails && pujaDetails) {
      let response1 = await CouponService.getActiveCouponsByPujaIdCurrentDate(
        templeDetails?.id,
        pujaDetails?.pujaId,
        pujaPackage?.packageId
      );
      if (response1?.couponList && response1?.couponList?.length > 0) {
        couponsList = response1.couponList;
      }
    }

    let response3 = await CouponService.getAllActiveCouponsByDate();
    if (response3?.couponList && response3?.couponList?.length > 0) {
      couponsList = [...couponsList, ...response3.couponList];
      setAllActiveCoupons(couponsList);
    } else {
      couponsList = [...couponsList];
      setAllActiveCoupons(couponsList);
    }

    if (
      couponsList.length > 0 &&
      couponsList.length > avialableCouponCode?.length
    ) {
      setAvailableCouponCode(couponsList[0]);
    }
  };

  useEffect(() => {
    if (templeDetails && pujaDetails) {
      fetchCouponForPuja(templeDetails, pujaDetails, pujaPackage);
    }
  }, [templeDetails, pujaDetails, pujaPackage]);

  useEffect(() => {
    if (avialableCouponCode) {
      checkValidityOfcoupon(avialableCouponCode?.couponCode);
      setCouponCodeData(avialableCouponCode?.couponCode);
    }
  }, [avialableCouponCode]);

  useEffect(() => {
    const isAddressInIndia =
      selectedAddressInCart?.countryCode === "IN" ||
      typeof selectedAddressInCart?.countryCode === "undefined" ||
      selectedAddressInCart?.countryCode === null;
    if (isOverseas) {
      setIsPrasad(false);
    } else if (!isOverseas && isAddressInIndia) {
      setIsPrasad(false);
    } else if (!isOverseas && !isAddressInIndia) {
      setIsPrasad(false);
    }
  }, [
    priceConversionState.priceConversionRate,
    selectedAddressInCart,
    isOverseas,
  ]);

  const setPriceQuoteForPuja = async () => {
    if (
      [orderType.chadhava, orderType.prasad]?.includes(
        selectedCartItem?.orderType
      )
    ) {
      return;
    }
    let payload = {
      templeId: templeDetails?.id,
      pujaId: pujaDetails?.pujaId,
      packageId: pujaPackage?.packageId,
      isOverseas: isOverseas,
      isPrasad: isPrasad,
    };

    const pujaPriceData = await OrderService.getPriceDetailsOfPuja(payload);

    setPriceBreakupData({
      ...pujaPriceData,
    });
    setPreviousPriceData(pujaPriceData?.finalPrice);
  };

  const setPriceQuoteForPujaWithCouponData = (couponApplyResponse: any) => {
    if (couponApplyResponse && couponApplyResponse.priceBreakup) {
      setPriceBreakupData({
        ...couponApplyResponse,
      });
    }
  };

  const setPriceQuoteForChadhavaWithCouponData = (couponApplyResponse: any) => {
    if (couponApplyResponse && couponApplyResponse.priceBreakup) {
      const priceData = {
        ...couponApplyResponse,
        finalPrice:
          Number(couponApplyResponse.finalPrice) + Number(panditDakshina),
        actualFinalPrice: Number(couponApplyResponse.finalPrice),
      };
      setPriceBreakupData({
        ...priceData,
      });
    }
  };

  const calculateTotalPrice = () => {
    const selectedChadhvaList = selectedCartItem?.selectedChadhvaList?.filter(
      (item: any) => item?.count > 0
    );

    const totalPrices = selectedChadhvaList?.reduce(
      (totals: any, item: any) => {
        const finalPrice = Number(item.priceInfo.finalPrice) * item.count;
        const calculatedPrice =
          Number(item.priceInfo.calculatedPrice) * item.count;
        const basePrice = Number(item.priceInfo.basePrice) * item.count;
        const gst = Number(item.priceInfo.gst) * item.count;
        const platformFee = item.priceInfo.priceBreakup.platformFee
          ? Number(item.priceInfo.priceBreakup.platformFee) * item.count
          : 0;
        const adminCommission = item.priceInfo.priceBreakup.adminCommission
          ? Number(item.priceInfo.priceBreakup.adminCommission) * item.count
          : 0;

        const finalPriceOv = Number(
          item.count * Number(item?.priceInfo?.finalPriceOv)
        ).toFixed(2);

        totals.finalPriceTotal += finalPrice;
        totals.calculatedPriceTotal += calculatedPrice;
        totals.gstTotal += gst;
        totals.basePrice += basePrice;
        totals.platformFee += platformFee;
        totals.adminCommission += adminCommission;

        totals.finalPriceOv += Number(finalPriceOv);

        return totals;
      },
      {
        finalPriceTotal: 0,
        calculatedPriceTotal: 0,
        gstTotal: 0,
        basePrice: 0,
        platformFee: 0,
        adminCommission: 0,
        finalPriceOv: 0,
      }
    );

    const priceData = {
      actualPujaPrice: totalPrices?.calculatedPriceTotal,
      delivery: 0,
      gst: totalPrices?.gstTotal?.toFixed(2) || 0,
      finalPrice:
        Number(totalPrices?.finalPriceTotal) + Number(panditDakshina) || 0,
      calculatedPrice: totalPrices?.calculatedPriceTotal || 0,
      finalPriceOv:
        Number(
          Number(
            totalPrices?.finalPriceOv +
              Number(calculateOverSeasPrice(Number(panditDakshina), rate))
          ).toFixed(2)
        ) || 0,
      chadhavaValueItemFinalOv: Number(totalPrices?.finalPriceOv?.toFixed(2)),
      chadhavaValueItemFinal: Number(totalPrices?.finalPriceTotal?.toFixed(2)),
      priceBreakup: {
        basePrice: Number(totalPrices?.basePrice) || 0,
        delivery: 0,
        packaging: 0,
        goodies: 0,
        adminCommission: Number(totalPrices?.adminCommission) || 0,
        platformFee: Number(totalPrices?.platformFee) || 0,
        dakshina: Number(panditDakshina) || 0,
      },
    };
    setPriceBreakupData(priceData);
    return priceData;
  };

  // const getChadhavaTotalValue = (item: any) => {
  //   const selectedChadhvaList = selectedCartItem?.selectedChadhvaList?.filter(
  //     (item: any) => item?.count > 0
  //   );
  //   const totalPrices = selectedChadhvaList?.reduce(
  //     (totals: any, item: any) => {
  //       const finalPrice = Number(item.priceInfo.finalPrice) * item.count;
  //       totals.finalPriceTotal += finalPrice;
  //       return totals;
  //     },
  //     { finalPriceTotal: 0 }
  //   );
  //   return isOverseas
  //     ? priceBreakupData?.chadhavaValueItemFinalOv
  //     : totalPrices?.finalPriceTotal || 0;
  // };

  // const getChadhavaFinalPaymentValue = (item = {}) => {
  //   const selectedChadhvaList = selectedCartItem?.selectedChadhvaList?.filter(
  //     (item: any) => item?.count > 0
  //   );
  //   const totalPrices = selectedChadhvaList?.reduce(
  //     (totals: any, item: any) => {
  //       const finalPrice = Number(item.priceInfo.finalPrice) * item.count;
  //       totals.finalPriceTotal += finalPrice;
  //       return totals;
  //     },
  //     { finalPriceTotal: 0 }
  //   );
  //   return isOverseas
  //     ? priceBreakupData?.finalPriceOv
  //     : totalPrices?.finalPriceTotal || 0;
  // };

  const getChadhavaTotalValue = (item: any) => {
    // const selectedChadhvaList = selectedCartItem?.selectedChadhvaList?.filter(
    //   (item: any) => item?.count > 0
    // );
    // const totalPrices = selectedChadhvaList?.reduce(
    //   (totals: any, item: any) => {
    //     const finalPrice = Number(item.priceInfo.finalPrice) * item.count;
    //     totals.finalPriceTotal += finalPrice;
    //     return totals;
    //   },
    //   { finalPriceTotal: 0 }
    // );
    // return isOverseas
    //   ? priceBreakupData?.chadhavaValueItemFinalOv
    //   : totalPrices?.finalPriceTotal || 0;

    if (selectedCartItem?.orderType === orderType.prasad) {
      return priceBreakupData?.chadhavaValueItemFinal;
    }
    return isOverseas
      ? priceBreakupData?.chadhavaValueItemFinalOv
      : priceBreakupData?.chadhavaValueItemFinal;
  };

  const getChadhavaFinalPaymentValue = (item = {}) => {
    // const selectedChadhvaList = selectedCartItem?.selectedChadhvaList?.filter(
    //   (item: any) => item?.count > 0
    // );
    // const totalPrices = selectedChadhvaList?.reduce(
    //   (totals: any, item: any) => {
    //     const finalPrice = Number(item.priceInfo.finalPrice) * item.count;
    //     totals.finalPriceTotal += finalPrice;
    //     return totals;
    //   },
    //   { finalPriceTotal: 0 }
    // );
    if (selectedCartItem?.orderType === orderType.prasad) {
      return priceBreakupData?.finalPrice || 0;
    }
    return isOverseas
      ? priceBreakupData?.finalPriceOv
      : priceBreakupData?.finalPrice || 0;
  };

  useEffect(() => {
    calculateTotalPrice();
    // const selectedChadhvaList = selectedCartItem?.selectedChadhvaList?.filter(
    //   (item: any) => item?.count > 0
    // );
    // const totalPrices = selectedChadhvaList?.reduce(
    //   (totals: any, item: any) => {
    //     const finalPrice =
    //       Math.round(Number(item.priceInfo.finalPrice)) * item.count;
    //     totals.finalPriceTotal += finalPrice;
    //     return totals;
    //   },
    //   { finalPriceTotal: 0 }
    // );

    // let couponDiscount = 0;

    // if (couponApplyResponse && couponApplyResponse?.isApplied) {
    //   // couponDiscount =  couponApplyResponse?.discount
    //   setPriceBreakupData((prevState: any) => ({
    //     ...prevState,
    //     finalPrice:
    //       Number(couponApplyResponse?.finalPrice) + Number(panditDakshina),
    //   }));
    // } else {
    //   setPriceBreakupData((prevState: any) => ({
    //     ...prevState,
    //     finalPrice:
    //       Number(totalPrices?.finalPriceTotal) + Number(panditDakshina),
    //   }));
    // }
  }, [panditDakshina]);

  const setPriceQuoteForChadhava = () => {
    // const selectedChadhvaList = getSelectedChadhvaList() || [];
    // const totalPrices = selectedChadhvaList?.reduce(
    //   (totals: any, item: any) => {
    //     const finalPrice =
    //       Math.round(Number(item.priceInfo.finalPrice)) * item.count;
    //     const calculatedPrice =
    //       Math.round(Number(item.priceInfo.calculatedPrice)) * item.count;
    //     const gst = Math.round(Number(item.priceInfo.gst)) * item.count;

    //     totals.finalPriceTotal += finalPrice;
    //     totals.calculatedPriceTotal += calculatedPrice;
    //     totals.gstTotal += gst;
    //     return totals;
    //   },
    //   { finalPriceTotal: 0, calculatedPriceTotal: 0, gstTotal: 0 }
    // );

    // const priceData = {
    //   actualPujaPrice: totalPrices?.calculatedPriceTotal,
    //   delivery: 0,
    //   gst: totalPrices?.gstTotal?.toFixed(2),
    //   finalPrice: Number(totalPrices?.finalPriceTotal) + Number(panditDakshina),
    //   priceBreakup: {
    //     basePrice: 0,
    //     delivery: 0,
    //     packaging: 0,
    //     goodies: 0,
    //     adminCommission: 0,
    //     platformFee: 0,
    //   },
    // };

    // setPriceBreakupData({
    //   ...priceData,
    // });
    const priceData = calculateTotalPrice();
    setPreviousPriceData(Number(priceData?.finalPrice));
  };

  const changeDeliverytAddress = (address: any) => {
    localStorage.setItem("selectedAddressId", address?.id);
    dispatch(setSelectedAddressId(address?.id));
    dispatch(setSelectedAddressInCart(address));
  };

  const editPujaOptions = (selectedCartItem: any) => {
    dispatch(setSelectedTempleDetailsForPuja(selectedCartItem.templeDetails));
    dispatch(setSelectedPuja(selectedCartItem.pujaDetails));
    dispatch(setPujaMembers(selectedCartItem.members));
    navigate("/checkoutV2/addDevotee");
  };

  const clearCart = () => {
    dispatch(emptyCartAction());
    navigate("/temples");
  };

  const checkForAnyFormError = () => {
    let isErrorInForm = false;
    let errorMsg = [];
    if (
      !selectedAddressInCart &&
      [orderType.puja, orderType.prasad].includes(selectedCartItem?.orderType)
    ) {
      isErrorInForm = true;
      errorMsg.push({
        errType: "addressNotGiven",
        message: "Address not provided",
      });
    }

    if (!isPrasad && isOverseas) {
      isErrorInForm = false;
      errorMsg.push([]);
    }

    if (!memberDetails || memberDetails.length === 0) {
      isErrorInForm = true;
      errorMsg.push({
        errType: "memberNotSelected",
        message: "No members added for puja please add atleast 1 member",
      });
    }

    if (isErrorInForm) {
      setErrorPopUp({ isError: true, errorMessages: errorMsg });
      return false;
    }
    return true;
  };

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const handleBookingConfirm = () => {
    setSelectedCartItem((prev: any) => ({
      ...(prev || {}),
      pujaDateTime: {
        ...(prev?.pujaDateTime || {}),
        pujaDate: `${newDateDateInConfirmDialog} 10:30PM`,
      },
    }));
    setNewDateDateInConfirmDialog("");
    setbookingDateInConfirmDialog("");
    setBookingDateConfirmDialogOpen(false);

    // placeOrder();
    setIsBookingConfirmedOnUpcomingDate(true);
  };
  const handleBookingCancel = () => {
    setNewDateDateInConfirmDialog("");
    setbookingDateInConfirmDialog("");
    setBookingDateConfirmDialogOpen(false);
  };

  const handlePlaceOrder = (e: any) => {
    e.preventDefault();
    setNewDateDateInConfirmDialog("");
    setbookingDateInConfirmDialog("");
    const _bookingDate = selectedCartItem?.pujaDateTime?.pujaDate;
    let bookingDate = getNormalizedDateTime(_bookingDate);
    if (bookingDate && !isDateWithinNextTwoDays(bookingDate)) {
      let newDate = bookingDate || "";
      if (selectedCartItem.orderType === orderType.puja) {
        let removedPastD = removePastDatesByNameAndDate(
          selectedCartItem?.pujaDetails?.restrictionDate
        );
        newDate = getUpcomingDate(removedPastD[0]);
      } else if (selectedCartItem.orderType === orderType.chadhava) {
        let removedPastD = removePastDatesByNameAndDate(
          selectedCartItem?.localRestrictionChadavaDate
        );
        newDate = getUpcomingDate(removedPastD[0]);
      }
      setNewDateDateInConfirmDialog(newDate);
      setbookingDateInConfirmDialog(bookingDate);
      setBookingDateConfirmDialogOpen(true);
    } else {
      placeOrder();
    }
  };

  const placeOrder = async () => {
    // e.preventDefault();
    const res = await loadRazorpayScript();

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    //check for error
    const isFormValid = checkForAnyFormError();
    //error checking complete
    if (isFormValid) {
      // let basePrice = priceDetails.basePrice;
      let orderValue = Math.round(priceBreakupData?.finalPrice);
      const _basePrice = priceBreakupData?.priceBreakup?.basePrice;
      const priceBreakup: any = {
        basic: _basePrice,
        adminCommission: priceBreakupData?.priceBreakup?.adminCommission,
        delivery: priceBreakupData?.priceBreakup?.delivery,
        packaging: priceBreakupData?.priceBreakup?.packaging,
        goodies: priceBreakupData?.priceBreakup?.goodies,
        platformFee: priceBreakupData?.priceBreakup?.platformFee,
        gst: priceBreakupData.gst,
      };
      if (
        selectedCartItem?.orderType === (orderType.chadhava || orderType.prasad)
      ) {
        priceBreakup.dakshina = priceBreakupData?.priceBreakup?.dakshina;
      }

      let razorPayAmount: any = 0;
      let orderValueOv: any = null;

      if (selectedCartItem?.orderType !== orderType.prasad) {
        razorPayAmount =
          currencyCode === "INR"
            ? Number(Number(orderValue))
            : selectedCartItem?.orderType === orderType.chadhava && isOverseas
            ? getChadhavaFinalPaymentValue()
            : Number(
                calculateOverSeasPrice(Number(orderValue), rate, "number")
              );

        // console.log("razorPayAmount",razorPayAmount,Number(
        //   calculateOverSeasPrice(Number(orderValue), rate, "number")
        // ) * 100);
        // return true
        // console.log("razorPayAmount bf",razorPayAmount,Number(
        //   calculateOverSeasPrice(Number(orderValue), rate, "number")
        // ));

        razorPayAmount = parseFloat((razorPayAmount * 100).toFixed(2));
        orderValueOv =
          currencyCode !== "INR" &&
          selectedCartItem?.orderType === orderType.chadhava &&
          isOverseas
            ? getChadhavaFinalPaymentValue()
            : null;
      } else {
        razorPayAmount = Number(Number(orderValue) * 100);
      }
      const transectionId = generateTransactionId();
      const orderId =
        selectedCartItem?.orderType === orderType.puja
          ? `PO${generateUniqueNumberString()}`
          : selectedCartItem?.orderType === orderType.chadhava
          ? `CO${generateUniqueNumberString()}`
          : `PRO${generateUniqueNumberString()}`;

      const orderData = {
        amount: razorPayAmount.toString(),
        currency:
          selectedCartItem?.orderType === orderType.prasad
            ? "INR"
            : currencyCode,
        receipt: "receipt#1",
        notes: {
          key1: orderId,
          key2: "value2",
        },
      };

      try {
        const rpOrderIdResponse = await CommonService.generateRazorPayOrderId(
          orderData
        );

        var rpOrderId = rpOrderIdResponse?.id;

        const rpSecreats = await CommonService.getRazorPayCredentials();
        const rpCredentials = rpSecreats?.credentials;
        if (rpOrderId && rpCredentials) {
          // const oderId = rpOrderId;
          const options = {
            key: rpCredentials.KEY,
            key_secreat: rpCredentials.SECRET,
            order_id: rpOrderId,
            amount: razorPayAmount.toString(),
            name: "Temple Dekho",
            description: "",
            image: templeLogo,
            handler: function (response: any) {
              if (
                response.razorpay_payment_id &&
                response.razorpay_payment_id.length > 0
              ) {
                let newCartData = [];
                let newCartInfo = [{ ...selectedCartItem }];
                if (
                  [orderType.chadhava, orderType.prasad]?.includes(
                    selectedCartItem?.orderType
                  )
                ) {
                  const singleCartData = { ...selectedCartItem };
                  const selectedChadhvaList = getSelectedChadhvaList();
                  let cartPayload = { ...singleCartData, selectedChadhvaList };
                  newCartData.push(cartPayload);
                  newCartInfo = newCartData;
                }
                const priceConversionRate =
                  priceConversionState.priceConversionRate || {};

                let prasadDelivaryAddress: any = null;
                //      (isPrasad && cartInfo[0]?.orderType === orderType.puja) && selectedAddressInCart
                //     ? selectedAddressInCart
                //     : isPrasad && cartInfo[0]?.orderType === orderType.prasad ? selectedAddressInCart : null;

                if (selectedAddressInCart) {
                  if (isPrasad && cartInfo[0]?.orderType === orderType.puja) {
                    prasadDelivaryAddress = selectedAddressInCart;
                  } else if (cartInfo[0]?.orderType === orderType.prasad) {
                    prasadDelivaryAddress = selectedAddressInCart;
                  } else {
                    prasadDelivaryAddress = null;
                  }
                } else {
                  prasadDelivaryAddress = null;
                }

                // const orderValueInrPrice = isOverseas && selectedCartItem?.orderType === orderType.chadhava ? getChadhavaFinalPaymentValue()  : orderValue
                dispatch(
                  placeOrderAction(
                    newCartInfo,
                    userInfo,
                    prasadDelivaryAddress,
                    orderValue,
                    transectionId,
                    orderId,
                    response.razorpay_payment_id,
                    priceBreakup,
                    isPrasad,
                    isOverseas,
                    rate,
                    currencySymbols,
                    currencyCode,
                    orderValueOv
                  )
                );
                if (couponApplyResponse.isApplied) {
                  const couponData: any = couponApplyResponse.couponData;
                  const userPhNo = userInfo.phoneNumber;
                  dispatch(
                    updateCouponsUsageAction(couponData.couponCode, userPhNo)
                  );
                }

                // setOrderResultDetails(orderId);
                // setOrderCreatedScreen(true);

                let successMessage = "";
                if (selectedCartItem?.orderType === orderType.prasad) {
                  successMessage = `Thank you! Your payment of INR ${getChadhavaFinalPaymentValue()}  has been received.`;
                } else {
                  successMessage = `Thank you! Your payment of ${currencySymbols}${
                    isOverseas &&
                    selectedCartItem?.orderType === orderType.chadhava
                      ? getChadhavaFinalPaymentValue()
                      : isOverseas &&
                        selectedCartItem?.orderType !== orderType.chadhava
                      ? formatToTwoFractionalDigits(
                          calculateOverSeasPrice(orderValue, rate)
                        )
                      : orderValue
                  }  has been received.`;
                }

                navigate("/success", {
                  state: {
                    type: "payment",
                    title: t(LOCALIZATION.Payment_Successful),
                    // message: `${t(getChadhavaFinalPaymentValue())} ${t(LOCALIZATION.Thank_You_Your_Payment_Received)}`,
                    message: `${t("paymentReceived", {
                      amount: `${getChadhavaFinalPaymentValue()}`,
                    })}
 `,
                    orderValue: isOverseas
                      ? formatToTwoFractionalDigits(
                          calculateOverSeasPrice(orderValue, rate)
                        )
                      : orderValue,
                    orderId,
                    transectionId,
                    priceBreakup,
                    orderType: selectedCartItem?.orderType,
                    razorpay_payment_id: response.razorpay_payment_id,
                    isPrasad,
                    isOverseas,
                    rate,
                    currencySymbols,
                    currencyCode,
                    orderValueOv,
                  },
                });
              } else {
                //setBookingFailedPopUp(true);
                navigate("/error", {
                  state: {
                    message: `Sorry for inconvenience. Error happened while booking the Puja.Please try again after sometime`,
                  },
                });
              }
            },
            prefill: {
              name:
                userPersonalInfo.firstName + " " + userPersonalInfo.lastName,
              email: userPersonalInfo.email,
              contact: userInfo.phoneNumber,
            },
            notes: {
              address: "TempleDekho Office",
            },
            theme: {
              color: "#F37254",
            },
          };

          var pay = new (window as any).Razorpay(options);
          pay.open();
        }
      } catch (error) {
        console.log("Error:", error);
        setBookingFailedPopUp(true);
      }

      return true;
    }
  };

  // useEffect(() => {
  //   if (couponCodeData) {
  //     checkValidityOfcoupon();
  //     // setIsPackageCouponCode(true);
  //   }
  // }, [couponCodeData]);

  const getSelectedChadhvaList = () => {
    return (
      selectedCartItem?.selectedChadhvaList?.filter(
        (item: any) => item?.count > 0
      ) || []
    );
  };

  const checkValidityOfcoupon = async (couponCodeData: any) => {
    if (couponCodeData) {
      try {
        let payload;

        if (selectedCartItem.orderType === orderType.chadhava) {
          payload = {
            couponCode: couponCodeData,
            userPhoneNo: userInfo?.phoneNumber,
            templeId: templeDetails?.id,
            orderType: selectedCartItem.orderType,
            chadhavaList: getSelectedChadhvaList()?.map((item: any) => {
              return {
                id: item?.id,
                count: item?.count,
              };
            }),
          };
        } else {
          payload = {
            couponCode: couponCodeData,
            userPhoneNo: userInfo?.phoneNumber,
            templeId: templeDetails?.id,
            pujaId: pujaDetails?.pujaId,
            packageId: pujaPackage?.packageId,
            orderType: selectedCartItem.orderType,
            isOverseas: isOverseas,
            isPrasad: isPrasad,
          };
        }

        const couponValRes2 = await CouponService.verifyCouponAndGetPrice(
          payload
        );

        if (couponValRes2.isError === false && couponValRes2.finalPrice) {
          setCouponApplyResponse({
            ...couponApplyResponse,
            isValid: true,
            isError: false,
            actualPujaPrice: couponValRes2.actualPujaPrice,
            finalPrice: couponValRes2.finalPrice,
            couponData: couponValRes2.couponData,
            discount: couponValRes2.discount,
            gst: couponValRes2.gst,
            priceBreakup: couponValRes2.priceBreakup,
            isApplied: true,
          });
          setCouponPopUp(false);
        } else {
          setCouponApplyResponse({
            ...couponApplyResponse,
            isValid: false,
            isApplied: false,
            isError: true,
            errorDetails: "Invalid coupon applied",
          });
        }
      } catch (err: any) {
        setCouponApplyResponse({
          ...couponApplyResponse,
          isApplied: false,
          isError: true,
          errorDetails: "Invalid coupon applied",
        });
      }
    }
  };

  const navigateToAddressScreen = () => {
    navigate("/profile/address");
  };

  const navigateToAddMembersScreen = () => {
    navigate("/checkoutV2/addDevotee");
  };

  const navigateToLoginScreen = () => {
    // set the redirect uri
    localStorage.setItem("redirectUri", "/checkoutV2/reviewCart");
    navigate("/login?navigatedFromCheckout=true");
  };

  useEffect(() => {
    if (cartInfo?.length > 0 && cartInfo[0]?.whatsAppNumber) {
      let analyticsData: any = {
        dateUTC: getDateNowInUtc(),
        key: "puja_cart_view_opened",
        eventData: {
          applicationType: "Web",
          userName: "",
        },
        count: 0,
      };

      analyticsData = { ...analyticsData, cartInfo: cartInfo };
      analyticsData["eventData"][
        "whatsAppNumber"
      ] = `${cartInfo[0]?.dialCode}${cartInfo[0]?.whatsAppNumber}`;

      let user: any = localStorage.getItem("userInfo");
      let userData = null;
      if (user && user !== "undefined" && user !== "null") {
        userData = JSON.parse(user);
      }

      if (userData) {
        analyticsData.eventData.userName = userData?.personalInfo?.firstName;
        analyticsData["eventData"]["userId"] = userData?.id;
        analyticsData["eventData"]["phoneNumber"] = userData?.phoneNumber;
      } else {
        analyticsData.eventData.userName = "Guest User";
      }
      dispatch(addAnalytics(analyticsData));
    }

    return () => {};
  }, [cartInfo]);

  useEffect(() => {
    if (selectedCartItem && selectedCartItem?.members.length === 0) {
      navigateToAddMembersScreen();
    }
  }, [selectedCartItem]);

  const handleSetCouponCodeData = (event: any) => {
    setCouponCodeData(event);
  };

  const updateCart = (cartPayload: any) => {
    let newCartData = [];
    newCartData.push(cartPayload);
    dispatch(updateCartAction(newCartData));
  };

  const handleIncrement = (item: any) => {
    const singleCartData = cartInfo[0];
    const selectedChadhvaList = singleCartData.selectedChadhvaList.map(
      (chadhava: any) => {
        if (chadhava.id === item.id) {
          const updatedCount = chadhava.count + 1;
          return { ...chadhava, count: updatedCount };
        } else {
          return chadhava;
        }
      }
    );

    let cartPayload = { ...singleCartData, selectedChadhvaList };
    updateCart(cartPayload);
  };

  // const handleDecrement = (item: any) => {
  //   const singleCartData = cartInfo[0];
  //   const selectedChadhvaList = singleCartData.selectedChadhvaList.map(
  //     (chadhava: any) => {
  //       if (chadhava.id === item.id) {
  //         const updatedCount = chadhava.count > 0 ? chadhava.count - 1 : 0;
  //         return { ...chadhava, count: updatedCount };
  //       } else {
  //         return chadhava;
  //       }
  //     }
  //   );

  //   let cartPayload = { ...singleCartData, selectedChadhvaList };

  //   updateCart(cartPayload);
  // };

  const handleDecrement = (item: any) => {
    const singleCartData = cartInfo[0];

    const selectedChadhvaList = singleCartData.selectedChadhvaList.map(
      (chadhava: any) => {
        if (chadhava.id === item.id) {
          const updatedCount = chadhava.count > 0 ? chadhava.count - 1 : 0;
          return { ...chadhava, count: updatedCount };
        } else {
          return chadhava;
        }
      }
    );

    const chadhavaList = selectedChadhvaList
      ? selectedChadhvaList?.filter((item: any) => item?.count > 0)
      : [];

    if (chadhavaList.length === 0) {
      setShowConfirmationModal(true);
    } else {
      let cartPayload = { ...singleCartData, selectedChadhvaList };
      updateCart(cartPayload);
    }
  };

  const handleConfirmDelete = (e: any) => {
    e.preventDefault();
    const singleCartData = cartInfo[0];
    let cartPayload = { ...singleCartData, selectedChadhvaList: [] };
    updateCart(cartPayload);
    setShowConfirmationModal(false);
  };
  const handleCancelDelete = () => {
    setShowConfirmationModal(false);
  };

  const handRedirectToChadhavaPage = () => {
    if (templeDetails?.id) {
      navigate("/chadhavaDetails/" + templeDetails?.nameKey);
      dispatch(emptyCartAction());
    }
  };

  const handRedirectToPrasadPage = () => {
    if (templeDetails?.id) {
      navigate("/prasadDetails/" + templeDetails?.nameKey);
      dispatch(emptyCartAction());
    }
  };

  const handlePrasadCheckboxChange = (event: any) => {
    const isAddressInIndia = selectedAddressInCart?.countryCode === "IN";
    const isChecked = event.target.checked;
    if (userInfo && userInfo?.phoneNumber) {
      if (!isAddressInIndia) {
        setOutSideIndiaMessagePopup(true);
        setIsPrasad(false);
      } else {
        setIsPrasad(isChecked);
      }
    } else {
      if (!isOverseas) {
        setIsPrasad(isChecked);
      } else {
        setOutSideIndiaMessagePopup(true);
        setIsPrasad(false);
      }
    }
    setCouponApplyResponse({
      ...couponApplyResponse,
      isApplied: false,
      isValid: false,
    });
  };
  const handleSelectedPanditDakshina = (value: any) => {
    let inrAmount = Number(value) / rate;
    setPanditDakshina(inrAmount);
  };

  const handleDeliveryAlertClose = () => {
    setShowDeliveryAlert(false);
  };

  useEffect(() => {
    if (!selectedAddressInCart && userPersonalInfo) {
      // setShowDeliveryAlert(true)
    }
  }, [selectedAddressInCart]);

  return (
    <div>
      {cartInfo && cartInfo.length > 0 ? (
        <ReviewCartView
          userDetails={userInfo}
          userPersonalInfo={userPersonalInfo}
          priceBreakup={priceBreakupData}
          pujaDetails={pujaDetails}
          templeDetails={templeDetails}
          memberDetails={memberDetails}
          selectedAddressDetails={selectedAddressInCart}
          chooseAddressBox={chooseAddressBox}
          setChooseAddressBox={setChooseAddressBox}
          changeDeliverytAddress={changeDeliverytAddress}
          editPujaOptions={() =>
            editPujaOptions(selectedCartItem ? selectedCartItem : cartInfo[0])
          }
          placeOrder={handlePlaceOrder}
          setOrderCreatedScreen={setOrderCreatedScreen}
          orderCreatedScreen={orderCreatedScreen}
          orderResultDetails={orderResultDetails}
          cartDetails={selectedCartItem ? selectedCartItem : cartInfo[0]}
          clearCart={clearCart}
          errorPopup={errorPopup}
          setErrorPopUp={setErrorPopUp}
          bookingFailedPopup={bookingFailedPopup}
          setBookingFailedPopUp={setBookingFailedPopUp}
          couponPopup={couponPopup}
          setCouponPopUp={setCouponPopUp}
          setCouponCodeData={handleSetCouponCodeData}
          couponCodeData={couponCodeData}
          checkValidityOfcoupon={checkValidityOfcoupon}
          couponApplyResponse={couponApplyResponse}
          setCouponApplyResponse={setCouponApplyResponse}
          navigateToAddressScreen={navigateToAddressScreen}
          navigateToAddMembersScreen={navigateToAddMembersScreen}
          navigateToLoginScreen={navigateToLoginScreen}
          setPriceQuoteForPuja={setPriceQuoteForPuja}
          allActiveCoupons={allActiveCoupons}
          chadhavaList={chadhavaList}
          handleIncrement={handleIncrement}
          handleDecrement={handleDecrement}
          handRedirectToChadhavaPage={handRedirectToChadhavaPage}
          setPanditCustomeDakshina={handleSelectedPanditDakshina}
          setPanditDakshina={setPanditDakshina}
          previousPriceData={previousPriceData}
          getChadhavaTotalValue={getChadhavaTotalValue}
          getOverSeasPriceByRateText={getOverSeasPriceByRateText}
          currencySign={currencySign}
          handlePrasadCheckboxChange={handlePrasadCheckboxChange}
          setOutSideIndiaMessagePopup={setOutSideIndiaMessagePopup}
          outSideIndiaMessagePopup={outSideIndiaMessagePopup}
          isPrasad={isPrasad}
          panditDakshina={panditDakshina}
          setIsPrasad={setIsPrasad}
          isCountryDisabledForBooking={
            [orderType.chadhava, orderType.puja]?.includes(
              selectedCartItem?.orderType
            )
              ? priceConversionState?.isCountryDisabled ?? false
              : countryCode === "IN"
              ? false
              : true
          }
          isOverseas={isOverseas}
          getChadhavaFinalPaymentValue={getChadhavaFinalPaymentValue}
          rate={rate}
          getSymbolWithPrice={getSymbolWithPrice}
          handRedirectToPrasadPage={handRedirectToPrasadPage}
        />
      ) : (
        <CartNoItem />
      )}
      <Dialog
        className="OrderResult"
        onClose={() => {
          setOrderCreatedScreen(false);
          navigate("/home");
        }}
        open={orderCreatedScreen}
      >
        <Box className="successCard">
          <Box className="successIcon">
            <img src={successIcon} alt="" />
          </Box>
          <Typography mt="30px">Your order Created Successfully</Typography>
          <Typography>
            {t(LOCALIZATION.Order_id)} : {orderResultDetails}
          </Typography>
          <Button
            variant="outlined"
            onClick={() => {
              setOrderCreatedScreen(false);
              navigate("/home");
            }}
          >
            Ok
          </Button>
        </Box>
      </Dialog>
      <Dialog
        className="errorMsg"
        onClose={() => setBookingFailedPopUp(false)}
        open={bookingFailedPopup}
      >
        <Box className="errorCard">
          <Box className="errorIcon">
            <img src={errorIcon} alt="" />
          </Box>
          <Typography>{t(LOCALIZATION.Sorry_For_Inconvenience)}</Typography>
          <Button onClick={() => setBookingFailedPopUp(false)}>
            {t(LOCALIZATION.OK)}
          </Button>
        </Box>
      </Dialog>
      <>
        <Dialog
          className="deleteConfirmModal"
          open={showConfirmationModal}
          onClose={handleCancelDelete}
        >
          <DialogTitle className="deleteConfirmModal">
            Confirm Deletion
          </DialogTitle>
          <Divider className="titleDivider" />
          <DialogContent>
            <Typography sx={{ fontWeight: 500, color: "#6e6e6e" }}>
              Do you really want to clear everything from your cart?
            </Typography>
          </DialogContent>
          <DialogActions className="confirmBtn">
            <Button
              className="confirmBtnCancel"
              onClick={handleCancelDelete}
              color="primary"
              variant="outlined"
            >
              No
            </Button>
            <Button
              className="confirmBtnDelete"
              onClick={handleConfirmDelete}
              color="primary"
              variant="contained"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
      <Snackbar
        open={showDeliveryAlert}
        autoHideDuration={3000} // Snackbar will disappear after 6 seconds
        onClose={handleDeliveryAlertClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleDeliveryAlertClose} severity="error">
          Prasad delivery not allowed outside India
        </Alert>
      </Snackbar>

      <BookingDateConfirmDialog
        open={bookingDateConfirmDialogOpen}
        bookingDate={bookingDateInConfirmDialog}
        newDate={newDateDateInConfirmDialog}
        onConfirm={handleBookingConfirm}
        onCancel={handleBookingCancel}
      />
    </div>
  );
}

export default ReviewCartScreen;
