import MainCategoriesCard from "../../components/card/MainCategories/MainCategoriesCard";
import UpcomingPujasCard from "../../components/card/UpcomingPujas/UpcomingPujasCard";
import TempleCard from "../../components/card/Temple/TempleCard";
import PostCard from "../../components/card/Post/PostCard";
import FeaturesDisplayCard from "../../components/card/FeaturedCategories/FeaturesDisplayCard";
import DailyDetailsCard from "../../components/card/FeaturedCategories/DailyDetailsCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import ViewCommentsForPost from "../../components/post/ViewCommentsForPost";
import "./HomeView.scss";
import LeftAddressCard from "../../components/card/LeftBlock/LeftAddressCard";
import TitleBlockCard from "../../components/card/TitleBlock/TitleBlock";
import kumbhMelaPrasad from "../../assets/Water-Order.png";
import { Box, Grid } from "@mui/material";
import BlessYSiso from "../../assets/blessysiso.png";

import KnowRashi from "../../assets/knowrashi.png";
import {
  UPCOMING_PUJA_CAROUSAL_RESPONSIVE,
  POPULAR_TEMPLE_CAUROSAL_RESPONSIVE,
  FEATURE_TO_DISPLAY_CAUROSAL_RESPONSIVE,
  HOME_PAGE_TOP_BANNER_CAROUSEL,
  CAROUSEL_REMOVE_ARRAOW_ON_DEVICE,
  SPECIAL_PROMOTIONAL_TYPES,
  LOCALSTORAGE_KEY,
} from "../../utils/Constants";
import PujaCategoryList from "../../components/PujaCategoryList/PujaCategoryList";
import SocialMediaAdd from "../../components/SocialMediaAdd/SocialMediaAdd";
import AdBannerItem from "../../components/AdBannerItem/AdBannerItem";
import { useSelector } from "react-redux";
import GlobalSearchItem from "../../components/GlobalSearchItem/GlobalSearchItem";
import OfferItemCard from "../../components/card/OfferItemCards/OfferItemCard";
import { t } from "i18next";
import { LOCALIZATION } from "../../utils/localization";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { getTempleName } from "../../utils/HelperFunctions";

function HomeView(props: any) {
  const navigate = useNavigate();
  const { offerData } = props;
  const { t } = useTranslation();

  const [showCommentForPost, setShowCommentForPost] = useState({
    shouldOpened: false,
    postId: "",
  });
  const [pujaOffer, setPujaOffer] = useState<any>(null);
  const { userInfo } = useSelector((state: any) => state.user);

  useEffect(() => {
    console.log("currentLang", i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (offerData && offerData.length > 0) {
      // const pujaListItem = offerData.find(
      //   (offerDataItem: any) => offerDataItem.name === "PujaList"
      // );
      setPujaOffer(offerData);
    }
  }, [offerData]);
  const leftAddressheader =
    props.userAddressData && props.userAddressData.city
      ? props.userAddressData.city
      : null;
  const leftAddressData =
    props.userAddressData && props.userAddressData.formatted_address
      ? props.userAddressData.formatted_address
      : null;

  const handleNavigateToPromtionalItem = (item:any) =>{
    if(item?.type && item?.type === SPECIAL_PROMOTIONAL_TYPES.promotionalChadhava){
      const promotionId = item?.specialChadhavaNameKey;
      navigate(`/specialChadhava?specialChadhavaNameKey=${promotionId}`)
      return;
    }
    const promotionId = item?.pujaNameKey;
    navigate(
      `/specialPuja?pujaNameKey=${promotionId}`
    )
  }

  const savedLanguage =
    localStorage.getItem(LOCALSTORAGE_KEY.LANG_CODE) ?? "en";
  const templeName = getTempleName(props.personalInfo, savedLanguage);
  return (
    <Box className="pageContainer homePage">
      <Box className="homeBlock">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={2} lg={3} xl={3} className="pagehdLeftBlock">
              {leftAddressData ? (
                <LeftAddressCard
                  header={leftAddressheader}
                  address={leftAddressData}
                  footerButton={
                    userInfo ? t(LOCALIZATION.Edit_or_add_an_address) : null
                  }
                  onClick={
                    userInfo ? () => navigate("/profile/address") : undefined
                  }
                />
              ) : null}
            </Grid>

            <Grid item xs={12} md={8} lg={6} xl={6} className="pageCenterBlock">
              <Box className="homeBodyContent">
                <Box className="topSearch">
                  <GlobalSearchItem
                    handelFocus={() => navigate("/globalSearch")}
                    currentLanguage={i18n.language}
                  />

                  {/* <GlobalServiceModal /> */}
                </Box>
                <Box className="mainCategories">
                  <Grid container spacing={2}>
                    {props.mainCategories.map((item: any, id: any) => {
                      let gridLengthCategory = 12 / props.mainCategories.length;

                      return (
                        <Grid
                          item
                          xs={6}
                          md={gridLengthCategory}
                          lg={gridLengthCategory}
                          xl={gridLengthCategory}
                          key={`cat_${id}`}
                        >
                          <MainCategoriesCard
                            cardTitle={t(item.cardTitle)}
                            image={item.img}
                            navigatePath={item?.navigatePath}
                            navigate={props.navigate}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>

                {/* {pujaOffer && pujaOffer.length > 0 && pujaOffer && (
                  <Box className="upPujaCard" key="upPujaCard">
                    <TitleBlockCard title={pujaOffer[0]?.name} />

                    <Carousel
                      responsive={UPCOMING_PUJA_CAROUSAL_RESPONSIVE}
                      swipeable={true}
                      draggable={true}
                      showDots={true}
                      keyBoardControl={true}
                      removeArrowOnDeviceType={CAROUSEL_REMOVE_ARRAOW_ON_DEVICE}
                    >
                      {pujaOffer.map((item: any, index: any) => {
                        return (
                          <Box
                            sx={{ cursor: "pointer" }}
                            onClick={() => navigate(item?.onClick?.web?.navId)}
                            key={`offer-${index}`}
                          >
                            <UpcomingPujasCard image={item?.dashboardImage} />
                          </Box>
                        );
                      })}
                    </Carousel>
                  </Box>
                )} */}

                {pujaOffer && pujaOffer.length > 0 && pujaOffer && (
                  <Box className="upPujaCard" key="independentOffer">
                    <TitleBlockCard title= {t(pujaOffer[0]?.name)} />
                    {/* Offer Item Carousel Component */}
                    <OfferItemCard pujaOffer={pujaOffer} />
                  </Box>
                )}

                {props.promotionalList && props.promotionalList.length > 0 ? (
                  <Box className="upPujaCard" key="upPujaCard">
                    <TitleBlockCard title={t(LOCALIZATION?.UPCOMING_PUJAS)} />

                    <Carousel
                      responsive={UPCOMING_PUJA_CAROUSAL_RESPONSIVE}
                      swipeable={true}
                      draggable={true}
                      showDots={true}
                      keyBoardControl={true}
                      removeArrowOnDeviceType={CAROUSEL_REMOVE_ARRAOW_ON_DEVICE}
                    >
                      {props.promotionalList.map((item: any, id: any) => {
                        return (
                          <Box
                            onClick={() =>
                              handleNavigateToPromtionalItem(item)
                            }
                            key={`upcomingpuja${id}`}
                          >
                            <UpcomingPujasCard image={item.dashBoardBg?.uri} />
                          </Box>
                        );
                      })}
                    </Carousel>
                  </Box>
                ) : null}
                <Box className="topAreaCardImageView">
                  <Carousel
                    responsive={HOME_PAGE_TOP_BANNER_CAROUSEL}
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    keyBoardControl={true}
                    removeArrowOnDeviceType={CAROUSEL_REMOVE_ARRAOW_ON_DEVICE}
                  >
                    <AdBannerItem />
                  </Carousel>
                </Box>
                <Box
                  className="knowYourrashi"
                  onClick={() => navigate("/horoscopeDetails?zodiacName=aries")}
                >
                  <Link to="./">
                    <img src={KnowRashi} />
                  </Link>
                </Box>
                {props.featuresToDispaly && (
                  <Box className="featureDetailArea">
                    <TitleBlockCard
                      title={t(LOCALIZATION?.Spiritual_Information)}
                    />
                    <Carousel
                      responsive={FEATURE_TO_DISPLAY_CAUROSAL_RESPONSIVE}
                      swipeable={true}
                      draggable={true}
                      showDots={true}
                      keyBoardControl={true}
                      removeArrowOnDeviceType={CAROUSEL_REMOVE_ARRAOW_ON_DEVICE}
                    >
                      <DailyDetailsCard
                        panchangData={props.panchangData}
                        onClick={() => navigate("/panchang")}
                      />
                      {props.featuresToDispaly.map((item: any, id: any) => {
                        return (
                          <FeaturesDisplayCard
                            name={item.name}
                            image={item.img}
                            onClick={() =>
                              navigate("/specialTemples" + item.url)
                            }
                            key={`panchang${id}`}
                          />
                        );
                      })}
                    </Carousel>
                  </Box>
                )}

                <Box className="featureDetailArea">
                  <TitleBlockCard
                    title={t(LOCALIZATION?.EXPLORE_PUJA_CATEGORIES)}
                  />
                  <Box className="tdCatglistblock">
                    <PujaCategoryList />
                  </Box>
                </Box>
                {props.popularTemples &&
                props.popularTemples &&
                props.popularTemples.length > 0 ? (
                  <Box className="popularTemple">
                    <TitleBlockCard
                      title={t(LOCALIZATION?.MOST_POPULAR_TEMPLES)}
                    />
                    <Carousel
                      responsive={POPULAR_TEMPLE_CAUROSAL_RESPONSIVE}
                      swipeable={true}
                      draggable={true}
                      showDots={true}
                      keyBoardControl={true}
                      removeArrowOnDeviceType={CAROUSEL_REMOVE_ARRAOW_ON_DEVICE}
                    >
                      {props.popularTemples &&
                        props.popularTemples.map((item: any, id: any) => {
                          const { personalInfo } = item;
                          let templeImage =
                            personalInfo &&
                            personalInfo.media &&
                            personalInfo.media.pictures &&
                            personalInfo.media.pictures.length > 0
                              ? personalInfo.media.pictures[0].pictureUrl
                              : // ? getOneImageForTemple(
                                //     personalInfo.media.pictures
                                //   )
                                "";
                          const savedLanguage =
                            localStorage.getItem(LOCALSTORAGE_KEY.LANG_CODE) ??
                            "en";
                          const templeName = getTempleName(item, savedLanguage);

                          return (
                            <TempleCard
                              image={templeImage}
                              templeName={templeName}
                              templeDescription={personalInfo.descriptions}
                              templeId={item.id}
                              navigate={props.navigate}
                              key={`TempleCard${id}`}
                            />
                          );
                        })}
                    </Carousel>
                  </Box>
                ) : null}

                {/* <Box className="blessings">
                  <TitleBlockCard title="Blessings" />
                  <BlessingsCard />
                </Box> */}

                <Box className="recentPost">
                  {/* <TitleBlockCard title="Recent Posts" /> */}
                  <Box className="recentPostCard">
                    <Grid container spacing={2}>
                      {props.posts.map((item: any, id: any) => {
                        return (
                          <Grid
                            item
                            xs={12}
                            md={12}
                            lg={12}
                            xl={12}
                            key={`recentPostCard${id}`}
                          >
                            <PostCard
                              postProfileImage={item.postDetails.userImageUrl}
                              postUserName={item.postDetails.userName}
                              postTime={"3 Jan"}
                              postDescription={item.postDetails.postDescription}
                              // postImage={item.postMedia[0].postMediaFileLink}
                              postData={item}
                              setShowCommentForPost={setShowCommentForPost}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                  <Dialog
                    className="startPostInput"
                    onClose={() =>
                      setShowCommentForPost({ shouldOpened: false, postId: "" })
                    }
                    open={showCommentForPost.shouldOpened}
                  >
                    <ViewCommentsForPost postId={showCommentForPost.postId} />
                  </Dialog>
                  {/* {showCommentForPost.shouldOpened?<ViewCommentsForPost />:null} */}
                </Box>

                <Box className="blessYSiso">
                  <Box className="blessIsoImg">
                    <img src={BlessYSiso} />
                  </Box>
                </Box>
                <Box className="socialMediaSec">
                  <TitleBlockCard
                    title={t(LOCALIZATION.Join_Into_Our_Community)}
                  />
                  <SocialMediaAdd />
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              lg={3}
              xl={3}
              className="pagehdRightBlock"
            >
              {/* <RightBlockCard /> */}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default HomeView;
