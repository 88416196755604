import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Divider,
  IconButton,
  Stack,
  Switch,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "./TempleOnboardingForm.scss";
import TempleTimings from "./TempleTimings";
import TempleAutocomplete from "../../../components/AutoComplete/TempleAutocomplete";
import CancelIcon from "@mui/icons-material/Cancel";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import GoogleMapDialog from "../../../components/dialog/googleMapDialog/GoogleMapDialog";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrganisationUser } from "../../../store/actions/userAction";
import VerifyAdminAutocomplete from "../../../components/AutoComplete/VerifyAdminAutocomplete";
import { getSplittedTimeGooglePeriod } from "../../../utils/HelperFunctions";

// Function to extract state, city, area, and ZIP
function extractLocationInfo(data: Location[]) {
  if (!data) return {};
  let state: any;
  let city: any;
  let zip: any;
  let area: any;

  data.forEach((location: any) => {
    if (location?.types.includes("administrative_area_level_1")) {
      state = location.long_name;
    }
    if (
      location.types.includes("locality") ||
      location.types.includes("sublocality")
    ) {
      city = location.long_name;
    }
    if (location.types.includes("postal_code")) {
      zip = location.long_name;
    }
    if (
      location.types.includes("route") ||
      location.types.includes("neighborhood") ||
      location.types.includes("sublocality_level_1")
    ) {
      area = location.long_name;
    }
  });

  return { state, city, zip, area };
}
interface TempleOnboardingFormProps {
  handleBack: () => void;
  onSubmit: (data: any, templeVerified:boolean) => void;
  editMode: any;
  singleTempleData: any;
}

const TempleOnboardingForm: React.FC<TempleOnboardingFormProps> = ({
  handleBack,
  onSubmit,
  editMode,
  singleTempleData,
}) => {
  const [formData, setFormData] = useState<any>({
    googleData: null,
    title: "",
    searchKey: "",
    coverImageUrls: [],
    coverImageUrlsPuja: [],
    coverImageUrlsChadava: [],
    coverImageUrlsPrasad: [],
    shortDescription: "",
    yearOfEstablishment: "",
    primaryGodName: "",
    officialEmail: "",
    officialWebsiteURL: "",
    position: 0,
    isPublished: false,
    showInHomePage: false,
    isClosedBooking: true,
    isClosed: true,
    videoURL: "",
    address: null,
    timings: {
      opensAt: "",
      closesAt: "",
      period: [
        {
          id: 0,
          day: "Monday",
          openingTime: "09:00 AM",
          closingTime: "06:00 PM",
        },
        {
          id: 1,
          day: "Tuesday",
          openingTime: "09:00 AM",
          closingTime: "06:00 PM",
        },
        {
          id: 2,
          day: "Wednesday",
          openingTime: "09:00 AM",
          closingTime: "06:00 PM",
        },
        {
          id: 3,
          day: "Thursday",
          openingTime: "09:00 AM",
          closingTime: "06:00 PM",
        },
        {
          id: 4,
          day: "Friday",
          openingTime: "09:00 AM",
          closingTime: "06:00 PM",
        },
        {
          id: 5,
          day: "Saturday",
          openingTime: "09:00 AM",
          closingTime: "06:00 PM",
        },
        {
          id: 6,
          day: "Sunday",
          openingTime: "09:00 AM",
          closingTime: "06:00 PM",
        },
      ],
      isOpen24Hour: true,
    },
  });
  const [selectedAdmin, setSelectedAdmin] = useState<any>([]);
  const [localImages, setLocalImages] = useState<any>([]);
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [isGoogleMapDialogOpen, setIsGoogleMapDialogOpen] =
    useState<boolean>(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const dispatch: any = useDispatch();
  const organisationUsers = useSelector((state: any) => state.user);
  const [isTempleVerified, setIsTempleVerified] = useState<boolean>(false);

  const [ errorForVerification, setErrorForVerification] = useState<boolean>(false);
  const { allOrganisationUser } = organisationUsers || [];

  useEffect(() => {
    dispatch(getAllOrganisationUser());
  }, [dispatch]);

  useEffect(() => {
    if(editMode === "Verify" && singleTempleData && singleTempleData?.verifications?.status && singleTempleData?.verifications?.status === "VERRIFIED" ){
      setIsTempleVerified(true)
    }
    if ((editMode === "Edit" || editMode === "Verify") && singleTempleData) {
      setSelectedAdmin(singleTempleData?.selectedAdminList || []);
      let editPayload: any = {
        googleData: singleTempleData?.googleData || null,
        title: singleTempleData?.personalInfo?.name || "",
        searchKey: singleTempleData?.searchKey || "",
        shortDescription: singleTempleData?.personalInfo?.descriptions || "",
        yearOfEstablishment:
          singleTempleData?.personalInfo?.yearOfEstablishment || "",
        primaryGodName: singleTempleData?.personalInfo?.primaryGodName || "",
        officialEmail: singleTempleData?.personalInfo?.officialEmail || "",
        officialWebsiteURL: singleTempleData?.personalInfo?.officialSite || "",
        position: singleTempleData?.position || "0",
        isPublished: singleTempleData?.isPublished ?? false,
        showInHomePage: singleTempleData?.showInHomePage ?? false,
        isClosedBooking: singleTempleData?.isClosedBooking ?? false,
        isClosed: singleTempleData?.isClosed ?? false,
        address: singleTempleData?.personalInfo?.address || null,
        coverImageUrls: singleTempleData?.personalInfo?.media?.pictures || [],
        coverImageUrlsPuja:
          singleTempleData?.personalInfo?.media?.pujaPictures || [],
        coverImageUrlsChadava:
          singleTempleData?.personalInfo?.media?.chadavaPictures || [],
        coverImageUrlsPrasad:
          singleTempleData?.personalInfo?.media?.prasadPictures || [],
        videoURL: singleTempleData?.personalInfo?.media?.videoURL || "",
      };
      if (singleTempleData?.personalInfo?.operatingHours) {
        editPayload["timings"] = {
          ...singleTempleData?.personalInfo?.operatingHours,
        };
      }
      setFormData((prev: any) => {
        return {
          ...prev,
          ...editPayload,
        };
      });
    }
  }, [editMode, singleTempleData]);

  const handleOpenGoogleDialog = () => {
    if (!formData?.googleData) {
      setFormErrors((prev: any) => {
        return {
          ...prev,
          ["confirmLocation"]: "Please select temple location.",
        };
      });
      return;
    }
    setIsGoogleMapDialogOpen(true);
  };

  const handleCloseGoogleDialog = (e: any, reason: any) => {
    if (reason !== "backdropClick") {
      setIsGoogleMapDialogOpen(false);
    }
  };

  const handleFileChange =
    (
      field:
        | "coverImageUrls"
        | "coverImageUrlsPuja"
        | "coverImageUrlsChadava"
        | "coverImageUrlsPrasad"
    ) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      if (files && files.length > 0) {
        const newImages = Array.from(files).map((file) => {
          return new Promise<string>((resolve) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as string);
            reader.readAsDataURL(file);
          });
        });

        Promise.all(newImages).then((images) => {
          setLocalImages((prev: any) => ({
            ...prev,
            [field]: [
              ...(Array.isArray(prev[field]) ? prev[field] : []),
              ...images,
            ],
          }));
        });

        setFormData((prev: any) => ({
          ...prev,
          [field]: [
            ...(Array.isArray(prev[field]) ? prev[field] : []),
            ...Array.from(files),
          ],
        }));
      }
    };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.SyntheticEvent
  ) => {
    const { name, value, type, checked } = e.target as HTMLInputElement;
    setFormData((prev: any) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
    if (
      [
        "title",
        "shortDescription",
        "position",
        "primaryGodName",
        "coverImgeUrls",
        "coverImgeUrlsPuja",
        "coverImageUrlsChadava",
        "coverImageUrlsPrasad",
      ].includes(name)
    ) {
      setFormErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const validateForm = () => {
    const errors: { [key: string]: string } = {};
    if (!formData?.title.trim()) errors.title = "Name of Temple is required.";
    if (!formData?.searchKey.trim())
      errors.searchKey = "Search Key of Temple is required.";
    if (!formData?.shortDescription.trim())
      errors.shortDescription = "Short description is required.";
    if (!formData?.position) errors.position = "Position is required";
    if (!formData?.primaryGodName.trim())
      errors.primaryGodName = "Please enter God name of temple";
    if (localImages?.length === 0 && formData?.coverImageUrls?.length === 0)
      errors.coverImageUrls = "At least one Temple image is required.";
    if (localImages?.length === 0 && formData?.coverImageUrlsPuja?.length === 0)
      errors.coverImageUrlsPuja = "At least one puja image is required.";
    if (
      localImages?.length === 0 &&
      formData?.coverImageUrlsPrasad?.length === 0
    )
      errors.coverImageUrlsPrasad = "At least one Prasad image is required.";
    if (
      localImages?.length === 0 &&
      formData?.coverImageUrlsChadava?.length === 0
    )
      errors.coverImageUrlsChadava = "At least one Chadava image is required.";
    if (!formData?.googleData) {
      errors.confirmLocation = "Please select temple location.";
    }
    setFormErrors(errors);
    if( editMode === "Verify" && isTempleVerified && selectedAdmin.length <= 0){
      setErrorForVerification(true)
      return false;
    }
    return Object.keys(errors)?.length === 0;
  };

  const handleRemoveImage = (field: string, index: number) => {
    setLocalImages((prevImages: any) => ({
      ...prevImages,
      [field]: prevImages[field]?.filter((_: any, i: any) => i !== index),
    }));
  };

  const handleRemoveAlreadyUploadedImage = (field: string, index: number) => {
    const updatedImages = formData?.[field]?.filter(
      (_: any, i: any) => i !== index
    );

    setFormData((prev: any) => ({
      ...prev,
      [field]: updatedImages,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    setIsFormSubmitting(true);
    setTimeout(() => {}, 10000);
    const finalFormData = { ...formData, selectedAdmin };
    onSubmit(finalFormData,isTempleVerified);
  };

  const addGoogleTiming = (timingsPeriod: any) => {
    let googlePeriod = getSplittedTimeGooglePeriod(
      JSON.parse(JSON.stringify(timingsPeriod)),
      JSON.parse(JSON.stringify(formData?.timings?.period))
    );
    setFormData((state: any) => ({
      ...state,
      timings: {
        ...state.timings,
        period: googlePeriod,
      },
    }));
  };

  const handleTempleTimeChange = (updateTimeing: any) => {
    if (!Array.isArray(updateTimeing) && updateTimeing?.length < 1) {
      return;
    }
    let newTimingArr: any = {
      ...formData?.timings,
      period: [...updateTimeing],
    };
    setFormData((prev: any) => {
      return { ...prev, timings: { ...newTimingArr } };
    });
  };

  const handle24TimingCheckbox = (timingType: boolean) => {
    let newTimingArr: any = {
      ...formData?.timings,
      isOpen24Hour: timingType,
    };
    setFormData((prev: any) => {
      return { ...prev, timings: { ...newTimingArr } };
    });
  };

  const handleChangeTemple = (templeData: any) => {
    const { state, city, zip, area } = extractLocationInfo(
      templeData?.address_components
    );
    const lat = templeData?.geometry?.location?.lat || "";
    const lng = templeData?.geometry?.location?.lng || "";
    const coordinates: any = {
      latitude: lat,
      longitude: lng,
    };

    let newAddress = {
      ...formData?.address,
      state: state,
      city: city,
      zip: zip,
      area: area,
      formatted_address: templeData?.formatted_address,
      coordinates: { ...coordinates },
    };
    if (templeData?.opening_hours?.weekday_text) {
      addGoogleTiming(templeData?.opening_hours?.weekday_text);
    }

    let title = templeData?.name || "";

    setFormData((prev: any) => {
      return {
        ...prev,
        googleData: { ...templeData },
        address: { ...newAddress },
        title: title,
      };
    });
    setFormErrors((prev: any) => {
      return { ...prev, ["confirmLocation"]: "", ["title"]: "" };
    });
  };

  const handleEditLocationFromMap = (templeData:any)=>{
    const { state, city, zip, area } = extractLocationInfo(
      templeData?.address_components
    );
    const lat = templeData?.geometry?.location?.lat || "";
    const lng = templeData?.geometry?.location?.lng || "";
    const coordinates: any = {
      latitude: lat,
      longitude: lng,
    };

    let newAddress = {
      ...formData?.address,
      state: state,
      city: city,
      zip: zip,
      area: area,
      formatted_address: templeData?.formatted_address,
      coordinates: { ...coordinates },
    };
    setFormData((prev: any) => {
      return {
        ...prev,
        googleData: { ...templeData },
        address: { ...newAddress },
      };
    });
  }

  const handleAddressChange = (e: any) => {
    let newAddress = {
      ...formData?.address,
      [e.target.name]: e.target.value,
    };
    setFormData((prev: any) => {
      return { ...prev, address: { ...newAddress } };
    });
  };

  return (
    <Box className="tempelonboardingform">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box className="templeOnboardHeader">
            <Typography variant="h4">
              <IconButton onClick={handleBack} color="primary">
                <KeyboardBackspaceIcon />
              </IconButton>
              {editMode} Temple Information
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                {editMode === "Add" && (
                  <TempleAutocomplete
                    onChangeHandle={handleChangeTemple}
                    cityValue={formData?.googleData?.name}
                    label="Search Temple"
                  />
                )}
                {!!formErrors?.confirmLocation && (
                  <Box>
                    <Typography color="#ff0000" sx={{ fontSize: "12px" }}>
                      {formErrors?.confirmLocation}
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Grid item md={6}>
                <TextField
                  label="Name of Temple *"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  fullWidth
                  error={!!formErrors.title}
                  helperText={formErrors.title}
                  disabled={editMode === "Verify"}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  label="Add Search Key *"
                  name="searchKey"
                  value={formData.searchKey}
                  onChange={handleChange}
                  fullWidth
                  error={!!formErrors.searchKey}
                  helperText={formErrors.searchKey}
                  disabled={editMode === "Verify"}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  label="Establishment Year"
                  name="yearOfEstablishment"
                  value={formData.yearOfEstablishment || ""}
                  onChange={handleChange}
                  fullWidth
                  disabled={editMode === "Verify"}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  label="God"
                  name="primaryGodName"
                  value={formData.primaryGodName || ""}
                  onChange={handleChange}
                  fullWidth
                  error={!!formErrors.primaryGodName}
                  helperText={formErrors.primaryGodName}
                  FormHelperTextProps={{
                    sx: { marginLeft: "3px" },
                  }}
                  disabled={editMode === "Verify"}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  label="Official Email"
                  name="officialEmail"
                  value={formData.officialEmail || ""}
                  onChange={handleChange}
                  fullWidth
                  disabled={editMode === "Verify"}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  label="Official Website URL"
                  name="officialWebsiteURL"
                  value={formData.officialWebsiteURL || ""}
                  onChange={handleChange}
                  fullWidth
                  disabled={editMode === "Verify"}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  label="Temple Weightage"
                  name="position"
                  value={formData?.position || ""}
                  // onChange={handleChange}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      handleChange(e);
                    }
                  }}
                  fullWidth
                  type="number"
                  error={!!formErrors.position}
                  helperText={formErrors.position}
                  FormHelperTextProps={{
                    sx: { marginLeft: "3px" },
                  }}
                  disabled={editMode === "Verify"}
                />
              </Grid>

              <Grid item md={12}>
                <TextField
                  label="Description *"
                  name="shortDescription"
                  value={formData.shortDescription}
                  onChange={handleChange}
                  fullWidth
                  //margin="normal"
                  error={!!formErrors.shortDescription}
                  helperText={formErrors.shortDescription}
                  FormHelperTextProps={{
                    sx: { marginLeft: "3px" },
                  }}
                  multiline
                  rows={6}
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-root": {
                      width: "100%",
                    },
                    textarea: {
                      minWidth: "100%",
                    },
                  }}
                  disabled={editMode === "Verify"}
                />
              </Grid>
              <Grid item md={6}>
                <Box className="weightageBtnRow itemList">
                  <Button
                    variant="outlined"
                    startIcon={<AddLocationIcon />}
                    onClick={handleOpenGoogleDialog}
                  >
                    Verify Temple location
                  </Button>
                </Box>
              </Grid>
            </Grid>

            <Box mt={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="isPublished"
                    onChange={handleChange}
                    checked={formData?.isPublished}
                    disabled={editMode === "Verify"}
                  />
                }
                label="Temple is Published"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="showInHomePage"
                    onChange={handleChange}
                    checked={formData?.showInHomePage}
                    disabled={editMode === "Verify"}
                  />
                }
                label="Show on Home Page"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="isClosedBooking"
                    onChange={handleChange}
                    checked={formData?.isClosedBooking}
                    disabled={editMode === "Verify"}
                  />
                }
                label="Check for close puja bookings"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="isClosed"
                    onChange={handleChange}
                    checked={formData?.isClosed}
                    disabled={editMode === "Verify"}
                  />
                }
                label="Check for close temple"
              />

              <Box className="timingsArea">
                <Divider className="divider" />
                <TempleTimings
                  isOpen24Hour={formData?.timings?.isOpen24Hour}
                  timings={formData?.timings?.period}
                  handleTempleTimeChange={handleTempleTimeChange}
                  handle24TimingCheckbox={handle24TimingCheckbox}
                  editMode={editMode}
                />
              </Box>
              <Grid container spacing={2} mt={2} mb={2}>
                <Grid item md={12}>
                  <Typography variant="h6" gutterBottom>
                    Temple Picture
                  </Typography>
                  <TextField
                    name="coverImageUrls"
                    type="file"
                    onChange={handleFileChange("coverImageUrls")}
                    fullWidth
                    inputProps={{ accept: "image/*", multiple: true }}
                    error={!!formErrors.coverImageUrls}
                    helperText={formErrors.coverImageUrls}
                    disabled={editMode === "Verify"}
                  />
                  <Box className="imagePreviewContainer">
                    {localImages?.coverImageUrls &&
                      Array.isArray(localImages.coverImageUrls) &&
                      localImages?.coverImageUrls?.length > 0 &&
                      localImages?.coverImageUrls?.map(
                        (image: any, index: number) => (
                          <Box key={index} className="imagePreviewWrapper">
                            <img
                              src={image}
                              alt={`Preview ${index}`}
                              className="imagePreview"
                            />
                            <IconButton
                              size="small"
                              color="error"
                              //  onClick={() => handleRemoveImage(index)}
                              onClick={() =>
                                handleRemoveImage("coverImageUrls", index)
                              }
                            >
                              <CancelIcon />
                            </IconButton>
                          </Box>
                        )
                      )}
                  </Box>
                  <Box className="imagePreviewContainer">
                    {formData?.coverImageUrls &&
                      formData?.coverImageUrls?.length > 0 &&
                      formData?.coverImageUrls?.map(
                        (item: any, index: number) => {
                          if (item instanceof File) {
                            return null;
                          } else {
                            return (
                              <Box key={index} className="imagePreviewWrapper">
                                <img
                                  src={item?.pictureUrl}
                                  alt={`Preview ${index}`}
                                  className="imagePreview"
                                />
                                {editMode !== "Verify" && (
                                  <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() =>
                                      handleRemoveAlreadyUploadedImage(
                                        "coverImageUrls",
                                        index
                                      )
                                    }
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                )}
                              </Box>
                            );
                          }
                        }
                      )}
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <Typography variant="h6" gutterBottom>
                    Puja Picture
                  </Typography>
                  <TextField
                    name="coverImageUrlsPuja"
                    type="file"
                    onChange={handleFileChange("coverImageUrlsPuja")}
                    fullWidth
                    inputProps={{ accept: "image/*", multiple: true }}
                    error={!!formErrors.coverImageUrlsPuja}
                    helperText={formErrors.coverImageUrlsPuja}
                    disabled={editMode === "Verify"}
                  />
                  <Box className="imagePreviewContainer">
                    {localImages?.coverImageUrlsPuja &&
                      Array.isArray(localImages.coverImageUrlsPuja) &&
                      localImages?.coverImageUrlsPuja.length > 0 &&
                      localImages?.coverImageUrlsPuja?.map(
                        (image: any, index: number) => (
                          <Box key={index} className="imagePreviewWrapper">
                            <img
                              src={image}
                              alt={`Preview ${index}`}
                              className="imagePreview"
                            />
                            <IconButton
                              size="small"
                              color="error"
                              onClick={() =>
                                handleRemoveImage("coverImageUrlsPuja", index)
                              }
                            >
                              <CancelIcon />
                            </IconButton>
                          </Box>
                        )
                      )}
                  </Box>
                  <Box className="imagePreviewContainer">
                    {formData?.coverImageUrlsPuja &&
                      formData?.coverImageUrlsPuja?.length > 0 &&
                      formData?.coverImageUrlsPuja?.map(
                        (item: any, index: number) => {
                          if (item instanceof File) {
                            return null;
                          } else {
                            return (
                              <Box key={index} className="imagePreviewWrapper">
                                <img
                                  src={item?.pictureUrl}
                                  alt={`Preview ${index}`}
                                  className="imagePreview"
                                />
                                {editMode !== "Verify" && (
                                  <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() =>
                                      handleRemoveAlreadyUploadedImage(
                                        "coverImageUrlsPuja",
                                        index
                                      )
                                    }
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                )}
                              </Box>
                            );
                          }
                        }
                      )}
                  </Box>
                </Grid>

                <Grid item md={12}>
                  <Typography variant="h6" gutterBottom>
                    Chadava Picture
                  </Typography>
                  <TextField
                    name="coverImageUrlsChadava"
                    type="file"
                    onChange={handleFileChange("coverImageUrlsChadava")}
                    fullWidth
                    inputProps={{ accept: "image/*", multiple: true }}
                    error={!!formErrors.coverImageUrlsChadava}
                    helperText={formErrors.coverImageUrlsChadava}
                    disabled={editMode === "Verify"}
                  />
                  <Box className="imagePreviewContainer">
                    {localImages?.coverImageUrlsChadava &&
                      Array.isArray(localImages.coverImageUrlsChadava) &&
                      localImages?.coverImageUrlsChadava?.length > 0 &&
                      localImages?.coverImageUrlsChadava?.map(
                        (image: any, index: number) => (
                          <Box key={index} className="imagePreviewWrapper">
                            <img
                              src={image}
                              alt={`Preview ${index}`}
                              className="imagePreview"
                            />
                            <IconButton
                              size="small"
                              color="error"
                              onClick={() =>
                                handleRemoveImage(
                                  "coverImageUrlsChadava",
                                  index
                                )
                              }
                            >
                              <CancelIcon />
                            </IconButton>
                          </Box>
                        )
                      )}
                  </Box>
                  <Box className="imagePreviewContainer">
                    {formData?.coverImageUrlsChadava &&
                      formData?.coverImageUrlsChadava?.length > 0 &&
                      formData?.coverImageUrlsChadava?.map(
                        (item: any, index: number) => {
                          if (item instanceof File) {
                            return null;
                          } else {
                            return (
                              <Box key={index} className="imagePreviewWrapper">
                                <img
                                  src={item?.pictureUrl}
                                  alt={`Preview ${index}`}
                                  className="imagePreview"
                                />
                                {editMode !== "Verify" && (
                                  <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() =>
                                      handleRemoveAlreadyUploadedImage(
                                        "coverImageUrlsChadava",
                                        index
                                      )
                                    }
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                )}
                              </Box>
                            );
                          }
                        }
                      )}
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <Typography variant="h6" gutterBottom>
                    Prasad Picture
                  </Typography>
                  <TextField
                    name="coverImageUrlsPrasad"
                    type="file"
                    onChange={handleFileChange("coverImageUrlsPrasad")}
                    fullWidth
                    inputProps={{ accept: "image/*", multiple: true }}
                    error={!!formErrors.coverImageUrlsPrasad}
                    helperText={formErrors.coverImageUrlsPrasad}
                    disabled={editMode === "Verify"}
                  />
                  <Box className="imagePreviewContainer">
                    {localImages?.coverImageUrlsPrasad &&
                      Array.isArray(localImages.coverImageUrlsPrasad) &&
                      localImages?.coverImageUrlsPrasad?.length > 0 &&
                      localImages?.coverImageUrlsPrasad?.map(
                        (image: any, index: number) => (
                          <Box key={index} className="imagePreviewWrapper">
                            <img
                              src={image}
                              alt={`Preview ${index}`}
                              className="imagePreview"
                            />
                            <IconButton
                              size="small"
                              color="error"
                              onClick={() =>
                                handleRemoveImage("coverImageUrlsPrasad", index)
                              }
                            >
                              <CancelIcon />
                            </IconButton>
                          </Box>
                        )
                      )}
                  </Box>
                  <Box className="imagePreviewContainer">
                    {formData?.coverImageUrlsPrasad &&
                      formData?.coverImageUrlsPrasad?.length > 0 &&
                      formData?.coverImageUrlsPrasad?.map(
                        (item: any, index: number) => {
                          if (item instanceof File) {
                            return null;
                          } else {
                            return (
                              <Box key={index} className="imagePreviewWrapper">
                                <img
                                  src={item?.pictureUrl}
                                  alt={`Preview ${index}`}
                                  className="imagePreview"
                                />
                                {editMode !== "Verify" && (
                                  <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() =>
                                      handleRemoveAlreadyUploadedImage(
                                        "coverImageUrlsPrasad",
                                        index
                                      )
                                    }
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                )}
                              </Box>
                            );
                          }
                        }
                      )}
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <TextField
                    label="Video URL"
                    name="videoURL"
                    value={formData?.videoURL}
                    onChange={handleChange}
                    fullWidth
                    error={!!formErrors.videoURL}
                    helperText={formErrors.videoURL}
                    disabled={editMode === "Verify"}
                  />
                </Grid>
              </Grid>
            </Box>

            {editMode === "Verify" && (
              <>
              <Box mt={2} sx={{ width: "100%" }}>
                <VerifyAdminAutocomplete
                  value={selectedAdmin}
                  options={allOrganisationUser}
                  onChange={(evt: any) => {
                    setSelectedAdmin([...evt]);
                    setErrorForVerification(false)
                  }}
                  label="Select Admin"
                  alreadySelectedOptions={selectedAdmin}
                />
              </Box>
              {errorForVerification && (
                  <Box mt={2}>
                    <Typography color="#ff0000" sx={{ fontSize: "12px" }}>
                      Please select admin
                    </Typography>
                  </Box>
                )}
              </>
            )}

            {editMode === "Verify" && (
              <Box mt={2} sx={{ width: "100%" , display: "flex", alignItems:"center"}}>
                <Typography>Is Temple verified</Typography>
                <Switch checked={isTempleVerified} onChange={()=>{setIsTempleVerified(prev=>!prev)}}/>
              </Box>
            )}

            <Stack
              className="bottomBtnArea"
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              mt={4}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleSubmit}
                disabled={isFormSubmitting}
              >
                {!isFormSubmitting ? "Submit" : "Submitting..."}
              </Button>
              <Button variant="outlined" onClick={handleBack}>
                Cancel
              </Button>
            </Stack>
          </form>
        </Grid>
      </Grid>
      <GoogleMapDialog
        open={isGoogleMapDialogOpen}
        onClose={handleCloseGoogleDialog}
        formData={formData}
        handleChangeTemple={handleEditLocationFromMap}
        handleAddressChange={handleAddressChange}
        editMode={editMode}
      />
    </Box>
  );
};

export default TempleOnboardingForm;
