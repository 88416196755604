import { Box, Grid, Typography, Stack } from "@mui/material";
import omLiner from "../../assets/om-liner.png";
import ShareLink from "../../components/sharelink/sharelink";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  SPECIAL_CHADHAVA_ITEM_CAROUSEL,
  CAROUSEL_REMOVE_ARRAOW_ON_DEVICE,
  LOCALSTORAGE_KEY,
} from "../../utils/Constants";
import SpecialChadhavaItem from "../../components/card/SpecialChadhavaItem/SpecialChadhavaItem";
import "./PromotionalChadhavaView.scss";
import { getTempleName } from "../../utils/HelperFunctions";

function PromotionalChadhavaView(props: any) {
  const { promotionalChadhavaDetails, urlToShare, handleClickOnMoreDetails } = props;
  const savedLanguage = localStorage.getItem(LOCALSTORAGE_KEY.LANG_CODE) ?? "en";
  const handleClickOnAddinView = (templeId:string,chadhavaId:string) =>{
    props.handleClickOnChadhavaAdd(templeId,chadhavaId)
  }
  return (
    <Box className="pageContainer promotionalPujaPage">
      <Box className="templeBlock">
        <Grid container spacing={1}>
          <Grid item xs={12} md={2} lg={3} xl={3} className="pagehdLeftBlock">
            {/* <LeftBlockCard /> */}
          </Grid>
          <Grid item xs={12} md={8} lg={6} xl={6}>
            <Box className="promoPujaCenterBlock" mb={2}>
              <Box>
                <img
                  className="promoPujaBanner"
                  src={promotionalChadhavaDetails?.chadhavaListBg?.uri}
                  alt={promotionalChadhavaDetails?.specialChadhavaName}
                />
              </Box>
              <Box className="shareIcon">
                <ShareLink urlToShare={urlToShare}></ShareLink>
              </Box>

              {promotionalChadhavaDetails?.templeList &&
                promotionalChadhavaDetails?.templeList?.length &&
                promotionalChadhavaDetails?.templeList?.map((temple: any) => {
                  const templeName = getTempleName(temple, savedLanguage);
                  if(temple && temple?.templeChadhava?.length === 0){
                    return null;
                  }
                  return (
                    <Box
                    key={temple?.nameKey}
                    >
                      <Box className="pujaBookingTitle">
                        <Stack
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Typography>{templeName}</Typography>
                          <Box className="textBottomLiner">
                            <img src={omLiner} alt="" />
                          </Box>
                        </Stack>
                      </Box>

                      <Box className="templeCenterBlock">
                        <Carousel
                          responsive={SPECIAL_CHADHAVA_ITEM_CAROUSEL(temple?.templeChadhava?.length || 0)}
                          swipeable={true}
                          draggable={true}
                          showDots={true}
                          keyBoardControl={true}
                          removeArrowOnDeviceType={
                            CAROUSEL_REMOVE_ARRAOW_ON_DEVICE
                          }
                          partialVisible
                        >
                          {temple?.templeChadhava &&
                            temple?.templeChadhava?.length ?
                            temple?.templeChadhava?.map((_chadhava: any, idx:number) => {
                              return (
                                <Box
                                 key={_chadhava?.id}
                                >
                                  <SpecialChadhavaItem 
                                  chadhavaDetails = {_chadhava}
                                  handleClickOnChadhavaAdd = {()=>{
                                    handleClickOnAddinView(temple?.nameKey,_chadhava,)
                                  }}
                                  handleClickOnMoreDetails = {()=>{
                                    handleClickOnMoreDetails(temple?.nameKey)
                                  }}
                                  />
                                </Box>
                              );
                            }): []}
                        </Carousel>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </Grid>
          <Grid item xs={12} md={2} lg={3} xl={3} className="pagehdRightBlock">
            {/* <RightBlockCard /> */}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default PromotionalChadhavaView;
