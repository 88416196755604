import { useEffect, useState } from "react";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import NotificationAddIcon from "@mui/icons-material/NotificationAdd";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import FilterVintageIcon from "@mui/icons-material/FilterVintage";
import SpaIcon from "@mui/icons-material/Spa";
import TempleHinduIcon from "@mui/icons-material/TempleHindu";
import ForumIcon from "@mui/icons-material/Forum";
import RiceBowlIcon from "@mui/icons-material/RiceBowl";
import SettingsIcon from "@mui/icons-material/Settings";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import YardOutlinedIcon from "@mui/icons-material/YardOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import "./AdminLayoutView.scss";
import DescriptionIcon from "@mui/icons-material/Description";
import { useSelector } from "react-redux";
import { APPROLES } from "../../utils/Constants";

const AdminLayoutView = () => {
  const { userInfo } = useSelector((state: any) => state.user);
  const [userType, setuserType] = useState<string>("");
  useEffect(() => {
    if (userInfo) {
      setuserType(userInfo?.userType);
    }
  }, [userInfo]);

  const navigate = useNavigate();
  return (
    <Box className="adminSideNavView" sx={{ flexGrow: 1 }}>
      {/* ---- Mobile Device Message ---- */}
      <Typography className="mobileDeviceMessage">
        The Admin Panel is accessible exclusively on PC
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={2} md={2} lg={2} xl={2} className="pagehdLeftBlock">
          <List
            className="sideNavList"
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {userType === APPROLES.SUPERADMIN.roleId && (
              <>
                <ListItemButton
                  className="listItemBtn"
                  onClick={() => {
                    navigate("/admin");
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  <ListItemIcon className="listIconSec">
                    <AdminPanelSettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Admin Panel" />
                </ListItemButton>
                <ListItemButton
                  className="listItemBtn"
                  onClick={() => {
                    navigate("/admin/useranalytics");
                  }}
                >
                  <ListItemIcon className="listIconSec">
                    <AnalyticsIcon />
                  </ListItemIcon>
                  <ListItemText primary="User Analytics" />
                </ListItemButton>
                <ListItemButton
                  className="listItemBtn"
                  onClick={() => {
                    navigate("/admin/sendpush");
                  }}
                >
                  <ListItemIcon className="listIconSec">
                    <NotificationAddIcon />
                  </ListItemIcon>
                  <ListItemText primary="Send Push Notification" />
                </ListItemButton>
                <ListItemButton
                  className="listItemBtn"
                  onClick={() => {
                    navigate("/admin/couponcode");
                  }}
                >
                  <ListItemIcon className="listIconSec">
                    <ConfirmationNumberIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manage Coupon code" />
                </ListItemButton>
                <Accordion className="listAccordion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className="listItemBtn mainItem"
                  >
                    <ListItemIcon className="listIconSec">
                      <TempleHinduIcon />
                    </ListItemIcon>
                    <ListItemText primary="Temple" />
                  </AccordionSummary>
                  <AccordionDetails className="detailsList">
                    <ListItemButton
                      className="listItemBtn"
                      onClick={() => {
                        navigate("/admin/temples");
                      }}
                    >
                      <ListItemIcon className="listIconSec">
                        <TempleHinduIcon />
                      </ListItemIcon>
                      <ListItemText primary="Manage Temples" />
                    </ListItemButton>
                    <ListItemButton
                      className="listItemBtn"
                      onClick={() => {
                        navigate("/admin/onboardtemple");
                      }}
                    >
                      <ListItemIcon className="listIconSec">
                        <TempleHinduIcon />
                      </ListItemIcon>
                      <ListItemText primary="Temple Onboarding" />
                    </ListItemButton>
                  </AccordionDetails>
                </Accordion>

                <Accordion className="listAccordion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className="listItemBtn mainItem"
                  >
                    <ListItemIcon className="listIconSec">
                      <TempleHinduIcon />
                    </ListItemIcon>
                    <ListItemText primary="Puja/Chadhava/Prasad" />
                  </AccordionSummary>
                  <AccordionDetails className="detailsList">
                    <ListItemButton
                      className="listItemBtn"
                      onClick={() => {
                        navigate("/admin/managepuja");
                      }}
                    >
                      <ListItemIcon className="listIconSec">
                        <ForumIcon />
                      </ListItemIcon>
                      <ListItemText primary="Manage Puja" />
                    </ListItemButton>
                    <ListItemButton
                      className="listItemBtn"
                      onClick={() => {
                        navigate("/admin/specialpuja");
                      }}
                    >
                      <ListItemIcon className="listIconSec">
                        <FilterVintageIcon />
                      </ListItemIcon>
                      <ListItemText primary="Manage Special Puja" />
                    </ListItemButton>
                    <ListItemButton
                      className="listItemBtn"
                      onClick={() => {
                        navigate("/admin/chadhawa");
                      }}
                    >
                      <ListItemIcon className="listIconSec">
                        <SpaIcon />
                      </ListItemIcon>
                      <ListItemText primary="Manage Chadhawa" />
                    </ListItemButton>

                    <ListItemButton
                      className="listItemBtn"
                      onClick={() => {
                        navigate("/admin/specialchadhava");
                      }}
                    >
                      <ListItemIcon className="listIconSec">
                        <YardOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Manage Special Chadhawa" />
                    </ListItemButton>

                    <ListItemButton
                      className="listItemBtn"
                      onClick={() => {
                        navigate("/admin/manageprasad");
                      }}
                    >
                      <ListItemIcon className="listIconSec">
                        <RiceBowlIcon />
                      </ListItemIcon>
                      <ListItemText primary="Manage Prasad" />
                    </ListItemButton>
                  </AccordionDetails>
                </Accordion>

                <Accordion className="listAccordion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className="listItemBtn mainItem"
                  >
                    <ListItemIcon className="listIconSec">
                    <FilterVintageIcon />
                    </ListItemIcon>
                    <ListItemText primary="Manage Others" />
                  </AccordionSummary>
                  <AccordionDetails className="detailsList">
                    <ListItemButton
                      className="listItemBtn"
                      onClick={() => {
                        navigate("/admin/horoscopes");
                      }}
                    >
                      <ListItemIcon className="listIconSec">
                        <Diversity2Icon />
                      </ListItemIcon>
                      <ListItemText primary="Manage Horoscopes" />
                    </ListItemButton>

                    <ListItemButton
                      className="listItemBtn"
                      onClick={() => {
                        navigate("/admin/gurujichats");
                      }}
                    >
                      <ListItemIcon className="listIconSec">
                        <ForumIcon />
                      </ListItemIcon>
                      <ListItemText primary="Manage Guruji Chat" />
                    </ListItemButton>

                    <ListItemButton
                      className="listItemBtn"
                      onClick={() => {
                        navigate("/admin/livedarshan");
                      }}
                    >
                      <ListItemIcon className="listIconSec">
                        <VideoCameraFrontIcon />
                      </ListItemIcon>
                      <ListItemText primary="Add/Edit Live Darshan" />
                    </ListItemButton>

                    <ListItemButton
                      className="listItemBtn"
                      onClick={() => {
                        navigate("/admin/managenewontd");
                      }}
                    >
                      <ListItemIcon className="listIconSec">
                      <FilterVintageIcon />
                      </ListItemIcon>
                      <ListItemText primary="Manage New On Temple Dekho" />
                    </ListItemButton>
                    <ListItemButton
                      className="listItemBtn"
                      onClick={() => {
                        navigate("/admin/onboardingbenefits");
                      }}
                    >
                      <ListItemIcon className="listIconSec">
                      <FilterVintageIcon />
                      </ListItemIcon>
                      <ListItemText primary="Manage Onboarding Benefits" />
                    </ListItemButton>

                  </AccordionDetails>
                </Accordion>
              </>
            )}

            {(userType === APPROLES.SUPERADMIN.roleId ||
              userType === APPROLES.ADMIN.roleId) && (
              <>
                <ListItemButton
                  className="listItemBtn"
                  onClick={() => {
                    navigate("/admin/posts");
                  }}
                >
                  <ListItemIcon className="listIconSec">
                    <DescriptionIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manage Blogs" />
                </ListItemButton>
              </>
            )}

            {/* {(userType === APPROLES.SUPERADMIN.roleId ||
              userType === APPROLES.ADMIN.roleId) && (
              <>
                <ListItemButton
                  className="listItemBtn"
                  onClick={() => {
                    navigate("/admin/settings");
                  }}
                >
                  <ListItemIcon className="listIconSec">
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Settings" />
                </ListItemButton>
              </>
            )} */}

            {userType === APPROLES.BLOGADMIN.roleId && (
              <>
                <ListItemButton
                  className="listItemBtn"
                  onClick={() => {
                    navigate("/externaladmin/posts");
                  }}
                >
                  <ListItemIcon className="listIconSec">
                    <DescriptionIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manage Blogs" />
                </ListItemButton>
              </>
            )}
          </List>
        </Grid>

        <Grid item xs={10} md={10} lg={10} xl={10} className="pagehdLeftBlock">
          <Outlet />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminLayoutView;
