import React, { useEffect, useState } from "react";
import ParentView from "../../Views/PrarentView/ParentView";
import TermsAndConditionsView from "../../Views/TermsAndConditions/TermsAndConditionsView";
import { useLocation } from "react-router-dom";

const ContactUsScreen = (props: any) => {
  useEffect(() => {}, []);
  const [parentViewCheck, setParentViewCheck] = useState(true);
    const location = useLocation();
    useEffect(() => {
  
      const pathData = location.pathname;
  
      if (pathData.includes("nativeViews")) {
        props.setIsMobileView(true);
        setParentViewCheck(false);
      }
    }, [location]);

  return (
    <>
      {parentViewCheck ? (
        <ParentView>
          <TermsAndConditionsView parentViewCheck={parentViewCheck} />
        </ParentView>
      ) : (
        <TermsAndConditionsView parentViewCheck={parentViewCheck} />
      )}
    </>
  );
};

export default ContactUsScreen;