import { useEffect, useState } from "react";
import { TempleService } from "td_server";
import {
  getChadhavaForTemple,
  updateChadhavaDetailsForTemple,
  addChadhavaDetailsForTemple,
  updateChadhavaBenefitsForTemple,
  resetChadhavaList,
  deleteChadhavaItem,
} from "../../store/actions/ChadhawaAction";
import { useDispatch, useSelector } from "react-redux";
import ChadhawaView from "../../Views/Admin/Chadhawa/ChadhawaView";
import { calculateChadhavaPrice } from "../../utils/HelperFunctions";
import {
  getAllTempleListVerifiedAndUnverified,
  updateOrganization,
} from "../../store/actions/templeAction";
function ChadhawaItemsScreen() {
  const dispatch: any = useDispatch();
  const [ChadhavaTempleList, setChadhavaTempleList] = useState([]);
  const [templeBenefitsList, setTempleBenefitsList] = useState([]);
  const [chadhavaListLocal, setChadhavaListLocal] = useState<any>(null);
  const [selectedTempleId, setSelectedTempleId] = useState<string>("");
  const [selectedTemple, setSelectedTemple] = useState<any>(null);
  const [selectedBenefitsOptions, setSelectedBenefitsOptions] = useState<any>();
  const { isLoading, error, chadhavaList } = useSelector(
    (state: any) => state.chadhawa
  );
  const { benefitsList } = useSelector((state: any) => state.temple);

  useEffect(() => {
    setChadhavaListLocal(chadhavaList);
  }, [chadhavaList]);

  const [allTempleList, setAllTempleList] = useState<any>([]);

  useEffect(() => {
    try {
      dispatch(resetChadhavaList());
      dispatch(getAllTempleListVerifiedAndUnverified()).then(
        (orgResponse: any) => {
          setAllTempleList(orgResponse);
        }
      );
    } catch (error) {
      setAllTempleList([]);
      console.log("Error:", error);
    }
  }, []);

  const handleSetSelectedTemple = (temple: any) => {
    dispatch(resetChadhavaList());
    if (temple?.nameKey && temple?.id) {
      setSelectedTempleId(temple?.id);
      setSelectedTemple(temple);

      dispatch(getChadhavaForTemple(temple?.nameKey));
      if (temple?.chadhavaBenefits) {
        const benefitIds: any = [];
        temple?.chadhavaBenefits?.forEach((el: any) => {
          benefitIds?.push(el?.benefitId);
        });

        setSelectedBenefitsOptions(benefitIds);
      }
    }
  };
  const handleSaveChadhawaItem = async (item: any) => {
    let imageUrl = "";
    const data = Object.assign({}, item);

    let priceInfo = calculateChadhavaPrice(Number(item?.acctualPrice));
    data.priceInfo = priceInfo;
    delete data.id;
    delete data.imageFile;
    if (item?.imageFile) {
      imageUrl = await TempleService.uploadImageForChadhava({
        uri: item?.imageFile,
        platform: "web",
      });
      data.image = imageUrl;
    }

    ///-----------
    if (item?.id && item?.id?.trim().length > 0) {
      dispatch(
        updateChadhavaDetailsForTemple(selectedTempleId, item.id, data)
      ).then(() => {
        dispatch(getChadhavaForTemple(selectedTemple?.nameKey));
      });
    } else {
      dispatch(addChadhavaDetailsForTemple(selectedTempleId, data)).then(() => {
        dispatch(getChadhavaForTemple(selectedTemple?.nameKey));
      });
    }
  };

  const handleBenefitsSelect = (items: any) => {
    // handleBenefitsSelect
    // if (selectedTempleId) {
    //   dispatch(updateChadhavaBenefitsForTemple(selectedTempleId, items));
    // }
    setTempleBenefitsList(items);
  };

  const handleBenefitsSubmit = () => {
    if (selectedTempleId && templeBenefitsList?.length > 0) {
      dispatch(
        updateChadhavaBenefitsForTemple(selectedTempleId, templeBenefitsList)
      );
      const benefitIds: any = [];
      templeBenefitsList?.forEach((el: any) => {
        benefitIds.push(el?.benefitId);
      });

      setSelectedBenefitsOptions((prev: any[]) => {
        const newOptions = [...prev, ...benefitIds];
        const uniqueOptions = Array.from(new Set(newOptions));
        return uniqueOptions;
      });
    }
  };

  const handleTempleWeightageSubmit = (
    weightage: any,
    indianMinimumPrice: any,
    overseasMinimumPrice: any
  ) => {
    if (selectedTempleId && selectedTemple && weightage) {
      dispatch(
        updateOrganization(
          selectedTempleId,
          "chadhavaWeightageForTemple",
          weightage
        )
      );
    }
    if (selectedTempleId && selectedTemple && indianMinimumPrice) {
      dispatch(
        updateOrganization(
          selectedTempleId,
          "minBookingChadhavaPriceIND",
          indianMinimumPrice
        )
      );
    }
    if (selectedTempleId && selectedTemple && overseasMinimumPrice) {
      dispatch(
        updateOrganization(
          selectedTempleId,
          "minBookingChadhavaPriceOverseas",
          overseasMinimumPrice
        )
      );
    }
  };

  // const saveChadhavaDate = ()=>{
  //   const data = [...selectedDays,...selectedDates]
  //   if(selectedTempleId && data?.length > 0){
  //     dispatch(updateChadhavaDateForTemple(selectedTempleId,data))
  //   }
  // }

  const handleDeleteChadhavaItem = (item: any) => {
    dispatch(deleteChadhavaItem(selectedTempleId, item.id)).then(() => {
      dispatch(resetChadhavaList());
      dispatch(getChadhavaForTemple(selectedTemple?.nameKey));
    });
  };

  return (
    <ChadhawaView
      temples={allTempleList}
      setSelectedTemple={handleSetSelectedTemple}
      chadhavaList={chadhavaListLocal}
      saveChadhawaItem={handleSaveChadhawaItem}
      benefitsList={benefitsList}
      selectedBenefitsOptions={selectedBenefitsOptions}
      handleBenefitsSelect={handleBenefitsSelect}
      handleBenefitsSubmit={handleBenefitsSubmit}
      selectedTempleId={selectedTempleId}
      deleteChadhavaItem={handleDeleteChadhavaItem}
      selectedTemple={selectedTemple}
      handleTempleWeightageSubmit={handleTempleWeightageSubmit}
    />
  );
}

export default ChadhawaItemsScreen;
