import { setDarshanList, setLoading } from "../slices/LiveDarshanSlice";
import { CommonService } from "td_server";
import { setManageNewOnTDList } from "../slices/manageNewOnTempleDekhoSlice";

export const addManageNewOnTDItem =
  (formData: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));

      const response: any = await CommonService.addOfferModalFromWeb(formData);

      const responseManageNewOnTempleDekhoList: any =
        await CommonService.getActiveAndInActiveOfferModalFromWeb();
      dispatch(setManageNewOnTDList(responseManageNewOnTempleDekhoList.data));
      dispatch(setLoading(false));

      return Promise.resolve(response);
    } catch (e) {
      console.error(
        formData?.id ? "Error updating item:" : "Error adding item:",
        e
      );
      dispatch(setLoading(false));
      return Promise.reject(e);
    }
  };

export const deleteManageNewOnTDItem =
  (id: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await CommonService.deleteOfferModalFromWeb(id);
      if (response) {
        const responseManageNewOnTempleDekhoList: any =
          await CommonService.getActiveAndInActiveOfferModalFromWeb();
        dispatch(setDarshanList(responseManageNewOnTempleDekhoList));
      } else {
      }
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      console.error("Error deleting item:", e);
      dispatch(setLoading(false));
      return Promise.reject(e);
    }
  };

export const getAllManageNewOnTDList =
  () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const responseSetManageNewOnTdList: any =
        await CommonService.getActiveAndInActiveOfferModalFromWeb();
      dispatch(setManageNewOnTDList(responseSetManageNewOnTdList.data));
      dispatch(setLoading(false));
      return Promise.resolve(responseSetManageNewOnTdList.data);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };
