import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { ManageOnboardingBenefits } from "../../../utils/ConstantsMessages";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import DeleteConfirmDialog from "../../../components/dialog/DeleteConfirmDialog";
import ManageOnboardingBenefitsForm from "./ManageOnboardingBenefitsForm";

interface ManageOnboardingBenefitsViewProps {
  onSubmit: (formData: any) => void;
  manageOnboardingBenefitsList: any;
  handleEditOnboardingBenefitsItem: any;
  openOnboardingBenefitsForm: boolean;
  handleOpenOnboardingBenefitsForm: () => void;
  handleCloseOnboardingBenefitsForm: () => void;
  selectedManageOnboardingBenefitsItem: any;
  setSelectedManageOnboardingBenefitsItem: any;
  handleDeleteOnboardingBenefitsItem: (id: any) => void;
}

const ManageOnboardingBenefitsView: React.FC<
  ManageOnboardingBenefitsViewProps
> = (props) => {
  const {
    onSubmit,
    manageOnboardingBenefitsList,
    handleEditOnboardingBenefitsItem,
    openOnboardingBenefitsForm,
    handleOpenOnboardingBenefitsForm,
    handleCloseOnboardingBenefitsForm,
    selectedManageOnboardingBenefitsItem,
    handleDeleteOnboardingBenefitsItem,
  } = props;

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const handleDeleteClick = (item: any) => {
    setSelectedItem(item);
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    if (selectedItem) {
      handleDeleteOnboardingBenefitsItem(selectedItem.id);
    }
    handleCloseDeleteDialog();
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setSelectedItem(null);
  };


  return (
    <Box className="pageContainer specialPujaAdmin">
      <Box className="topTitle">
        <Typography className="adminTitleText">
          {ManageOnboardingBenefits.title}
        </Typography>
      </Box>

      <Box className="btnAddSpecialPuja">
        <Button onClick={handleOpenOnboardingBenefitsForm} variant="contained">
          <AddCircleOutlineIcon sx={{ fontSize: "20px", mr: 1 }} />
          {ManageOnboardingBenefits.button}
        </Button>
      </Box>

      {manageOnboardingBenefitsList ? (
        <List className="specialPujaItemListAdmin">
          {manageOnboardingBenefitsList?.benefitsList?.map(
            (_manageOnboardingBenefitsList: any, index: number) => {

              return (
                <ListItem
                  key={_manageOnboardingBenefitsList?.id}
                  className="specialPujaAdminCard"
                  sx={{
                    backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#d569391a",
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      src={_manageOnboardingBenefitsList?.url}
                      sx={{
                        width: 80,
                        height: 80,
                        marginRight: 2,
                        borderRadius: "10px",
                        border: "1px solid #cdcdcd",
                      }}
                    />
                  </ListItemAvatar>

                  <ListItemText
                    className="listText"
                    primary={
                      <Box>
                        <Typography variant="body1" fontWeight="bold">
                          {
                            _manageOnboardingBenefitsList?.name
                          }
                        </Typography>
                      </Box>
                    }
                  />
                  <IconButton
                    className="spPujaEditBtn"
                    aria-label="edit"
                    onClick={(e) => {
                      e.preventDefault();
                      handleEditOnboardingBenefitsItem(
                        _manageOnboardingBenefitsList
                      );
                    }}
                  >
                    <EditIcon sx={{ fontSize: "18px" }} />
                  </IconButton>
                  <IconButton
                    className="spPujaDeleteBtn"
                    aria-label="delete"
                    onClick={() =>
                      handleDeleteClick(_manageOnboardingBenefitsList)
                    }
                  >
                    <DeleteIcon sx={{ fontSize: "18px" }} />
                  </IconButton>
                </ListItem>
              );
            }
          )}
        </List>
      ) : (
        <NoDataFound />
      )}

      <Dialog
        open={openOnboardingBenefitsForm}
        onClose={handleCloseOnboardingBenefitsForm}
        fullWidth
      >
        <DialogContent>
          <ManageOnboardingBenefitsForm
            onSubmit={onSubmit}
            onClose={handleCloseOnboardingBenefitsForm}
            selectedManageOnboardingBenefitsItem={selectedManageOnboardingBenefitsItem}
          />
        </DialogContent>
      </Dialog>

      <DeleteConfirmDialog
        open={isDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleConfirmDelete}
        msg="Are you sure you want to delete this item?"
      />
    </Box>
  );
};

export default ManageOnboardingBenefitsView;
