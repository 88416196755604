import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getPromotionalPujaListById } from "../../store/actions/pujaAction";
import { useDispatch } from "react-redux";
import { getTempleDetailsById } from "../../store/actions/templeAction";

const PageLinkScreen: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: any = useDispatch();
  useEffect(() => {
    // Parse the URL parameters
    const params = new URLSearchParams(location.search);
    const templeId = params.get("templeid");
    const singlePuja = params.get("singlepuja");
    const visheshpuja = params.get("visheshpuja");
    const pujaoftemple = params.get("pujaoftemple"); // id of org coming
    const chadhavaOftemple = params.get("chadhavaOftemple");
    const blogId = params.get("blogId");
    const pujaType = params.get("pujaType");
    const page = params.get("page");

    const subcategory = params.get("subcategory");
    const horoscopedetails = params.get("horoscopedetails");

    //New routes With GET params
    const templeNameKey = params.get("templeNameKey");
    const pujaNameKey = params.get("pujaNameKey");
    const specialPuja = params.get("specialPuja");
    const prasadOftemple = params.get("prasadOftemple");
    const specialChadhavaNameKey = params.get("specialChadhavaNameKey");

    let newUrl = "/apps";
   
     if(pujaNameKey && pujaNameKey === 'undefined'){
      navigate("/app");
      return;
     }

     if(templeNameKey && templeNameKey === 'undefined'){
      navigate("/app");
      return;
     }
    // Construct the new URL
    if (visheshpuja) {
      // newUrl = `/promotionalPuja/${visheshpuja}`;
      oldViseshPujaRedirect(visheshpuja);
      return;
    } else if (pujaoftemple) {
      oldTempleDetailsRedirect(pujaoftemple);
      return;
      // newUrl = `/temples/${pujaoftemple}`;
    } else if (chadhavaOftemple) {
      newUrl = `/chadhavaDetails/${chadhavaOftemple}`;
    } else if (templeId && singlePuja) {
      newUrl = `/temples/${templeId}/pujaInfo/${singlePuja}`;
    } else if (subcategory) {
      newUrl = `/pujaSubCategory/${subcategory}`;
    } else if (horoscopedetails) {
      newUrl = `/horoscopeDetails?zodiacName=${horoscopedetails}`;
    } else if (blogId) {
      newUrl = `/posts/content/${blogId}`;
    }  else if (prasadOftemple) {
      newUrl = `/prasadDetails/${prasadOftemple}`;
    }

    //
    else if (templeNameKey && pujaNameKey) {
      newUrl = `/templePujaInfo?templeNameKey=${templeNameKey}&pujaNameKey=${pujaNameKey}`;
    }
    else if(pujaType && templeNameKey){
      newUrl = `/${pujaType}?templeNameKey=${templeNameKey}`;
    }
    else if (templeNameKey) {
      newUrl = `/templeInfo?templeNameKey=${templeNameKey}`;
    } else if (specialPuja) {
      newUrl = `/specialPuja?pujaNameKey=${specialPuja}`;
    }

    //
    else if (templeNameKey && pujaNameKey) {
      newUrl = `/templePujaInfo?templeNameKey=${templeNameKey}&pujaNameKey=${pujaNameKey}`;
    } else if (templeNameKey) {
      newUrl = `/templeInfo?templeNameKey=${templeNameKey}`;
    } else if (specialPuja) {
      newUrl = `/specialPuja?pujaNameKey=${specialPuja}`;
    }
    else if(specialChadhavaNameKey){
      newUrl=`/specialChadhava?specialChadhavaNameKey=${specialChadhavaNameKey}`
    }
   
    if(page){
      newUrl=`/${page}`;
    }

    navigate(newUrl);
  }, [navigate, location.search]);

  //Old navigation
  const oldViseshPujaRedirect = async (visheshpuja: any) => {
    dispatch(getPromotionalPujaListById(visheshpuja))
      .then((data: any) => {
        let newUrl = `/specialPuja?pujaNameKey=${data?.pujaNameKey}`;
        navigate(newUrl);
      })
      .catch((err: any) => {
        console.log("Errror:", err);
      });
  };

  const oldTempleDetailsRedirect = async (templeId: any) => {
    dispatch(getTempleDetailsById(templeId))
      .then((data: any) => {
        let newUrl = `/templeInfo?templeNameKey=${data.nameKey}`;
        navigate(newUrl);
      })
      .catch((err: any) => {
        console.log("Errror", err);
      });
  };

  return <div>Redirecting...</div>;
};

export default PageLinkScreen;
