import React, { useEffect, useState } from "react";
import ManageNewOnTDView from "../../../Views/Admin/ManageNewOnTD/ManageNewOnTempleDekhoView";
import { useDispatch, useSelector } from "react-redux";
import {
  addManageNewOnTDItem,
  deleteManageNewOnTDItem,
  getAllManageNewOnTDList,
} from "../../../store/actions/manageNewOnTDAction";
import { CommonService } from "td_server";

const ManageNewOnTDScreen = () => {
  const dispatch: any = useDispatch();
  const { manageNewOnTDList } = useSelector(
    (state: any) => state?.manageNewOnTD
  );
  const [selectedManageNewOnTDItem, setSelectedManageNewOnTDItem] =
    useState<any>(null);
  const [openManageNewOnForm, setOpenManageNewOnForm] = useState(false);

  useEffect(() => {
    try {
      dispatch(getAllManageNewOnTDList());
    } catch (error) {
      console.error("Error fetching list:", error);
    }
  }, [dispatch]);

  const handleOpenManageNewOnForm = () => {
    setOpenManageNewOnForm(true);
  };

  const handleCloseManageNewOnForm = () => {
    setOpenManageNewOnForm(false);
    setSelectedManageNewOnTDItem(null);
  };
  const handleDeleteManageNewOnTD = async (item: any) => {
    try {
      await dispatch(deleteManageNewOnTDItem(item.id));
      console.log("Item deleted successfully!");
    } catch (error) {
      console.error("Failed to delete item:", error);
    }
  };

  const handleEditManageNewOnTDItem = (item: any) => {
    setSelectedManageNewOnTDItem(item);
    handleOpenManageNewOnForm();
  };

  const handleSubmit = async (formData: any) => {
    let payload: any = {
      image: selectedManageNewOnTDItem?.image,
      dashboardImage: selectedManageNewOnTDItem?.dashboardImage,
      isActive: formData?.isActive,
      name: formData?.name,
      onClick: {
        navId: formData?.mobileNavigation,
        params: {
          position: formData?.position,
          searchParam: formData?.searchInput,
          title: formData?.title,
          url: formData?.url,
        },
        web: {
          navId: formData?.webNavigation,
        },
      },
    };
    if (selectedManageNewOnTDItem) {
      payload = { ...selectedManageNewOnTDItem, ...payload };
    }

    if (formData.image && formData.image instanceof File) {
      try {
        const uploadedImage = await CommonService.fileUpload(
          {
            uri: formData.image,
            platform: "web",
          },
          "users_details/offer_model"
        );
        payload.image = uploadedImage;
        payload.dashboardImage = uploadedImage;
      } catch (error) {
        console.error("Error uploading image:", error);
        return;
      }
    }
    try {
      const response = await dispatch(addManageNewOnTDItem(payload));
      console.log("Data successfully saved!", response);
      dispatch(getAllManageNewOnTDList());
      handleCloseManageNewOnForm();
    } catch (error) {
      console.error("Error in submission:", error);
    }
  };


  return (
    <ManageNewOnTDView
      onSubmit={handleSubmit}
      manageNewOnTDList={manageNewOnTDList}
      handleEditManageNewOnTDItem={handleEditManageNewOnTDItem}
      openManageNewOnForm={openManageNewOnForm}
      handleOpenManageNewOnForm={handleOpenManageNewOnForm}
      handleCloseManageNewOnForm={handleCloseManageNewOnForm}
      setSelectedManageNewOnTDItem={setSelectedManageNewOnTDItem}
      selectedManageNewOnTDItem={selectedManageNewOnTDItem}
      handleDeleteManageNewOnTD={handleDeleteManageNewOnTD}
    />
  );
};

export default ManageNewOnTDScreen;
