/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  TempleService,
  CommonService,
} from "td_server";
import {
  setLoading,
  setSelectedTempleDetailsForPuja,
  setPromotionalList,
  setSelectedPromotionalPujaOrganisations,
  setPujaCategoryList,
  setPujaMergedSubCategoryList,
} from "./../slices/pujaSlice";
export const getTempleDetailsById =
  (templeId: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.getOrganisationDetailsById(
        templeId
      );
      dispatch(setSelectedTempleDetailsForPuja(response));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getPromotionalPujaList =
  () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any =
        await TempleService.getPromotionalPujaDashBoardListActive();
      dispatch(setPromotionalList(response?.promotionalList));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };
  // get both special promotional puja and chadhava active
  export const getPromotionalPujaChadhavaDashBoardListActive =
  () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any =
        await TempleService.getPromotionalPujaChadhavaDashBoardListActive();
      dispatch(setPromotionalList(response?.promotionalList));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

  export const getPromotionalPujaChadhavaDashBoardListActiveWithPriceConversion =
  (isOverseas: boolean = false,
    platformFeeForChadhava: number = 0,
    rate = 1
  ) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any =
        await TempleService.getPromotionalChadhavaDashBoardListActiveWithPriceConversion(isOverseas,platformFeeForChadhava,rate);
      if(response?.promotionalList){
        dispatch(setPromotionalList(response?.promotionalList));
      }
      
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getSpecialPujaList =
  () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any =
        await TempleService.getPromotionalPujaDashBoardListActiveInactive();
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getPromotionalPujaListById =
  (id: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.getPromotionalPujaListById(id);
  
      dispatch(setSelectedPromotionalPujaOrganisations(response?.organisation));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
    }
  };

export const getPromotionalPujaListByNameKey =
  (nameKey: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.getPromotionalPujaListByNameKey(
        nameKey
      );
      dispatch(setSelectedPromotionalPujaOrganisations(response?.organisation));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      console.log("Error : ", e);
      dispatch(setLoading(false));
    }
  };

export const getTemplePujaCategoryList =
  () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await CommonService.getTemplePujaCategoryList();
      dispatch(setPujaCategoryList(response?.categoryList));
      dispatch(setPujaMergedSubCategoryList(response?.mergedSubCategory));

      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const addPromotionalPujaAction =
  (data: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.addPromotionalPujaFromWeb(data);
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const deletePromotionalPujaAction =
  (templePuja: any, specialPujaId: any) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.deletePromotionalPuja(
        templePuja,
        specialPujaId
      );
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };
