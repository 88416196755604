import { Box, Typography } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  CAROUSEL_REMOVE_ARRAOW_ON_DEVICE,
  UPCOMING_PUJA_CAROUSAL_RESPONSIVE,
} from "../../../utils/Constants";
import UpcomingPujasCard from "../UpcomingPujas/UpcomingPujasCard";
import { useNavigate } from "react-router-dom";
import "./OfferItemCard.scss";

export default function OfferItemCard(props: any) {
  const navigate = useNavigate();
  const { pujaOffer } = props;

  const isSingleItem = pujaOffer?.length === 1;

  return (
    <Carousel
      responsive={UPCOMING_PUJA_CAROUSAL_RESPONSIVE}
      swipeable={true}
      draggable={true}
      showDots={true}
      keyBoardControl={true}
      removeArrowOnDeviceType={CAROUSEL_REMOVE_ARRAOW_ON_DEVICE}
      className={`offerCarousel ${isSingleItem ? "singleItem" : ""}`}
    >
      {pujaOffer?.map((item: any, index: number) => (
        <Box
          onClick={() => navigate(item?.onClick?.web?.navId)}
          key={`offerImg${item?.id}`}
        >
          <UpcomingPujasCard image={item.dashboardImage} />
        </Box>
      ))}
    </Carousel>
  );
}
