import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  DialogTitle,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import "./ManageNewOnTempleDekhoForm.scss";

interface ManageNewOnFormProps {
  onSubmit: (formData: any, isEdit: boolean) => void;
  onClose: () => void;
  selectedManageNewOnTDItem: any;
}

const ManageNewOnTempleDekhoForm: React.FC<ManageNewOnFormProps> = ({
  onSubmit,
  onClose,
  selectedManageNewOnTDItem,
}) => {
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [localImageManageNewOn, setLocalImageManageNewOn] =
    useState<string>("");
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [formData, setFormData] = useState<any>({
    name: "New On Temple Dekho",
    title: "",
    searchInput: "",
    mobileNavigation: "",
    webNavigation: "",
    position: "",
    image: "",
    isActive: false,
    url: "",
  });

  const [errors, setErrors] = useState<any>({
    name: "",
    title: "",
    searchInput: "",
    mobileNavigation: "",
    webNavigation: "",
    position: "",
    image: "",
    url: "",
  });

  useEffect(() => {
    if (selectedManageNewOnTDItem) {
      setIsEdit(true);
      const loadFormData: any = {
        name: selectedManageNewOnTDItem?.name ?? "",
        title: selectedManageNewOnTDItem?.onClick?.params?.title ?? "",
        searchInput:
          selectedManageNewOnTDItem?.onClick?.params?.searchParam ?? "",
        mobileNavigation: selectedManageNewOnTDItem?.onClick?.navId ?? "",
        webNavigation: selectedManageNewOnTDItem?.onClick?.web?.navId ?? "",
        position: selectedManageNewOnTDItem?.onClick?.params?.position ?? "",
        image:
          selectedManageNewOnTDItem?.image ??
          selectedManageNewOnTDItem?.dashboardImage ??
          "",
        isActive: selectedManageNewOnTDItem?.isActive ?? false,
        url: selectedManageNewOnTDItem?.onClick?.params?.url ?? "",
      };
      setFormData({ ...loadFormData });
      setLocalImageManageNewOn(loadFormData?.image ? loadFormData?.image : "");
    } else {
      resetForm();
    }
  }, [selectedManageNewOnTDItem]);

  const resetForm = () => {
    setFormData({
      name: "New On Temple Dekho",
      title: "",
      searchInput: "",
      mobileNavigation: "",
      webNavigation: "",
      position: "",
      image: "",
      isActive: false,
      url: "",
    });
    setLocalImageManageNewOn("");
    setErrors({
      name: "",
      title: "",
      searchInput: "",
      mobileNavigation: "",
      webNavigation: "",
      position: "",
      image: "",
      url: "",
    });
    setIsEdit(false);
  };

  const validateForm = () => {
    let valid = true;
    const newErrors: any = {
      name: "",
      title: "",
      searchInput: "",
      mobileNavigation: "",
      webNavigation: "",
      position: "",
      image: "",
      url: "",
    };

    if (!formData?.name.trim()) {
      newErrors.name = "Name is required.";
      valid = false;
    }
    if (!formData?.title.trim()) {
      newErrors.title = "Title is required.";
      valid = false;
    }
    if (!formData?.searchInput.trim()) {
      newErrors.searchInput = "Search input is required.";
      valid = false;
    }
    if (!formData?.mobileNavigation.trim()) {
      newErrors.mobileNavigation = "Mobile navigation is required.";
      valid = false;
    }
    if (!formData?.webNavigation.trim()) {
      newErrors.webNavigation = "Web navigation is required.";
      valid = false;
    }
    if (!formData?.position?.toString().trim()) {
      newErrors.position = "Position is required.";
      valid = false;
    }
    if (!localImageManageNewOn && !formData?.image) {
      newErrors.image = "Image upload is required.";
      valid = false;
    }
    if (!formData?.url.trim()) {
      newErrors.url = "URL is required.";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked, files } = event.target;

    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else if (type === "file" && files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setLocalImageManageNewOn(e.target?.result as string);
      };
      reader.readAsDataURL(files[0]);
      setFormData({
        ...formData,
        [name]: files[0],
      });

      setErrors((prevErrors: any) => ({
        ...prevErrors,
        image: "",
      }));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });

      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmit(true);

    if (!validateForm()) {
      return;
    }

    const updatedFormData = {
      ...formData,
    };

    onSubmit(updatedFormData, isEdit);
    resetForm();
    setIsSubmit(false);
    onClose();
  };

  const handleCancel = () => {
    resetForm();
    onClose();
  };

  return (
    <>
      <DialogTitle className="spAdminDialogTitle">
        {isEdit ? "Edit New On Item" : "Add New On Item"}
      </DialogTitle>
      <Box className="manageNewOnForm">
        <form onSubmit={handleSubmit}>
          <Box mb={1}>
            <TextField
              label="Name"
              variant="outlined"
              name="name"
              value={formData?.name}
              // onChange={handleChange}
              fullWidth
              error={!!errors.name}
              helperText={errors.name}
              disabled
            />
          </Box>

          <Box mb={2} className="imageUploadSection">
            <TextField
              name="image"
              label="Upload Image *"
              type="file"
              fullWidth
              margin="normal"
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                accept: "image/*",
              }}
              error={!!errors.image}
              helperText={errors.image}
            />
            {(localImageManageNewOn || formData?.image) && (
              <Box mt={2} className="imagePreview">
                <img
                  src={localImageManageNewOn || formData?.image}
                  alt="Uploaded Preview"
                  className="previewImage"
                />
              </Box>
            )}
          </Box>

          <Box mb={1} className="checkboxArea">
            <FormControlLabel
              control={
                <Checkbox
                  name="isActive"
                  checked={formData?.isActive}
                  onChange={handleChange}
                />
              }
              label="Is Active"
            />
          </Box>

          <Box className="sectionHeader">
            <Typography variant="h5">Mobile</Typography>
          </Box>
          <Box mb={3}>
            <TextField
              label="Mobile Navigation"
              variant="outlined"
              name="mobileNavigation"
              value={formData?.mobileNavigation}
              onChange={handleChange}
              fullWidth
              error={!!errors.mobileNavigation}
              helperText={errors.mobileNavigation}
            />
          </Box>

          <Box className="sectionHeader">
            <Typography variant="h5">Parameter</Typography>
          </Box>
          <Box mb={1}>
            <TextField
              label="Position"
              variant="outlined"
              name="position"
              type="number"
              value={formData?.position}
              onChange={handleChange}
              fullWidth
              error={!!errors.position}
              helperText={errors.position}
            />
          </Box>

          <Box mb={1}>
            <TextField
              label="Search Input"
              variant="outlined"
              name="searchInput"
              value={formData?.searchInput}
              onChange={handleChange}
              fullWidth
              error={!!errors.searchInput}
              helperText={errors.searchInput}
            />
          </Box>

          <Box mb={1}>
            <TextField
              label="Title"
              variant="outlined"
              name="title"
              value={formData?.title}
              onChange={handleChange}
              fullWidth
              error={!!errors.title}
              helperText={errors.title}
            />
          </Box>

          <Box mb={3}>
            <TextField
              label="URL"
              variant="outlined"
              name="url"
              value={formData?.url}
              onChange={handleChange}
              fullWidth
              error={!!errors.url}
              helperText={errors.url}
            />
          </Box>

          <Box className="sectionHeader">
            <Typography variant="h5">Web</Typography>
          </Box>
          <Box mb={2}>
            <TextField
              label="Web Navigation"
              variant="outlined"
              name="webNavigation"
              value={formData?.webNavigation}
              onChange={handleChange}
              fullWidth
              error={!!errors.webNavigation}
              helperText={errors.webNavigation}
            />
          </Box>

          <Stack
            className="buttonArea"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Button type="submit" variant="contained" color="primary">
              {isEdit ? "Update" : "Submit"}
            </Button>

            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
          </Stack>
        </form>
      </Box>
    </>
  );
};

export default ManageNewOnTempleDekhoForm;
