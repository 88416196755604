import { useEffect, useLayoutEffect, useState } from "react";
import ChadhawaView from "../../Views/Admin/Chadhawa/ChadhawaView";
import { useDispatch, useSelector } from "react-redux";
import TempleView from "../../Views/Admin/Temple/TempleView";
import {
  getAllTempleListVerifiedAndUnverified,
  syncSearchDataBase,
  updateOrganization,
} from "../../store/actions/templeAction";
const TempleAdminScreen: React.FC<any> = () => {
  const dispatch: any = useDispatch();
  const [temples, setTemples] = useState<any>([]);
  const [isSearchSyncing, setIsearchSyncing] = useState<boolean>(false);

  const [allTempleList, setAllTempleList] = useState<any>([]);

  useEffect(() => {
    try {
      dispatch(getAllTempleListVerifiedAndUnverified()).then(
        (orgResponse: any) => {
          setAllTempleList(orgResponse);
        }
      );
    } catch (error) {
      setAllTempleList([]);
      console.log("Error:", error);
    }
  }, []);
  useLayoutEffect(() => {
    setTemples(allTempleList);
  }, [allTempleList]);

  const handleChadhavaToggle = async (temple: any) => {
    if (temple && temple?.id) {
      const value = !temple.isChadhavaEnabled;
      await updateOrganizationById(temple.id, "isChadhavaEnabled", value);
      if (temple.isChadhavaEnabled === false) {
        await updateOrganizationById(temple.id, "isClosed", false);
      }
    }
  };

  const handleToggleTemple = async (temple: any) => {
    if (temple && temple?.id) {
      const value = !temple.isClosed;
      await updateOrganizationById(temple.id, "isClosed", value);
      if (temple.isClosed === false) {
        await updateOrganizationById(temple.id, "isClosedBooking", true);
        await updateOrganizationById(temple.id, "isPrasadEnabled", false);
        await updateOrganizationById(temple.id, "isChadhavaEnabled", false);
      }
    }
  };

  const handleIsClosedBooking = async (temple: any) => {
    if (temple && temple?.id) {
      const value = !temple.isClosedBooking;
      await updateOrganizationById(temple.id, "isClosedBooking", value);
      if (temple.isClosedBooking === true) {
        await updateOrganizationById(temple.id, "isClosed", false);
      }
    }
  };

  const handlePositonSubmitFortemple = (
    templeId: any,
    templePositionValue: any
  ) => {
    if (templeId && templePositionValue) {
      updateOrganizationById(templeId, "position", templePositionValue);
    }
  };

  const handleTogglePrasadForTemple = async (temple: any) => {
    if (temple && temple?.id) {
      const value = !temple.isPrasadEnabled;
      await updateOrganizationById(temple.id, "isPrasadEnabled", value);
      if (temple.isPrasadEnabled === false) {
        await updateOrganizationById(temple.id, "isClosed", false);
      }
    }
  };

  const updateOrganizationById = async (
    id: string,
    key: string,
    value: boolean
  ) => {
    try {
      await dispatch(updateOrganization(id, key, value));
      setTemples((prevItems: any) =>
        prevItems.map((item: any) =>
          item.id === id ? { ...item, [key]: value } : item
        )
      );
    } catch (err: any) {
      console.log("Error :", err);
    }
  };

  const handleSyncSearchDatabase = () => {
    if (isSearchSyncing) return;
    try {
      setIsearchSyncing(true);
      dispatch(syncSearchDataBase()).then(() => {
        setIsearchSyncing(false);
      });
    } catch (error) {
      console.log("Error :", error);
      setIsearchSyncing(false);
    }
  };
  return (
    <TempleView
      temples={temples}
      handleChadhavaToggle={handleChadhavaToggle}
      handleToggleTemple={handleToggleTemple}
      handleIsClosedBooking={handleIsClosedBooking}
      handlePositonSubmitFortemple={handlePositonSubmitFortemple}
      handleTogglePrasadForTemple={handleTogglePrasadForTemple}
      isSearchSyncing={isSearchSyncing}
      handleSyncSearchDatabase={handleSyncSearchDatabase}
    />
  );
};

export default TempleAdminScreen;
