import React, { useEffect, useState, useCallback } from "react";
import ManageOnboardingBenefitsView from "../../../Views/Admin/ManageOnboardingBenefits/ManageOnboardingBenefitsView";
import {
  addOnboardingBenefitsItem,
  deleteOnboardingBenefitsItem,
  getAllManageOnboardingBenefitsList,
  updateOnbordingBenefitsItem,
} from "../../../store/actions/mangeOnboardingBenefitsAction";
import { useDispatch, useSelector } from "react-redux";
import { CommonService } from "td_server";

const ManageOnboardingBenefitsScreen = () => {
  const dispatch: any = useDispatch();
  const { manageOnboardingBenefitsList } = useSelector(
    (state: any) => state?.manageOnboardingBenefits
  );
  const [
    selectedManageOnboardingBenefitsItem,
    setSelectedManageOnboardingBenefitsItem,
  ] = useState<any>(null);
  const [openForm, setOpenForm] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getAllManageOnboardingBenefitsList());
      } catch (error) {
        console.error("Error fetching Manage Onboarding Benefits List:", error);
      }
    };
    fetchData();
  }, [dispatch]);

  const handleOpenOnboardingBenefitsForm = () => {
    setOpenForm(true);
  };

  const handleCloseOnboardingBenefitsForm = () => {
    setOpenForm(false);
    setSelectedManageOnboardingBenefitsItem(null);
  };
  
  const handleEditOnboardingBenefitsItem = (item: any) => {
    setSelectedManageOnboardingBenefitsItem(item);
    handleOpenOnboardingBenefitsForm();
  };

  const handleDeleteOnboardingBenefitsItem = async (item: any) => {
    try {
      await dispatch(deleteOnboardingBenefitsItem(item));
    } catch (error) {
      console.error("Failed to delete item:", error);
    }
  };

  const handleSubmit = async (formData: any) => {
    let payload: any = {
      name: formData?.name,
      description: formData?.description,
      category: formData?.category,
      url: selectedManageOnboardingBenefitsItem?.url || "",
    };
    if (selectedManageOnboardingBenefitsItem) {
      payload = { ...selectedManageOnboardingBenefitsItem, ...payload };
    }
    if (formData.url && formData.url instanceof File) {
      try {
        const uploadedImage = await CommonService.fileUpload(
          {
            uri: formData.url,
            platform: "web",
          },
          "users_details/benefits"
        );
        payload.url = uploadedImage;
      } catch (error) {
        console.error("Error uploading image:", error);
        return;
      }
    }
    try {
      if (selectedManageOnboardingBenefitsItem?.id) {
        const response = await dispatch(
          updateOnbordingBenefitsItem(
            payload,
            selectedManageOnboardingBenefitsItem.id
          )
        );
      } else {
        const response = await dispatch(addOnboardingBenefitsItem(payload));
      }
      dispatch(getAllManageOnboardingBenefitsList());
      handleCloseOnboardingBenefitsForm();
    } catch (error) {
      console.error("Error in submission:", error);
    }
  };

  return (
    <ManageOnboardingBenefitsView
      onSubmit={handleSubmit}
      manageOnboardingBenefitsList={manageOnboardingBenefitsList}
      handleEditOnboardingBenefitsItem={handleEditOnboardingBenefitsItem}
      openOnboardingBenefitsForm={openForm}
      handleOpenOnboardingBenefitsForm={handleOpenOnboardingBenefitsForm}
      handleCloseOnboardingBenefitsForm={handleCloseOnboardingBenefitsForm}
      setSelectedManageOnboardingBenefitsItem={
        setSelectedManageOnboardingBenefitsItem
      }
      selectedManageOnboardingBenefitsItem={
        selectedManageOnboardingBenefitsItem
      }
      handleDeleteOnboardingBenefitsItem={handleDeleteOnboardingBenefitsItem}
    />
  );
};

export default ManageOnboardingBenefitsScreen;
