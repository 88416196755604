import { useEffect, useState } from "react";
import { PrasadService } from "td_server";
import {
  getPrasadForTemple,
  updatePrasadDetailsForTemple,
  addPrasadDetailsForTemple,
  updatePrasadBenefitsForTemple,
  resetPrasadList,
  deletePrasadItem,
} from "../../store/actions/prasadAction";
import { useDispatch, useSelector } from "react-redux";
import {
  calculatePrasadForOnboardingPrice,
  logger,
} from "../../utils/HelperFunctions";
import {
  getAllTempleListVerifiedAndUnverified,
  updateOrganization,
} from "../../store/actions/templeAction";
import ManagePrasadView from "../../Views/Admin/ManagePrasad/ManagePrasadView";
function ManagePrasadScreen() {
  const dispatch: any = useDispatch();
  const [prasadTempleList, setPrasadTempleList] = useState([]);
  const [templeBenefitsList, setTempleBenefitsList] = useState([]);
  const [prasadListLocal, setPrasadListLocal] = useState<any>(null);
  const [selectedTempleId, setSelectedTempleId] = useState<string>("");
  const [selectedTemple, setSelectedTemple] = useState<any>(null);
  const [selectedBenefitsOptions, setSelectedBenefitsOptions] = useState<any>();
  const [prasadOverViewForTemple, setPrasadOverViewForTemple] =
    useState<any>("");
  const { isLoading, error, prasadList } = useSelector(
    (state: any) => state.prasad
  );

  const { benefitsList } = useSelector((state: any) => state.temple);

  const [allTempleList, setAllTempleList] = useState<any>([]);

  useEffect(() => {
    setPrasadListLocal(prasadList);
  }, [prasadList]);

  useEffect(() => {
    try {
      dispatch(resetPrasadList());
      dispatch(getAllTempleListVerifiedAndUnverified()).then(
        (orgResponse: any) => {
          setAllTempleList(orgResponse);
        }
      );
    } catch (error) {
      setAllTempleList([]);
      console.log("Error:", error);
    }
  }, []);

  const handleSetSelectedTemple = (temple: any) => {
    dispatch(resetPrasadList());
    if (temple?.nameKey && temple?.id) {
      setSelectedTempleId(temple?.id);
      setSelectedTemple(temple);

      dispatch(getPrasadForTemple(temple?.nameKey));
      setSelectedBenefitsOptions([]);
      if (temple?.prasadBenefits) {
        const benefitIds: any = [];
        temple?.prasadBenefits?.forEach((el: any) => {
          benefitIds.push(el?.benefitId);
        });

        setSelectedBenefitsOptions(benefitIds);
      }
      setPrasadOverViewForTemple(temple?.prasadOverview || "");
    }
  };
  const handleSavePrasadItem = async (item: any) => {
    let imageUrl = "";
    const data = Object.assign({}, item);

    // for now we are sending isFixedPrice = true, since we do not have price breakup for prasad.
    let priceInfo = calculatePrasadForOnboardingPrice(
      Number(item?.acctualPrice),
      true
    );
    data.priceInfo = priceInfo;
    delete data.id;
    delete data.imageFile;
    if (item?.imageFile) {
      imageUrl = await PrasadService.uploadImageForPrasad({
        uri: item?.imageFile,
        platform: "web",
      });
      data.image = imageUrl;
    }

    if (item?.id && item?.id?.trim().length > 0) {
      dispatch(
        updatePrasadDetailsForTemple(selectedTempleId, item.id, data)
      ).then(() => {
        dispatch(getPrasadForTemple(selectedTemple?.nameKey));
      });
    } else {
      dispatch(addPrasadDetailsForTemple(selectedTempleId, data)).then(() => {
        dispatch(getPrasadForTemple(selectedTemple?.nameKey));
      });
    }
  };

  const handleBenefitsSelect = (items: any) => {
    setTempleBenefitsList(items);
  };

  const handleBenefitsSubmit = () => {
    if (selectedTempleId && templeBenefitsList.length > 0) {
      dispatch(
        updatePrasadBenefitsForTemple(selectedTempleId, templeBenefitsList)
      );
      const benefitIds: any = [];
      templeBenefitsList?.forEach((el: any) => {
        benefitIds.push(el?.benefitId);
      });

      setSelectedBenefitsOptions((prev: any[]) => {
        const newOptions = [...prev, ...benefitIds];
        const uniqueOptions = Array.from(new Set(newOptions));
        return uniqueOptions;
      });
    }
  };

  const handleTempleWeightageSubmit = (weightage: any) => {
    if (selectedTempleId && selectedTemple && weightage) {
      dispatch(
        updateOrganization(
          selectedTempleId,
          "prasadWeightageForTemple",
          weightage
        )
      );
    }
  };

  const handleTempleOverViewSubmit = () => {
    if (selectedTempleId && selectedTemple && prasadOverViewForTemple) {
      dispatch(
        updateOrganization(
          selectedTempleId,
          "prasadOverview",
          prasadOverViewForTemple
        )
      );
    }
  };

  const handleDeletePrasadItem = (item: any) => {
    dispatch(deletePrasadItem(selectedTempleId, item.id)).then(() => {
      dispatch(resetPrasadList());
      dispatch(getPrasadForTemple(selectedTemple?.nameKey));
    });
  };
  return (
    <ManagePrasadView
      temples={allTempleList}
      setSelectedTemple={handleSetSelectedTemple}
      prasadList={prasadListLocal}
      savePrasadItem={handleSavePrasadItem}
      benefitsList={benefitsList}
      selectedBenefitsOptions={selectedBenefitsOptions}
      handleBenefitsSelect={handleBenefitsSelect}
      handleBenefitsSubmit={handleBenefitsSubmit}
      selectedTempleId={selectedTempleId}
      deletePrasadItem={handleDeletePrasadItem}
      selectedTemple={selectedTemple}
      handleTempleWeightageSubmit={handleTempleWeightageSubmit}
      handleTempleOverViewSubmit={handleTempleOverViewSubmit}
      prasadOverViewForTemple={prasadOverViewForTemple}
      setPrasadOverViewForTemple={setPrasadOverViewForTemple}
    />
  );
}

export default ManagePrasadScreen;
