import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import "./SpecialChadhavaItem.scss";
import { useTranslation } from "react-i18next";
import { LOCALIZATION } from "../../../utils/localization";
import { useSelector } from "react-redux";
import { formatNumberWithK } from "../../../utils/HelperFunctions";

export default function SpecialChadhavaItem(props: any) {
  const { chadhavaDetails, handleClickOnChadhavaAdd, handleClickOnMoreDetails } = props;
  const { t } = useTranslation();
   const priceConversionState = useSelector(
      (state: any) => state.priceConversion
    );
    const {
      currencySymbols = "₹",
      isOverseas = false,
    } = priceConversionState.priceConversionRate || {};
  return (
    <>
      <Card className="spChadhavaCardContainer">
        <CardContent className="spChadhavaCardContent">
          <Box className="leftSection">
            <Typography variant="h6" className="title">
              {chadhavaDetails?.name ?? ""}
            </Typography>
            <Typography className="price">
              {isOverseas
                ? `${currencySymbols} ${
                    chadhavaDetails?.priceInfo?.finalPriceOv ?? ""
                  }`
                : `₹ ${chadhavaDetails?.priceInfo?.finalPrice}`}
            </Typography>
            <Box className="devoteeBookedCount" mt={2}>
              <Typography variant="body2" className="numberText">
                <span>
                  {formatNumberWithK(
                    chadhavaDetails?.priceInfo?.basePrice ?? 0
                  )}
                  +
                </span>{" "}
                {t(LOCALIZATION.Booked)}
              </Typography>
            </Box>
            <Box className="moreInfoBtn">
              <Button
                variant="outlined"
                endIcon={<ChevronRightRoundedIcon />}
                onClick={(e: any) => {
                  e.preventDefault();
                  handleClickOnMoreDetails();
                }}
              >
                {t(LOCALIZATION.More_Details)}
              </Button>
            </Box>
          </Box>
          <Box className="rightSection">
            <img
              src={chadhavaDetails?.image}
              alt={chadhavaDetails?.name}
              className="chadhavaItemImg"
            />
            <Button
              variant="contained"
              className="addButton"
              onClick={(e: any) => {
                e.preventDefault();
                handleClickOnChadhavaAdd();
              }}
            >
              {t(LOCALIZATION?.ADD)}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}