import { setDarshanList, setLoading } from "../slices/LiveDarshanSlice";
import { BenefitService, CommonService } from "td_server";
import { setManageOnboardingBenefitsList } from "../slices/mangeOnboardingBenefitsSlice";

export const addOnboardingBenefitsItem =
  (formData: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await BenefitService.addBenefit(formData);
      const responseOnboardingBenefitsList: any =
        await BenefitService.getAllBenefitList();
      dispatch(setManageOnboardingBenefitsList(responseOnboardingBenefitsList));
      dispatch(setLoading(false));
      console.log("benefititem response", JSON.stringify(response));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject(e);
    }
  };

export const updateOnbordingBenefitsItem =
  (formData: any, id: string) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await BenefitService.editBenefit(id, formData);
      if (response?.success) {
        const responseOnboardingBenefitsList: any =
          await BenefitService.getAllBenefitList();
        dispatch(
          setManageOnboardingBenefitsList(responseOnboardingBenefitsList)
        );
        dispatch(setLoading(false));
        return Promise.resolve(response);
      } else {
        dispatch(setLoading(false));
        return Promise.reject(
          response?.message || "Failed to update document."
        );
      }
    } catch (e) {
      dispatch(setLoading(false));
      console.error("Error updating item:", e);
      return Promise.reject(e);
    }
  };

export const deleteOnboardingBenefitsItem =
  (id: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await BenefitService.deleteBenefit(id);
      if (response) {
        const responseOnboardingBenefitsList: any =
          await BenefitService.getAllBenefitList();
        dispatch(
          setManageOnboardingBenefitsList(responseOnboardingBenefitsList)
        );
      } else {
      }
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      console.error("Error deleting item:", e);
      dispatch(setLoading(false));
      return Promise.reject(e);
    }
  };

export const getAllManageOnboardingBenefitsList =
  () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const responseSetManageOnboardingBenefitsList: any =
        await BenefitService.getAllBenefitList();
      dispatch(
        setManageOnboardingBenefitsList(responseSetManageOnboardingBenefitsList)
      );
      dispatch(setLoading(false));
      return Promise.resolve(responseSetManageOnboardingBenefitsList);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };
