import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Button,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import "./ManageSpecialChadhavaView.scss";
import DeleteConfirmDialog from "../../../components/dialog/DeleteConfirmDialog";
import { adminSpecielPujaViewLabel } from "../../../utils/ConstantsMessages";
import SpecialChadhavaForm from "./SpecialChadhavaForm";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import SearchFilter from "../../../components/SearchFilter/SearchFilter";

interface ManageSpecialChadhavaViewProps {
  isAddEditSpecialChadhavaModalOpen: boolean;
  setIsAddEditSpecialChadhavaModalOpen: (modalState: boolean) => void;
  promotionalList: any;
  templeList: any;
  handleTempleSelect: ([]: any) => any;
  allChadhavaList: any;
  handleChadhavaSelect: (chadhavaList: any) => any;
  seletedTempleOptions: any;
  seletedChadhavaOptions: any;
  setSelectedSpecialChadhava: (selectedChadhava: any) => any;
  selectedSpecialChadhava: any;
  setSeletedTempleOptions: ([]: any) => any;
  setSeletedChadhavaOptions: ([]: any) => any;
  handleEditSpecialChadhavaItem: (item: any) => any;
  handleSubmitSpecialChadhava: (formData: any) => Promise<any>;
  handleCloseAddEditSpecialChadhavaForm: () => any;
  handleDeleteSpecialChadhava: (specialChadhava: any) => any;
  handleSearchSpChadhavalist: any;
}

const ManageSpecialChadhavaView: React.FC<ManageSpecialChadhavaViewProps> = (props: any) => {
  const {
    isAddEditSpecialChadhavaModalOpen,
    setIsAddEditSpecialChadhavaModalOpen,
    promotionalList,
    templeList,
    handleTempleSelect,
    allChadhavaList,
    handleChadhavaSelect,
    seletedTempleOptions,
    seletedChadhavaOptions,
    selectedSpecialChadhava,
    setSeletedTempleOptions,
    setSeletedChadhavaOptions,
    handleEditSpecialChadhavaItem,
    handleSubmitSpecialChadhava,
    handleCloseAddEditSpecialChadhavaForm,
    handleDeleteSpecialChadhava,
    handleSearchSpChadhavalist,
  } = props;

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const handleDeleteClick = (item: any) => {
    setSelectedItem(item);
    setIsDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setSelectedItem(null);
  };

  const handleConfirmDelete = () => {
    if (selectedItem) {
      handleDeleteSpecialChadhava(selectedItem);
      setSelectedItem(null);
    }
    handleCloseDeleteDialog();
  };

  const handleOpenAddEditSpecialChadhavaForm = () => {
    setIsAddEditSpecialChadhavaModalOpen(true);
  };

  return (
    <Box className="pageContainer specialChadhavaAdmin">
      <Box className="topTitle">
        <Typography className="adminTitleText">
          {/* {adminSpecielPujaViewLabel.title} */}
          Manage Special Chadhava
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={2}
        mb={1}
      >
        <Box>
          <SearchFilter handleSearchSpPujalist={handleSearchSpChadhavalist} />
        </Box>
        <Box className="btnAddSpecialChadhava">
          <Button
            onClick={handleOpenAddEditSpecialChadhavaForm}
            variant="contained"
            aria-label="Add special chadhava"
          >
            <AddCircleOutlineIcon sx={{ fontSize: "20px", mr: 1 }} />
            {adminSpecielPujaViewLabel.button}
          </Button>
        </Box>
      </Box>

      {promotionalList && promotionalList?.length > 0 ? (
        <List className="specialChadhavaItemListAdmin">
          {promotionalList.map((_chadhavaItem: any, index: number) => (
            <ListItem
              key={_chadhavaItem?.id}
              className="specialChadhavaAdminCard"
              sx={{
                backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#d569391a",
              }}
            >
              <ListItemAvatar>
                <Avatar
                  src={_chadhavaItem?.dashBoardBg?.uri}
                  sx={{
                    width: 80,
                    height: 80,
                    marginRight: 2,
                    borderRadius: "10px",
                    border: "1px solid #cdcdcd",
                  }}
                />
              </ListItemAvatar>
              <ListItemText
                className="listText"
                primary={_chadhavaItem?.specialChadhavaName}
              />
              <IconButton aria-label="delete" onClick={() => {}}>
                <Box
                  sx={{
                    color: "#fff",
                    padding: "2px 18px",
                    borderRadius: "4px",
                    fontSize: "14px",

                    backgroundColor: `${
                      _chadhavaItem?.hasOwnProperty("version")
                        ? "green"
                        : "rgb(209, 68, 68)"
                    }`,
                    fontFamily: "Roboto",
                  }}
                >
                  <span>
                    {_chadhavaItem?.hasOwnProperty("version") ? "Web" : "Mobile"}
                  </span>
                </Box>
              </IconButton>
              {_chadhavaItem?.hasOwnProperty("version") && (
                <IconButton
                  className="spChadhavaEditBtn"
                  aria-label="edit"
                  onClick={(e) => {
                    e.preventDefault();
                    handleEditSpecialChadhavaItem(_chadhavaItem);
                  }}
                >
                  <EditIcon sx={{ fontSize: "18px" }} />
                </IconButton>
              )}
              <IconButton
                className="spChadhavaDeleteBtn"
                aria-label="delete"
                onClick={() => handleDeleteClick(_chadhavaItem)}
              >
                <DeleteIcon sx={{ fontSize: "18px" }} />
              </IconButton>
            </ListItem>
          ))}
        </List>
      ) : (
        <NoDataFound />
      )}

      <SpecialChadhavaForm
        isOpen={isAddEditSpecialChadhavaModalOpen}
        onClose={handleCloseAddEditSpecialChadhavaForm}
        onSave={handleSubmitSpecialChadhava}
        templeList={templeList}
        handleTempleSelect={handleTempleSelect}
        allChadhavaList={allChadhavaList}
        handleChadhavaSelect={handleChadhavaSelect}
        seletedTempleOptions={seletedTempleOptions}
        seletedChadhavaOptions={seletedChadhavaOptions}
        selectedSpecialChadhava={selectedSpecialChadhava}
        setSeletedTempleOptions={setSeletedTempleOptions}
        setSeletedChadhavaOptions={setSeletedChadhavaOptions}
      />
      <DeleteConfirmDialog
        open={isDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleConfirmDelete}
        msg="Are you sure you want to delete this item?"
      />
    </Box>
  );
};
export default ManageSpecialChadhavaView;
