import React, { useState } from 'react'
import "./AIGurujiValmikiImg.scss";
import { Box, Button, Grid, IconButton, Modal, Typography } from '@mui/material';
import valmikiImg from "../../../../assets/valmiki.png";
import omImage from "../../../../assets/hinduism.gif";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import CloseIcon from "@mui/icons-material/Close";

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

const AIGurujiValmikiImg = () => {
  const [demoVideoOpen, setDemoVideoOpen] = useState(false);

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={2} columnSpacing={3} alignItems="center">
          <Grid item xs={12} md={6} className="gridLeft">
            <Stack spacing={2}>
              <Box component="img" src={valmikiImg} className="valmikiImg" />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6} className="gridRight">
            <Stack spacing={2}>
              <Box className="valmikiBannerText">
                <Box className="betaText">Beta.</Box>
                <Box className="valmikiTitle">Valmiki</Box>
                <Box className="valmikiSubTitle">Your own AI Guruji</Box>
                <Box className="animatedCircle">
                  {/* <Box className="rotating-image-container">
                    <Box
                      component="img"
                      src={omImage}
                      className="animated-image"
                    />
                  </Box> */}
                  <Button
                    variant="contained"
                    endIcon={<KeyboardDoubleArrowRightIcon />}
                    className="demoBtn"
                    onClick={() => setDemoVideoOpen(true)}
                  >
                    View Demo
                  </Button>

                  <Modal open={demoVideoOpen} onClose={() => setDemoVideoOpen(false)}>
                    <Box className="videoModal" >
                      <IconButton
                        className="demoVideoCloseBtn"
                        onClick={() => setDemoVideoOpen(false)}
                      >
                        <CloseIcon />
                      </IconButton>
                      
                      <iframe src="https://www.youtube.com/embed/9DEBie7yHVI?rel=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                    </Box>
                  </Modal>
                </Box>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default AIGurujiValmikiImg
