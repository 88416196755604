import { createSlice } from "@reduxjs/toolkit";

const manageNewOnTempleDekhoSlice = createSlice({
  name: "manageNewOnTD",
  initialState: {
    isLoading: false,
    error: "",
    manageNewOnTDList: [],
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setManageNewOnTDList: (state, action) => {
      state.manageNewOnTDList = action.payload;
    },
  },
});

export const { setLoading, setError, setManageNewOnTDList } =
  manageNewOnTempleDekhoSlice.actions;

export default manageNewOnTempleDekhoSlice.reducer;
