import React, { useState, useEffect } from "react";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { useLoadScript } from "@react-google-maps/api";
import { googleMapKey } from "../../configs/firebaseConfig";
import LocationOnIcon from "@mui/icons-material/LocationOn";
const libraries: "places"[] = ["places"];

const TempleAutocomplete: React.FC<any> = ({
  onChangeHandle,
  variant = "outlined",
  label = "Name of Temple",
  placeholder = "Start typing temple name...",
  cityValue,
  placeId = "",
  templeNameError=""
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapKey,
    libraries,
  });

  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<any[]>([]);

  
  // useEffect(() => {
  //   if (inputValue === "") {
  //     setOptions([]);
  //     return;
  //   }

  //   const service = new window.google.maps.places.AutocompleteService();
  //   const request = {
  //     input: inputValue,
  //     types: ["hindu_temple"],
  //     componentRestrictions: { country: 'in' },
  //   };

  //   service.getPlacePredictions(request, (predictions: any, status) => {
  //     if (status === window.google.maps.places.PlacesServiceStatus.OK) {
  //       setOptions(
  //         predictions.map((prediction: any) => ({
  //           templeName: prediction?.structured_formatting?.main_text,
  //           address: prediction?.structured_formatting?.secondary_text,
  //           place_id: prediction.place_id,
  //         }))
  //       );
  //     }
  //   });
  // }, [inputValue]);

  useEffect(() => {
    if (inputValue === "") {
      setOptions([]);
      return;
    }
  
    const service = new window.google.maps.places.AutocompleteService();
  
    const fetchPredictions = (country: string) => {
      return new Promise((resolve) => {
        const request = {
          input: inputValue,
          types: ["hindu_temple"],
          componentRestrictions: { country },
        };
  
        service.getPlacePredictions(request, (predictions: any, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            resolve(
              predictions.map((prediction: any) => ({
                templeName: prediction?.structured_formatting?.main_text,
                address: prediction?.structured_formatting?.secondary_text,
                place_id: prediction.place_id,
              }))
            );
          } else {
            resolve([]);
          }
        });
      });
    };
  
    Promise.all([fetchPredictions("in"), fetchPredictions("np")]).then(
      (results:any) => {
        setOptions([...results[0], ...results[1]]);
      }
    );
  }, [inputValue]);
  

  const handlePlaceSelect = (placeId: string) => {
    const service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
    const request = {
      placeId,
    };
    

    service.getDetails(request, (place: any, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        let tempPlace = JSON.parse(JSON.stringify(place))
        const lat = place?.geometry?.location?.lat();
        const lng = place?.geometry?.location?.lng();
        tempPlace.geometry.location.lat = lat;
        tempPlace.geometry.location.lng = lng;
        onChangeHandle(tempPlace)
      }
    });
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => {
        return option.templeName
      }}
      filterOptions={(x) => x}
      defaultValue={cityValue || null}
      isOptionEqualToValue={(option, value) => option.place_id === value.place_id}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, value) => {
        if (value) {
          handlePlaceSelect(value.place_id);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label || undefined}
          variant={variant}
          placeholder={placeholder}
          error={!!templeNameError}
          helperText={templeNameError}
          FormHelperTextProps={{
            sx: { marginLeft: "3px" },
          }}
        />
      )}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <LocationOnIcon style={{ marginRight: 8 }} />
          <Box sx={{display: "flex", flexDirection:"column"}}>
            <Typography variant="body2">{option?.templeName}</Typography>
            <Typography variant="body2">{option?.address}</Typography>
          </Box>
        </Box>
      )}
    />
  );
};

export default TempleAutocomplete;
