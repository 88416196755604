export const LOCALIZATION = {
  Mobile_Number: "Mobile_Number",
  Phone_number: "Phone_number",
  Continue_with_Phone_Number: "Continue_with_Phone_Number",
  Continue_as_a_guest: "Continue_as_a_guest",
  Location_is_not_enable: "Location_is_not_enable",
  Address_details_added_successfully: "Address_details_added_successfully",
  Unable_to_add_address_details: "Unable_to_add_address_details",
  Pujas: "Pujas",
  Temples: "Temples",
  Is_Primary_Address: "Is_Primary_Address",
  Save_and_Continue: "Save_and_Continue",
  Phone_Number: "Phone_Number",
  Save_As: "Save_As",
  Zip: "Zip",
  Zip_code: "Zip_code",
  State: "State",
  City: "City",
  Street: "Street",
  Darshan: "Darshan",
  State_province_area: "State_province_area",
  House_no_Block_no: "House_no_Block_no",
  A_detailed_address_will_help_us_to_locate_you:
    "A_detailed_address_will_help_us_to_locate_you",
  Please_enter_your_name_as_per_pan_card:
    "Please_enter_your_name_as_per_pan_card",
  Please_enter_donation_amount: "Please_enter_donation_amount",
  Please_enter_valid_donation_amount: "Please_enter_valid_donation_amount",
  Please_enter_pan_card_number: "Please_enter_pan_card_number",
  Please_enter_valid_pan_card_number: "Please_enter_valid_pan_card_number",
  Something_went_wrong_Please_try_again:
    "Something_went_wrong_Please_try_again",
  Payment_Details: "Payment_Details",
  Full_Name: "Full_Name",
  Fund_Category: "Fund_Category",
  Amount: "Amount",
  Minimum_amount_should_be: "Minimum_amount_should_be",
  PAN: "PAN",
  FILTER: "FILTER",
  Unable_to_register_user: "Unable to register user. Please try again",
  Unable_to_choose_picture: "Unable to choose picture. Please try again.",
  Email_Optional: "Email_Optional",
  Mobile: "Mobile",
  City_Area_Optional: "City_Area_Optional",
  PROCEED: "PROCEED",
  Select_Location: "Select_Location",
  CHANGE: "CHANGE",
  Confirm_Location: "Confirm_Location",
  Enter_your_area_apartment_name: "Enter_your_area_apartment_name",
  Search_for_area: "Search_for_area",
  Use_current_location: "Use_current_location",
  Donation_Toward_Temple: "Donation_Toward_Temple",
  Live_Temple_List: "Live_Temple_List",
  Add_Live_Temple: "Add_Live_Temple",
  Google_Place_Id: "Google_Place_Id",
  Temple_not_accepting_puja_booking: "Temple_not_accepting_puja_booking",
  Newly_added: "Newly_added",
  Tithi: "Tithi",
  Sunrise: "Sunrise",
  Sunset: "Sunset",
  Dakshineswar_Kali_Temple: "Dakshineswar_Kali_Temple",
  Back_to_top: "Back_to_top",
  Dashboard: "Dashboard",
  Share_your_thoughts: "Share your thoughts...",
  Sort_By: "Sort_By",
  APPLY: "APPLY",
  Sort_By_Order_Status: "Sort_By_Order_Status",
  Sort_By_Booking_Date: "Sort_By_Booking_Date",
  Filter_Data_By: "Filter_Data_By",
  Order_Status: "Order_Status",
  Puja_Date: "Puja_Date",
  Reset_All_Filters: "Reset_All_Filters",
  Puja_starts_from: "Puja_starts_from",
  Book_Puja: "Book_Puja",
  You_and: "You_and",
  others: "others",
  Account_Deletion: "Account_Deletion",
  Please_write_reason_or_feedback: "Please_write_reason_or_feedback",
  Please_write_your_feedback: "Please_write_your_feedback",
  Your_deletion_request_will_be_process:
    "Your_deletion_request_will_be_process",
  Request_Account_Deletion: "Request_Account_Deletion",
  Send_Feedback: "Send_Feedback",
  Continue: "Continue",
  Pick_your: "Pick_your",
  Country_Code: "Country_Code",
  Search_here_for_your_country: "Search_here_for_your_country",
  No_countries_found: "No_countries_found",
  No_address_added: "No_address_added",
  ADD_NEW_ADDRESS: "ADD_NEW_ADDRESS",
  Enable_Push_Notifications: "Enable_Push_Notifications",
  App_Version: "App_Version",
  Work_In_Progress: "Work_In_Progress",
  UPCOMING_PUJAS: "UPCOMING_PUJAS",
  Spiritual_Information: "Spiritual_Information",
  EXPLORE_PUJA_CATEGORIES: "EXPLORE_PUJA_CATEGORIES",
  MOST_POPULAR_TEMPLES: "MOST_POPULAR_TEMPLES",
  TEMPLE_OFFERINGS: "TEMPLE_OFFERINGS",
  Upload: "Upload",
  Search_Temple: "Search_Temple",
  Name_of_Temple: "Name_of_Temple",
  Create_a: "Create_a",
  New_Account: "New_Account",
  Spiritual_Experience_with: "Spiritual_Experience_with",
  Temple_Dekho: "Temple_Dekho",
  Proceed: "Proceed",
  Youtube_Embed_Code: "Youtube_Embed_Code",
  Temple_City: "Temple_City",
  Temple_State: "Temple_State",
  Background_Image: "Background_Image",
  Cancel: "Cancel",
  SAVE: "SAVE",
  Del: "Del",
  Edit: "Edit",
  Whats_In_Your_Mind: "Whats_In_Your_Mind",
  LOGIN_SIGNUP_WITH_Your_Mobile_Number: "LOGIN_SIGNUP_WITH_Your_Mobile_Number",
  Unable_to_take_picture: "Unable_to_take_picture",
  Create_Post: "Create_Post",
  Next: "Next",
  Puja_Request: "Puja_Request",
  Your_Temples: "Your_Temples",
  Your_Earning: "Your_Earning",
  Verify_your_number: "Verify_your_number",
  Code_is_sent_to: "Code_is_sent_to",
  Resend_OTP: "Resend_OTP",
  Confirm: "Confirm",
  Sign_Up: "Sign_Up",
  Address: "Address",
  First_Name: "First_Name",
  Last_Name: "Last_Name",
  Email: "Email",
  Date_of_Birth: "Date_of_Birth",
  Male: "Male",
  Female: "Female",
  Other: "Other",
  Verification: "Verification",
  ID_Card_Type: "ID_Card_Type",
  ID_Card_Number: "ID_Card_Number",
  Front_image_selected: "Front_image_selected",
  ID_card_Front_Side: "ID_card_Front_Side",
  only_jpeg_jpg_and_png_files_allowed: "only_jpeg_jpg_and_png_files_allowed",
  Back_image_selected: "Back_image_selected",
  ID_card_Back_Side: "ID_card_Back_Side",
  Submit: "Submit",
  Update_Order_Status: "Update_Order_Status",
  Modification_Type: "Modification_Type",
  Puja_Status: "Puja_Status",
  Delivery_Status: "Delivery_Status",
  Puja_Video: "Puja_Video",
  AWB_Number: "AWB_Number",
  Agent_Phone_Number: "Agent_Phone_Number",
  Remarks: "Remarks",
  Temple: "Temple",
  Donation_Amount: "Donation_Amount",
  Pan_Number: "Pan_Number",
  Order_Time: "Order_Time",
  My_Order: "My_Order",
  puja_temple_name_status_order_id: "puja_temple_name_status_order_id",
  Order_id: "Order_id",
  Booked_on: "Booked_on",
  Booked_for: "Booked_for",
  Total: "Total",
  Booked_from: "Booked_from",
  VIEW_DETAILS: "VIEW_DETAILS",
  RATE_EXPERENCE: "RATE_EXPERENCE",
  You_rated: "You_rated",
  Name: "Name",
  Gotram: "Gotram",
  Manokamna_Wish: "Manokamna_Wish",
  Optional: "Optional",
  Puja_Members_Details: "Puja_Members_Details",
  Delivery_address: "Delivery_address",
  Temple_Name: "Temple_Name",
  Puja_Name: "Puja_Name",
  Contact: "Contact",
  Partner_Total_Payment: "Partner_Total_Payment",
  Base_Price_Of_Puja: "Base_Price_Of_Puja",
  Temple_Admin_Commision_Of_Puja: "Temple_Admin_Commision_Of_Puja",
  Total_Temple_Payment: "Total_Temple_Payment",
  Change_Status: "Change_Status",
  Puja_Journey: "Puja_Journey",
  No_notifications_to_show: "No_notifications_to_show",
  You_dont_have_any_new_notifications: "You_dont_have_any_new_notifications",
  Panchang: "Panchang",
  Auspicious: "Auspicious",
  Inuspicious: "Inuspicious",
  Nakshatra: "Nakshatra",
  Yoga: "Yoga",
  Karana: "Karana",
  Add_New: "Add_New",
  PhonePe: "PhonePe",
  Save: "Save",
  Card: "Card",
  UPI: "UPI",
  Add_New_UPI_ID: "Add_New_UPI_ID",
  ADD: "ADD",
  Cards: "Cards",
  Add_New_Card: "Add_New_Card",
  Continue_To_Pay: "Continue_To_Pay",
  Request_placed_successfully: "Request_placed_successfully",
  Our_team_will_connect_with_you_within_hours:
    "Our_team_will_connect_with_you_within_hours",
  ONBOARDED: "ONBOARDED",
  Overview: "Overview",
  EDIT: "EDIT",
  VISIT: "VISIT",
  Visiting_Hours: "Visiting_Hours",
  Getting_There: "Getting_There",
  Show_less: "Show_less",
  Show_more: "Show_more",
  Search_by_temple_name: "Search_by_temple_name",
  No_temples_found_to_display: "No_temples_found_to_display",
  Open_Camera: "Open_Camera",
  Open_Gallery: "Open_Gallery",
  Personal_Information: "Personal_Information",
  Edit_Profile: "Edit_Profile",
  Update_Profile: "Update_Profile",
  Update_Cover: "Update_Cover",
  Home: "Home",
  Name_Photo_Email_Contact_number_Birthday:
    "Name_Photo_Email_Contact_number_Birthday",
  My_Addresse: "My_Addresse",
  Add_Edit_Delete_address: "Add_Edit_Delete_address",
  Help: "Help",
  Frequently_asked_questions: "Frequently_asked_questions",
  Account_Settings: "Account_Settings",
  Delete_your_account: "Delete_your_account",
  Add_Edit_Promotional_Puja_Dashboard_UI:
    "Add_Edit_Promotional_Puja_Dashboard_UI",
  Apply: "Apply",
  Apply_Coupon: "Apply_Coupon",
  Code: "Code",
  Existing_Coupons: "Existing_Coupons",
  Choose_a_delivery_address: "Choose_a_delivery_address",
  No_Address_added_yet: "No_Address_added_yet",
  Your_cart_is_empty: "Your_cart_is_empty",
  Explore_our_Puja_and_Chadhava_offerings_and_other_features:
    "Explore_our_Puja_and_Chadhava_offerings_and_other_features",
  Explore_pujas_Chadhava: "Explore_pujas_Chadhava",
  Devotee: "Devotee",
  Previous_Booking_Devotee_List: "Previous_Booking_Devotee_List",
  Select_Continue: "Select_Continue",
  Shri_Siddhivinayak_Ganapati_Temple: "Shri_Siddhivinayak_Ganapati_Temple",
  devotees_booked_puja_from_this_temple:
    "devotees_booked_puja_from_this_temple",
  Please_enter_coupon_code: "Please_enter_coupon_code",
  Review_Puja_Booking_Details: "Review_Puja_Booking_Details",
  Unable_to_send_OTP_please_try_after_one_minute:
    "Unable_to_send_OTP_please_try_after_one_minute",
  Test_Page: "Test_Page",
  Vishesh_Rudrabhishek_Family: "Vishesh_Rudrabhishek_Family",
  Puja_Performing_Date: "Puja_Performing_Date",
  Shree_Mahakaleshwar_Jyotirlinga: "Shree_Mahakaleshwar_Jyotirlinga",
  Ujjain_Madhya_Pradesh: "Ujjain_Madhya_Pradesh",
  You_are_not_logged_in: "You_are_not_logged_in",
  Devotee_Added: "Devotee_Added",
  Members: "Members",
  Edit_Details: "Edit_Details",
  No_Puja_added: "No_Puja_added",
  Coupon_Code: "Coupon_Code",
  Bill_Details: "Bill_Details",
  Puja_Value: "Puja_Value",
  GST: "GST",
  Coupon_Code_Applied: "Coupon_Code_Applied",
  Final_Payment: "Final_Payment",
  User_Details: "User_Details",
  I_want_to_receive_transaction: "I_want_to_receive_transaction",
  Orders_can_be_cancelled: "Orders_can_be_cancelled",
  Cancellation_Policy: "Cancellation_Policy",
  To_book_a_puja_please_add_at_least: "To_book_a_puja_please_add_at_least",
  Add_Devotee_Details: "Add_Devotee_Details",
  Provide_Your_WhatsApp_Number: "Provide_Your_WhatsApp_Number",
  Full_Name_of_First_Devotee: "Full_Name_of_First_Devotee",
  Gotram_Optional: "Gotram_Optional",
  Dont_know_my_Gotram: "Dont_know_my_Gotram",
  Manokamna_Wish_Optional: "Manokamna_Wish_Optional",
  Enter_here: "Enter_here",
  ADD_OPTIONAL_DETAILS: "ADD_OPTIONAL_DETAILS",
  Save_and_continue: "Save_and_continue",
  No_members_added_yet: "No_members_added_yet",
  Add_Edit_Members: "Add_Edit_Members",
  Select_Date: "Select_Date",
  Temple_Dekho_stands_out: "Temple_Dekho_stands_out",
  SOC_2_type_2_compliant: "SOC_2_type_2_compliant",
  and: "and",
  ISO_27001_certified: "ISO_27001_certified",
  ensuring_the_highest_standards: "ensuring_the_highest_standards",
  Explore_Temple_Dekho_App: "Explore_Temple_Dekho_App",
  Click_the_below_link: "Click_the_below_link",
  Share_Puja_Steps_Video: "Share_Puja_Steps_Video",
  Temple_Dekho_Video: "Temple_Dekho_Video",
  Product_Video: "Product_Video",
  Temple_Dekho_leads_in_virtual_puja_services:
    "Temple_Dekho_leads_in_virtual_puja_services",
  Temple_Dekho_is_Indias_leading_most_reliable:
    "Temple_Dekho_is_Indias_leading_most_reliable",
  Recognised_and_Certified_by: "Recognised_and_Certified_by",
  Seamless_Online_Puja_Booking_and_Chadhava:
    "Seamless_Online_Puja_Booking_and_Chadhava",
  app: "app",
  Skilled_pandits_conduct_Pujas_offer: "Skilled_pandits_conduct_Pujas_offer",
  Name_Gotram: "Name_Gotram",
  Receive_notifications_directly_to_your:
    "Receive_notifications_directly_to_your",
  WhatsApp_number: "WhatsApp_number",
  Login_OR_Sign_up: "Login_OR_Sign_up",
  Devotee_Details: "Devotee_Details",
  COUPON: "COUPON",
  devotees_booked_this_puja: "devotees_booked_this_puja",
  Puja_Overview: "Puja_Overview",
  About_Temple_Dekho: "About_Temple_Dekho",
  Please_watch_our_feature_video: "Please_watch_our_feature_video",
  See_VIDEO: "See_VIDEO",
  Select_Puja_Package: "Select_Puja_Package",
  Benefits_of_Puja: "Benefits_of_Puja",
  Puja_Process_Steps: "Puja_Process_Steps",
  Select_and_add_puja: "Select_and_add_puja",
  Select_date: "Select_date",
  Enter_Name_Gotra: "Enter_Name_Gotra",
  Make_payment: "Make_payment",
  Skilled_pandits_conduct_Puja_notifications:
    "Skilled_pandits_conduct_Puja_notifications",
  Prasad_will_be_delivered_within: "Prasad_will_be_delivered_within",
  Puja_Contribution_Details: "Puja_Contribution_Details",
  Your_gracious_contribution_for_this_sacred_puja:
    "Your_gracious_contribution_for_this_sacred_puja",
  Frequently_Asked_Questions: "Frequently_Asked_Questions",
  What_is_Temple_Dekho_FAQ: "What_is_Temple_Dekho_FAQ",
  What_is_Temple_Dekho_FAQ_Answer: "What_is_Temple_Dekho_FAQ_Answer",
  What_makes_your_virtual_puja_services_FAQ:
    "What_makes_your_virtual_puja_services_FAQ",
  What_makes_your_virtual_puja_services_FAQ_Answer:
    "What_makes_your_virtual_puja_services_FAQ_Answer",
  How_do_you_prioritize_the_security_FAQ:
    "How_do_you_prioritize_the_security_FAQ",
  How_do_you_prioritize_the_security_FAQ_Answer:
    "How_do_you_prioritize_the_security_FAQ_Answer",
  Who_is_designated_to_perform_my_Puja_FAQ:
    "Who_is_designated_to_perform_my_Puja_FAQ",
  Who_is_designated_to_perform_my_Puja_FAQ_Answer:
    "Who_is_designated_to_perform_my_Puja_FAQ_Answer",
  Can_I_participate_in_a_Puja_if_I_don_know_my_Gotram_FAQ:
    "Can_I_participate_in_a_Puja_if_I_don_know_my_Gotram_FAQ",
  How_do_I_get_evidence_of_my_Puja_FAQ: "How_do_I_get_evidence_of_my_Puja_FAQ",
  How_do_I_get_evidence_of_my_Puja_FAQ_Answer:
    "How_do_I_get_evidence_of_my_Puja_FAQ_Answer",
  Who_should_I_reach_out_to_for_more_details_FAQ:
    "Who_should_I_reach_out_to_for_more_details_FAQ",
  Who_should_I_reach_out_to_for_more_details_FAQ_Answer:
    "Who_should_I_reach_out_to_for_more_details_FAQ_Answer",
  How_do_I_receive_the_prasad: "How_do_I_receive_the_prasad",
  How_do_I_receive_the_prasad_FAQ: "How_do_I_receive_the_prasad_FAQ",
  How_do_I_receive_the_prasad_FAQ_Answer:
    "How_do_I_receive_the_prasad_FAQ_Answer",
  ADD_MEMBER: "ADD_MEMBER",
  member_remaining_to_add_optional: "member_remaining_to_add_optional",
  This_is_awesome: "This_is_awesome",
  Upto: "Upto",
  Devotees: "Devotees",
  PARTICIPATE_NOW: "PARTICIPATE_NOW",
  OFFERING_PUJAS: "OFFERING_PUJAS",
  Temple_currently_not_accepting_puja_booking:
    "Temple_currently_not_accepting_puja_booking",
  Add_Edit_Promotional_Puja: "Add_Edit_Promotional_Puja",
  Most_offered: "Most_offered",
  Devotee_saved: "Devotee_saved",
  with_coupon_code: "with_coupon_code",
  REMOVE: "REMOVE",
  ADD_CHADHAVA: "ADD_CHADHAVA",
  Temple_Flag: "Temple_Flag",
  Devotee_often_offer_peacock_feathers: "Devotee_often_offer_peacock_feathers",
  Choose_Language: "Choose_Language",
  Proceed_to_payment: "Proceed_to_payment",
  You_can_add_maximum: "You_can_add_maximum",
  Add_devotee_from_previous_bookings: "Add_devotee_from_previous_bookings",
  Most_Given: "Most_Given",
  Open_Settings: "Open_Settings",
  A_new_update_is_available: "A_new_update_is_available",
  Update_Now: "Update_Now",
  Ignore: "Ignore",
  Connecting_Souls_to_Spirituality: "Connecting_Souls_to_Spirituality",
  Recognised_by: "Recognised_by",
  Video_Picker: "Video_Picker",
  Post: "Post",
  Address_deleted_successfully: "Address_deleted_successfully",
  Unable_to_delete_address_Pleasse_try_again:
    "Unable_to_delete_address_Pleasse_try_again",
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",
  Sunday: "Sunday",
  Could_not_add_temple_details_Please_try_again:
    "Could_not_add_temple_details_Please_try_again",
  Cart: "Cart",
  Thank_You_Your_Donation_is_Completed: "Thank_You_Your_Donation_is_Completed,",
  Thank_You_Your_Puja_Booking_Is_Confirmed:
    "Thank_You_Your_Puja_Booking_Is_Confirmed",
  Puja_not_available_at_the_moment: "Puja_not_available_at_the_moment",
  Account_Deletion_Request_Of_Temple_Dekho:
    "Account_Deletion_Request_Of_Temple_Dekho",
  Feedback_About_Temple_Dekho_Application:
    "Feedback_About_Temple_Dekho_Application",
  Auto_Verified: "Auto_Verified",
  OTP_sent_successfully: "OTP_sent_successfully",
  Coupon_code_usage_limit_has_been_reached:
    "Coupon_code_usage_limit_has_been_reached",
  Please_select_payment_profile: "Please_select_payment_profile",
  Unable_to_register_user_Please_try_again:
    "Unable_to_register_user_Please_try_again",
  Unable_to_take_picture_Please_try_again:
    "Unable_to_take_picture_Please_try_again",
  Unable_to_choose_picture_Please_try_again:
    "Unable_to_choose_picture_Please_try_again",
  Profile_information_updated_successfully:
    "Profile_information_updated_successfully",
  Unable_to_update_profile_information_Please_try_again:
    "Unable_to_update_profile_information_Please_try_again",
  Maximum_number_allowed: "Maximum_number_allowed",
  Could_not_save_member_Please_try_again:
    "Could_not_save_member_Please_try_again",
  Could_not_add_member_Please_try_again:
    "Could_not_add_member_Please_try_again",
  Could_not_delete_member_Please_try_again:
    "Could_not_delete_member_Please_try_again",
  A_puja_is_already_added_in_your_cart: "A_puja_is_already_added_in_your_cart",
  Please_select_a_day_for_puja: "Please_select_a_day_for_puja",
  Location_is_not_enable_in_your_phone: "Location_is_not_enable_in_your_phone",
  We_need_access_to_your_location: "We_need_access_to_your_location",
  Try_to_switch_to_a_different_network: "Try_to_switch_to_a_different_network",
  We_are_working_on_it: "We_are_working_on_it",
  Order_Status_updated_successfully: "Order_Status_updated_successfully",
  Panchang_calendar: "Panchang_calendar",
  You_can_access_panchang_for_various_Tithis:
    "You_can_access_panchang_for_various_Tithis",
  EDIT_DEVOTEE: "EDIT_DEVOTEE",
  Full_Name_of_Devotee: "Full_Name_of_Devotee",
  Gotram_Kashyap_Gotram_if_not_known: "Gotram_Kashyap_Gotram_if_not_known",
  Chadhawa_Items: "Chadhawa_Items",
  Chadhawa_Add_ons: "Chadhawa_Add_ons",
  Offer_Dakshina_to_Pandit_ji: "Offer_Dakshina_to_Pandit_ji",
  Your_dakshina_will_be_given_to_the_pandit_ji:
    "Your_dakshina_will_be_given_to_the_pandit_ji",
  Add_this_Dakshina_amount_in_all_my_future_offerings:
    "Add_this_Dakshina_amount_in_all_my_future_offerings",
  Chadhava_Value: "Chadhava_Value",
  Pandit_Dakshina: "Pandit_Dakshina",
  Chadhava_Item_Description: "Chadhava_Item_Description",
  Receive_prasad_at: "Receive_prasad_at",
  your_doorstep_directly: "your_doorstep_directly",
  from_temples: "from_temples",
  Live_darshan: "Live_darshan",
  of_various_temples_of_India: "of_various_temples_of_India",
  This_is_how: "This_is_how",
  operates: "operates",
  Search: "Search",
  Live_Darshan: "Live_Darshan",
  // New
  Verify_Temple: "Verify_Temple",
  Add_Temple: "Add_Temple",
  Add_Saktipeeth: "Add_Saktipeeth",
  Verify_Shaktipeeth: "Verify_Shaktipeeth",
  Login_Or_SignUp: " Login_Or_SignUp",
  Feedback: "Feedback",
  PrivacyPolicy: "Privacy Policy",
  Settings: "Settings",
  Logout: "Logout",
  Puja: "Puja",
  Chadhava: "Chadhava",
  OFFER_ZONE: "OFFER_ZONE",
  Shubh_Muhurta: "Shubh_Muhurta",
  Rahukaal: "Rahukaal",
  PROCEED_TO_PAYMENT: "PROCEED_TO_PAYMENT",
  devotees_booked_Chadhava_from_this_temple:
    "devotees_booked_Chadhava_from_this_temple",
  Temple_Overview: "Temple_Overview",
  Benefits_of_Chadhava: "Benefits_of_Chadhava",
  Chadhava_Steps: "Chadhava_Steps",
  Book_Chadhava: "Book_Chadhava",
  Select_and_add_Chadhava_items: "Select_and_add_Chadhava_items",
  Provide_Name_and_Gotram_Gotram_is_optional:
    "Provide_Name_and_Gotram_Gotram_is_optional",
  Skilled_pandits_will_present_the_Chadhavas_and_recite:
    "Skilled_pandits_will_present_the_Chadhavas_and_recite",
  After_the_Sankalp_ceremony_Pandit_ji_will_proceed:
    "After_the_Sankalp_ceremony_Pandit_ji_will_proceed",
  Once_Chadhava_done_you_will_receive_video:
    "Once_Chadhava_done_you_will_receive_video",
  Why_should_I_choose_Temple_Dekho_for_temple_Chadhava:
    "Why_should_I_choose_Temple_Dekho_for_temple_Chadhava",
  Who_designated_to_offer_the_Chadhava: "Who_designated_to_offer_the_Chadhava",
  I_dont_know_my_Gotram_can_I_offer_Chadhava:
    "I_dont_know_my_Gotram_can_I_offer_Chadhava",
  Could_I_receive_any_proof_of_my_Chadhava:
    "Could_I_receive_any_proof_of_my_Chadhava",
  Who_should_I_reach_out_to_for_more_information:
    "Who_should_I_reach_out_to_for_more_information",
  Donations_made_towards_temples_are_eligible:
    "Donations_made_towards_temples_are_eligible",
  Add_To_Cart: "Add_To_Cart",
  Login_to_Your_Account: "Login_to_Your_Account",
  Login: "Login",
  Experience_divine_convenience: "Experience_divine_convenience",
  Elevate_your_spiritual_journey: "Elevate_your_spiritual_journey",
  Most_Popular: "Most_Popular",
  View_other_coupons: "View_other_coupons",
  View_all_coupons: "View_all_coupons",
  Coupon_Applied: "Coupon_Applied",
  To_book_a_puja_please_create_account: "To_book_a_puja_please_create_account",
  Price: "Price",
  Participants_Upto: "Participants_Upto",
  Can_I_participate_in_a_Puja_if_I_don_know_my_Gotram_FAQ_Answer:
    "Can_I_participate_in_a_Puja_if_I_don_know_my_Gotram_FAQ_Answer",
  Puja_Booking_Details: "Puja_Booking_Details",
  Puja_Booking_Date: "Puja_Booking_Date",
  Full_Name_of_x_number_Devotee: "Full_Name_of_x_number_Devotee",
  Gotram_Default_Kashyap: "Gotram_Default_Kashyap",
  Member_Details: "Member_Details",
  SOC2_CONTENT: "SOC2_CONTENT",
  UptoSingle: "UptoSingle",
  BOOK_YOUR_PUJA_NOW: "BOOK_YOUR_PUJA_NOW",
  Save_details_obtain_deep_link: "Save_details_obtain_deep_link",
  Open_24_Hours: "Open_24_Hours",
  Open: "Open",
  Close: "Close",
  BOOK_YOUR_PUJA: "BOOK_YOUR_PUJA",
  Save_As_Name: "Save_As_Name",
  Please_enter_full_name: "Please_enter_full_name",
  Please_enter_WhatsApp_number: "Please_enter_WhatsApp_number",
  Please_enter_valid_number: "Please_enter_valid_number",
  You_are_not_login_to_add_address: "You_are_not_login_to_add_address",
  Puja_Details: "Puja_Details",
  This_Puja_is_closed_now_for_bookings: "This_Puja_is_closed_now_for_bookings",
  A_puja_already_added_in_your_cart: "A_puja_already_added_in_your_cart",
  Could_not_update_order_status_Please_try_again:
    "Could_not_update_order_status_Please_try_again",
  // Navigation Route List
  InternetNotAvailableScreen: "InternetNotAvailableScreen",
  Chadhawa_Details: "Chadhawa_Details",
  Chadhawa_Checkout: "Chadhawa_Checkout",
  AddTemple: "AddTemple",
  VerifyTemple: "VerifyTemple",
  DashboardTab: "DashboardTab",
  Donation: "Donation",
  Checkout: "Checkout",
  Total_Payment: "Total_Payment",
  My_Addresses: "My_Addresses",
  Add_New_Address: "Add_New_Address",
  Notifications: "Notifications",
  Payment: "Payment",
  MyOrder: "MyOrder",
  AdminOrder: "AdminOrder",
  Track_Orders: "Track_Orders",
  Badrinath: "Badrinath",
  Saktipeeth: "Saktipeeth",
  App_Settings: "App_Settings",
  Update_Order_Details: "Update_Order_Details",
  Panchang_Details: "Panchang_Details",
  Add_Edit_Live_Darshan: "Add_Edit_Live_Darshan",
  Change_User_Type: "Change_User_Type",
  Vishesh_Puja: "Vishesh_Puja",
  Configure_Visesh_Puja: "Configure_Visesh_Puja",
  Back_to_Puja_Page: "Back_to_Puja_Page",
  Virtual_Puja_Booking: "Virtual_Puja_Booking",
  Profile: "Profile",
  My_Orders: "My_Orders",
  Addresses: "Addresses",
  Terms_and_Conditions: "Terms_and_Conditions",
  Horoscope: "Horoscope",
  Privacy_Policy: "Privacy_Policy",
  ChadhavaList: "ChadhavaList",
  Chadhava_Items: "Chadhava_Items",
  Please_select_date_for_Chadhava: "Please_select_date_for_Chadhava",
  Why_should_I_choose_Temple_Dekho_for_temple_ChadhavaAnswer:
    "Why_should_I_choose_Temple_Dekho_for_temple_ChadhavaAnswer",
  Who_designated_to_offer_the_ChadhavaAnswer:
    "Who_designated_to_offer_the_ChadhavaAnswer",
  What_is_Chadhava: "What_is_Chadhava",
  What_is_ChadhavaAnswer: "What_is_ChadhavaAnswer",
  What_are_the_benefits_of_Chadhava: "What_are_the_benefits_of_Chadhava",
  What_are_the_benefits_of_ChadhavaAnswer:
    "What_are_the_benefits_of_ChadhavaAnswer",
  I_dont_know_my_Gotram_can_I_offer_ChadhavaAnswer:
    "I_dont_know_my_Gotram_can_I_offer_ChadhavaAnswer",
  Could_I_receive_any_proof_of_my_ChadhavaAnswer:
    "Could_I_receive_any_proof_of_my_ChadhavaAnswer",
  Who_should_I_reach_out_to_for_more_informationAnswer:
    "Who_should_I_reach_out_to_for_more_informationAnswer",
  Can_I_choose_the_temple_and_types_of_chadhava:
    "Can_I_choose_the_temple_and_types_of_chadhava",
  Can_I_choose_the_temple_and_types_of_chadhavaAnswer:
    "Can_I_choose_the_temple_and_types_of_chadhavaAnswer",
  How_do_you_ensure_the_security_and_sanctity_of_my_offering:
    "How_do_you_ensure_the_security_and_sanctity_of_my_offering",
  How_do_you_ensure_the_security_and_sanctity_of_my_offeringAnswer:
    "How_do_you_ensure_the_security_and_sanctity_of_my_offeringAnswer",
  Blog: "Blog",
  Blogs: "Blogs",
  Share_the_app: "Share_the_app",
  Chadhava_Selection: "Chadhava_Selection",
  Review_Details_Checkout: "Review_Details_Checkout",
  Chadhava_Add_ons: "Chadhava_Add_ons",
  SAVE_DEVOTEE: "SAVE_DEVOTEE",
  Review_Chadhava_Booking_Details: "Review_Chadhava_Booking_Details",
  Chadhava_Date: "Chadhava_Date",
  Unlock_Free_Chat_with_Valmiki: "Unlock_Free_Chat_with_Valmiki",
  To_ensure_the_best_predictions: "To_ensure_the_best_predictions",
  validPhone: "validPhone",
  Bookings_are_not_permitted_in_your_country:
    "Bookings_are_not_permitted_in_your_country",
  OptedForPrasad: "OptedForPrasad",
  Would_you_like_to_receive_Prasad: "Would_you_like_to_receive_Prasad",
  Bookings_are_not_permitted_for_below: "Bookings_are_not_permitted_for_below",
  ONLINE: "ONLINE",
  AiGuruJi: "AiGuruJi",
  PrasadDelivery: "PrasadDelivery",
  PrasadCheckout: "PrasadCheckout",
  devotees_booked_Prasad: "devotees_booked_Prasad",
  What_is_the_Prasad_Delivery_Service: "What_is_the_Prasad_Delivery_Service",
  What_is_the_Prasad_Delivery_ServiceAnswer:
    "What_is_the_Prasad_Delivery_ServiceAnswer",
  Which_temples_are_available_for_Prasad_Delivery:
    "Which_temples_are_available_for_Prasad_Delivery",
  Which_temples_are_available_for_Prasad_DeliveryAnswer:
    "Which_temples_are_available_for_Prasad_DeliveryAnswer",
  What_items_are_included_the_Prasad_package:
    "What_items_are_included_the_Prasad_package",
  What_items_are_included_the_Prasad_packageAnswer:
    "What_items_are_included_the_Prasad_packageAnswer",
  Is_the_Prasad_authentic: "Is_the_Prasad_authentic",
  Is_the_Prasad_authenticAnswer: "Is_the_Prasad_authenticAnswer",
  When_will_I_receive_my_Prasad: "When_will_I_receive_my_Prasad",
  When_will_I_receive_my_PrasadAnswer: "When_will_I_receive_my_PrasadAnswer",
  Prasad: "Prasad",
  Prasad_Value: "Prasad_Value",
  Prasad_Delivery: "Prasad_Delivery",
  BOOK_PUJA: "BOOK_PUJA",
  BOOK_CHADHAVA: "BOOK_CHADHAVA",
  BOOK_PRASAD: "BOOK_PRASAD",
  CLICK_ON_PLUS_ICON_MESSAGE: "CLICK_ON_PLUS_ICON_MESSAGE",
  Oops: "Oops!",
  weCouldNotFound: "weCouldNotFound",
  pleaseSearch: "pleaseSearch",
  The_price_does_not_include_Prasad_delivery:
    "The_price_does_not_include_Prasad_delivery",
  Chadhava_Booking_Date: "Chadhava_Booking_Date",
  Temple_List: "Temple_List",
  SubCategory: "SubCategory",
  //gaurav
  Download_App: "Download_App",
  Join_Into_Our_Community: "Join_Into_Our_Community",
  Facebook: "Facebook",
  Chanel_for_Community: "Chanel_for_Community",
  Linkedin: "Linkedin",
  Instagram: "Instagram",
  Latest_News_Update: "Latest_News_&_Update",
  Youtube: "Youtube",
  Edit_or_add_an_address: "Edit_or_add_an_address",
  Search_for: "Search_for",
  Search_for_Temples_Pujas_Chadhava_and_Prasad:
    "Search_for_Temples_Pujas_Chadhava_and_Prasad",
  OUR_SERVICES: "OUR_SERVICES",
  Hi_I_am_listening_Try_saying: "Hi_I_am_listening_Try_saying",
  Listening: "Listening",
  No_results_found_for: "No_results_found_for",
  Pooja_Booking_Closed: "Pooja_Booking_Closed",
  Continue_to_Book: "Continue_to_Book",
  Puja_Booking_Closes_In: "Puja_Booking_Closes_In",
  Prices_may_vary_depending_on_your_Country:
    "Prices_may_vary_depending_on_your_Country",
  Footer_address: "Footer_address",
  Links: "Links",
  About: "About",
  About_Us: "About_Us",
  Contact_Us: "Contact_Us",
  Information: "Information",
  Refunds_and_Cancellations: "Refunds_and_Cancellations",
  Content_Community_Guidlines: "Content_Community_Guidlines",
  Daily_Panchang: "Daily_Panchang",
  Moonrise: "Moonrise",
  Other_Important_Timings: "Other_Important_Timings",
  Moonset: "Moonset",
  To: "To",
  CHAT_WITH_VALMIKI: "CHAT_WITH_VALMIKI",
  Quick_Steps_for_a_Hassle_Free_Puja_Booking:
    "Quick_Steps_for_a_Hassle_Free_Puja_Booking",
  Choose_the_date: "Choose_the_date",
  Enter_your_details_steps: "Enter_your_details_steps",
  Select_add_your_address: "Select_add_your_address",
  Complete_payment_securely: "Complete_payment_securely",
  Receive_transaction_and_puja_updates_via_WhatsApp:
    "Receive_transaction_and_puja_updates_via_WhatsApp",
  Get_puja_video_picture_on_WhatsApp: "Get_puja_video_picture_on_WhatsApp",
  Get_Prasad_and_puja_samagri_delivered_within_7_business_days:
    "Get_Prasad_and_puja_samagri_delivered_within_7_business_days",
  The_booking_for_the_puja_will_be_closed_two_days_before_the_scheduled_date:
    "The_booking_for_the_puja_will_be_closed_two_days_before_the_scheduled_date",
  Search_for_pujas: "Search_for_pujas",
  Book_Your_Puja: "Book_Your_Puja",
  devotees: "devotees",
  Temple_Dekho_is_Indias_leading_digital_platform:
    "Temple_Dekho_is_Indias_leading_digital_platform",
  This_is_Awesome: "This_is_Awesome",
  Members_for_this_puja: "Members_for_this_puja",
  No_data_found: "No_data_found",
  Read_More: "Read_More",
  Temple_Details: "Temple_Details",
  Cart_Replace_POP_UP_MESSAGE: "Cart_Replace_POP_UP_MESSAGE ",
  You_Can_Add_Maximum: "You_Can_Add_Maximum",
  Members_At_A_Time: "Members_At_A_Time",
  Yes: "Yes",
  No: "No",
  Select_The_Chadhava_Offering_From_The_List:
    "Select_The_Chadhava_Offering_From_The_List",
  Search_for_temples: "Search_for_temples",
  Temple_is_closed: "Temple_is_closed",
  Temple_Closed: "Temple_Closed",
  View_All: "View_All",
  No_posts_available: "No_posts_available",
  Back_to_Blog: "Back_to_Blog",
  mins_read: "mins_read",
  Share: "Share",
  Select_a_Platform: "Select_a_Platform",
  Onboarded: "Onboarded",
  Show_Less: "Show_Less",
  Show_More: "Show_More",
  Resend_available_in: "Resend_available_in",
  Experience_divine_convenience_Book_temple_pujas_anytime_anywhere_with_Temple_Dekho:
    "Experience_divine_convenience_Book_temple_pujas_anytime_anywhere_with_Temple_Dekho",
  Elevate_your_spiritual_journey_with_seamless_temple_puja_booking_through_Temple_Dekho:
    "Elevate_your_spiritual_journey_with_seamless_temple_puja_booking_through_Temple_Dekho",
  Elevate_your_home_pujas_with_ease_Book_a_pandit_for_hassle_free_house_pujas_at_your_fingertips:
    "Elevate_your_home_pujas_with_ease_Book_a_pandit_for_hassle_free_house_pujas_at_your_fingertips",
  Create_a_New_Account: "Create_a_New_Account",
  Register: "Register",
  Spiritual_Experience_with_Temple_Dekho:
    "Spiritual_Experience_with_Temple_Dekho",
  Gender: "Gender",
  Joined_us_before: "Joined_us_before",
  Document_front: "Document_front",
  Document_back: "Document_back",
  Please_enter_all_necessary_fields_to_register:
    "Please_enter_all_necessary_fields_to_register",
  Cancel_edit: "Cancel_edit",
  Name_photo_email_Number_Birthday: "Name_photo_email_Number_Birthday",
  Add_edit_delete_address: "Add_edit_delete_address",
  add_New_Adress_Button: "add_New_Adress_Button",
  street_Label: "street_Label",
  city_Label: "city_Label",
  areaLabel: "areaLabel",
  state_Province_Area_Label: "state_Province_Area_Label",
  zip_Code_Label: "zip_Code_Label",
  location_Button_Label: "location_Button_Label",
  street_House_Apt_Label: "street_House_Apt_Label",
  city_District_Town_Label: "city_District_Town_Label",
  state_Label: "state_Label",
  pin_Code_Label: "pin_Code_Label",
  alternate_PhoneNumber_Label: "alternate_PhoneNumber_Label",
  formatted_Address_Label: "formatted_Address_Label",
  save_Button_Label: "save_Button_Label",
  edit_Addres_Label: "edit_Addres_Label",
  address_Name_Error_Message: "address_Name_Error_Message",
  city_Error_Message: "city_Error_Message",
  state_Error_Message: "state_Error_Message",
  zip_CodeError_Message: "zip_CodeError_Message",
  area_Error_Message: "area_Error_Message",
  invalid_PhoneNumber_Message: "invalid_PhoneNumber_Message",
  county_Code: "county_Code",
 Select_your_preferred_date_for_the_Chadhava_offering:"Select_your_preferred_date_for_the_Chadhava_offering",
 Complete_your_booking_with_a_secure_online_payment:"Complete_your_booking_with_a_secure_online_payment",
 Our_Temple_Partner_An_Experienced_Priest:"Our_Temple_Partner_An_Experienced_Priest",
 You_Will_Receive_Chadhava_Video_On_Your_WhatsApp:"You_Will_Receive_Chadhava_Video_On_Your_WhatsApp",
 Chadhava_Items_Are_Offered_Directly_To_The_Deity:"Chadhava_Items_Are_Offered_Directly_To_The_Deity",
 You_Are_Not_Logged_In_Please_Login:"You_Are_Not_Logged_In_Please_Login",
 Review:"Review",
 Details:"Details",
 Prasad_Delivery_Is_Only_Available_In_India:"Prasad_Delivery_Is_Only_Available_In_India",
 Login_Or_Signup:"Login_Or_Signup",
 Offers_Zone:"Offers_Zone",
 X_1_Devotees: " X_1_Devotees",
 Confirm_Deletion: "Confirm_Deletion",
 Do_You_Really_Want_To_Clear_Everything_From_Your_Cart:"Do_You_Really_Want_To_Clear_Everything_From_Your_Cart",
 Delete:"Delete",
 Selected_Address_Is_Outside_India:"Selected_Address_Is_Outside_India",
 Coupon_Code_Is_Not_Valid:"Coupon_Code_Is_Not_Valid",
 Alert:"Alert",
 Prasad_Package:"Prasad_Package",
 OK:"OK",
 Prasad_delivery_currently_limited_to_locations_within_India:"Prasad_delivery_currently_limited_to_locations_within_India",
 Value:"Value",
 Please_Enter_Your_Full_Name:"Please_Enter_Your_Full_Name",
 WhatsApp_Number_Error_Message:"WhatsApp_Number_Error_Message",
 Items:"Items",
 View_and_edit_profile:'View_and_edit_profile',
 Admin_Panel:'Admin_Panel',
 Share_This_Link:"Share_This_Link",
 Copy_Link:"Copy_Link",
 Most_Booked:"Most_Booked",
 Read_Less:"Read_Less",
 Custom:"Custom",
 Confirm_Logout:'Confirm_Logout',
 Are_you_sure_you_want_to_log_out_You_will_need_to_log_in_again_continue_booking_pujas_or_making_offerings:'Are_you_sure_you_want_to_log_out_You_will_need_to_log_in_again_continue_booking_pujas_or_making_offerings',
 Order_history:'Order_history',
 Order_User_Name:'Order_User_Name',
 Chadhava_Item_List:'Chadhava_Item_List',
 Prasad_Item_List:'Prasad_Item_List',
 Chadhava_Image:'Chadhava_Image',
 Prasad_Image:'Prasad_Image',
 Chadhava_Item:'Chadhava_Item',
 Prasad_Item:'Prasad_Item',
 Qty:'Qty',
 Add_ons:"Add_ons",
 Total_Amount:"Total_Amount",
 Payment_Successful:"Payment_Successful",
 Thank_You_Your_Payment_Received:"Thank_You_Your_Payment_Received",
 paymentReceived:"paymentReceived",
 Transaction_ID:"Transaction_ID",
 No_items_added_in_cart:"No_items_added_in_cart",
 Explore_temples:"Explore_temples",
 Explore_pujas:"Explore_pujas",
 Previous:"Previous",
 Kashyap:"Kashyap",
 Login_Register:"Login_Register",
 Sorry_For_Inconvenience:"Sorry_For_Inconvenience",
 Kashyapa:"Kashyapa",
 Booked:"Booked",
 More_Details:"More_Details",
 Starts: "Starts",
};
