import pujaMainImg from "../../assets/puja-img.png";
import templeIcon from "../../assets/temple-icon.png";
import locationIcon from "../../assets/loc-icon.png";
import pricetagIcon from "../../assets/price-tag.png";
import viewPujaSteps from "../../assets/viewpujasteps.png";
import viewBless from "../../assets/bless-your-self.png";
import "./PujaInfoView.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";

import {
  Box,
  Grid,
  Stack,
  Typography,
  Button,
  Dialog,
  Snackbar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  ClickAwayListener,
} from "@mui/material";
import TitleTypography from "../../components/typography/TitleTypography";
import ParagraphTypography from "../../components/typography/ParagraphTypography";
import PercentRoundedIcon from "@mui/icons-material/PercentRounded";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import SubtitleTypography from "../../components/typography/SubtitleTypography";
import SubtitleTypographyPrimary from "../../components/typography/SubtitleTypographyPrimary";
import RoundedButton from "../../components/button/RoundedButton";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  calculateActualPujaPrice,
  calculateOverSeasPrice,
  formatNumberWithK,
  getNearestValidDate,
  getTempleName,
} from "../../utils/HelperFunctions";
import ShareLink from "../../components/sharelink/sharelink";
import { useEffect, useState } from "react";
import packageImg1 from "../../assets/Package-img1.png";
import packageImg2 from "../../assets/Package-img2.png";
import packageImg3 from "../../assets/Package-img3.png";
import packageImg6 from "../../assets/Package-img6.png";
import packageImg8 from "../../assets/Package-img8.png";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CloseIcon from "@mui/icons-material/Close";

import { SyntheticEvent } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  CAROUSEL_REMOVE_ARRAOW_ON_DEVICE,
  LOCALSTORAGE_KEY,
  PUJA_PACKAGE_SCROLL_RESPONSIVE,
} from "../../utils/Constants";
import dayjs, { Dayjs } from "dayjs";
import PujaPackageCard from "../../components/card/PujaPackage/PujaPackageCard";
import { disclaimerText, POP_UP_MESSAGE } from "../../utils/ConstantsMessages";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AlertDialog from "../../components/dialog/AlertDialog";
import moment from "moment";
import PujaEndTimer from "../../components/Timer/PujaEndTimer";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { LOCALIZATION } from "../../utils/localization";

function PujaView(props: any) {
  const { t, i18n } = useTranslation();
  const {
    selectedTempleDetails,
    pujaPriceInfo,
    avialableCouponCode,
    pujaDetails,
    cartInfo,
    setPopupPujaAlreadyAdded,
    preprocessingAndProceedToAddmemberScreen,
    isSingleDatePuja,
    setIsDateTimePicker,
    isSingleDatePujaEnabled,
    navigateToSeeVideoView,
    navigateToPujaStepsView,
    isDateTimePicker,
    shouldDisableDate,
    setPujaDateAndTime,
    pujaDateAndTime,
    popupPujaAlreadyAdded,
    overSeasRate,
    currencySymbols,
    isOverseas,
    isCountryDisabledForBooking,
    defaultCalendardate,
  } = props;
  const savedLanguage =
    localStorage.getItem(LOCALSTORAGE_KEY.LANG_CODE) ?? "en";
  const templeName = getTempleName(selectedTempleDetails, savedLanguage);

  const personalInfo = selectedTempleDetails?.personalInfo;
  const templeAddress =
    (personalInfo?.address?.street ||
      personalInfo?.address?.city ||
      personalInfo?.address?.area) +
    " , " +
    personalInfo?.address?.state;

  const getImageForBenefitsDataByBenefitId = (benefitId: string) => {
    const benefitList = props.benefitsList;
    const benefitFilterData = benefitList.filter(function (item: any) {
      return item.benefitId === benefitId;
    });
    if (benefitList.length > 0) {
      return benefitFilterData[0];
    } else {
      return null;
    }
  };
  const packageSelected = props.packageSelected;

  const [
    openAlertDialogForBookingNotAllowed,
    setOpenAlertDialogForBookingNotAllowed,
  ] = useState<any>(false);
  const actualPujaPriceAggregated = pujaPriceInfo?.finalPrice;

  const couponCodeText = avialableCouponCode?.couponCode;

  const [urlToShare, setUrlToShare] = useState<string>("");
  const templeAndPujaInfoPath = window.location.pathname;

  useEffect(() => {
    const hostname =
      window.location.hostname === "localhost"
        ? "http://localhost:3000"
        : "https://" + window.location.hostname;
    setUrlToShare(
      `${hostname}/pagelink?pujaNameKey=${pujaDetails?.pujaNameKey}&templeNameKey=${selectedTempleDetails?.nameKey}`
    );

    // }
  }, [selectedTempleDetails, pujaDetails]);

  const sortPackageData = (multiplePujaDetails: any) => {
    let multiplePuja = [...multiplePujaDetails];
    let data = multiplePuja.sort((a: any, b: any) =>
      Number(a.maxMemberAllowed) > Number(b.maxMemberAllowed) ? 1 : -1
    );

    return data;
  };

  const pujaBgImage =
    pujaDetails && pujaDetails.pujaBg && pujaDetails.pujaBg.uri
      ? pujaDetails.pujaBg.uri
      : pujaMainImg;
  let pujaPackages =
    pujaDetails && pujaDetails.multiplePujaDetails
      ? sortPackageData(pujaDetails.multiplePujaDetails)
      : [];

  const [open, setOpen] = useState<boolean>(false);

  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(couponCodeText);
  };

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const bookedSingleDatePuja = () => {
    if (isCountryDisabledForBooking) {
      setOpenAlertDialogForBookingNotAllowed(true);
      return;
    }
    if (cartInfo && cartInfo.length > 0) {
      setPopupPujaAlreadyAdded(true);
    } else {
      preprocessingAndProceedToAddmemberScreen();
    }
  };
  //Puja Package Radio btn
  const [radioSelectedValue, setRadioSelectedValue] = useState("option2");

  const handleSetIsDateTimePicker = (isOpen: boolean) => {
    if (isCountryDisabledForBooking) {
      setOpenAlertDialogForBookingNotAllowed(true);
    } else {
      setIsDateTimePicker(isOpen);
    }
  };

  const handleCloseAlertDialogForBookingNotAllowed = () => {
    setOpenAlertDialogForBookingNotAllowed(false);
  };

  const getDevoteeImage = (index: any) => {
    switch (index) {
      case "1":
        return packageImg1;
      case "2":
        return packageImg2;
      case "4":
        return packageImg3;
      case "6":
        return packageImg6;
      case "8":
        return packageImg8;
      default:
        return packageImg8;
    }
  };

  // POPUP Open on HOVER
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  const handlePriceInfoOpen = () => setIsPopUpOpen(true);
  const handlePriceInfoClose = () => setIsPopUpOpen(false);
  // END

  const minDate = dayjs().add(2, "day");
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const [pujaEndTime, setPujaEndTime] = useState<any>(null);

  useEffect(() => {
    const newDateSubtract = moment(
      getNearestValidDate(pujaDetails?.restrictionDate)
    )
      .clone()
      .subtract(3, "days")
      .toDate(); // Convert back to Date object
    setPujaEndTime(newDateSubtract);
  }, [pujaDetails]);

  //console.log('-------------END---------',pujaEndTime)

  const isPujaBookingClosed = () => {
    return !!(
      selectedTempleDetails?.isClosed ||
      selectedTempleDetails?.isClosedBooking ||
      pujaDetails?.isPujaDisable
    );
  };

  return (
    <Box className="pageContainer pujaPage">
      <Box className="pujaBlock">
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            md={2}
            lg={3}
            xl={3}
            className="pagehdLeftBlock"
          ></Grid>
          <Grid item xs={12} md={8} lg={6} xl={6}>
            <Box className="pujainfoCenterBlock">
              <Box className="pujaCard">
                <Box className="pujaCardBlock">
                  <Box className="pujaImgBlock">
                    <img src={pujaBgImage} alt=""></img>
                  </Box>
                  <Box className="pujaDescription">
                    <Box className="pujaNameInfo">
                      <Typography>{pujaDetails?.pujaName}</Typography>
                    </Box>
                    <Box className="nameLocPrice">
                      <List>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          className="twoItem"
                        >
                          <ListItem>
                            <ListItemAvatar sx={{ minWidth: "35px" }}>
                              <Avatar
                                alt="Temple Icon"
                                className="squreShape temple"
                              >
                                <img src={templeIcon}></img>
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={`${templeName}`} />
                          </ListItem>
                          <Box className="shareIcon">
                            <ShareLink urlToShare={urlToShare}></ShareLink>
                          </Box>
                        </Stack>

                        <ListItem>
                          <ListItemAvatar sx={{ minWidth: "35px" }}>
                            <Avatar
                              alt="Location Icon"
                              className="squreShape location"
                            >
                              <img src={locationIcon}></img>
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={`${templeAddress}`} />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar sx={{ minWidth: "35px" }}>
                            <Avatar
                              alt="Pricetag Icon"
                              className="squreShape pricetag"
                            >
                              <img src={pricetagIcon}></img>
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            className="pujaPriceInfo"
                            sx={{ flex: "0 0 auto" }}
                            primary={`${t(
                              LOCALIZATION.Price
                            )}: ${currencySymbols}${
                              calculateOverSeasPrice(
                                actualPujaPriceAggregated,
                                overSeasRate
                              ) || " "
                            }`}
                          />
                          {/* Info Button & PopUp Here */}
                          <Box className="infoContainer">
                            <ClickAwayListener
                              onClickAway={handlePriceInfoClose}
                            >
                              <Box>
                                <InfoOutlinedIcon
                                  className="infoIcon"
                                  onClick={handlePriceInfoOpen}
                                  onMouseEnter={handlePriceInfoOpen}
                                />
                                {isPopUpOpen && (
                                  <Box
                                    className="popupBox"
                                    onMouseLeave={handlePriceInfoClose}
                                  >
                                    <Typography className="messageTitle">
                                      {t(LOCALIZATION.Price)}
                                    </Typography>
                                    <Typography className="messageText">
                                      {t(
                                        LOCALIZATION.The_price_does_not_include_Prasad_delivery
                                      )}
                                    </Typography>
                                  </Box>
                                )}
                              </Box>
                            </ClickAwayListener>
                          </Box>
                        </ListItem>
                        <ListItem>
                          {couponCodeText ? (
                            <ListItemText
                              sx={{ flex: "0 0 auto" }}
                              style={{ cursor: "pointer", marginLeft: "45px" }}
                              onClick={() => handleClick()}
                            >
                              <Typography className="couponDiscountCode">
                                <PercentRoundedIcon />
                                Coupon: {couponCodeText}
                              </Typography>
                            </ListItemText>
                          ) : null}
                        </ListItem>
                        <ListItem>
                          <Box className="pujaCloseTimer">
                            <PujaEndTimer endDate={pujaEndTime} />
                          </Box>
                        </ListItem>
                        <ListItem>
                          <Box className="devoteeInfomation">
                            <Typography className="countText">
                              <span>
                                {`${
                                  pujaDetails?.acctualPrice &&
                                  pujaDetails?.acctualPrice > 0
                                    ? formatNumberWithK(
                                        pujaDetails?.acctualPrice * 2.5
                                      )
                                    : formatNumberWithK(13 * 145)
                                }`}
                                +
                              </span>{" "}
                              {t(
                                LOCALIZATION.devotees_booked_puja_from_this_temple
                              )}
                            </Typography>
                          </Box>
                        </ListItem>
                        <ListItem>
                          <Typography className="disclaimerText">
                            <ErrorOutlineIcon />
                            {/* {disclaimerText.textMessage} */}
                            {t(
                              LOCALIZATION.Prices_may_vary_depending_on_your_Country
                            )}
                          </Typography>
                        </ListItem>
                        {isPujaBookingClosed() && (
                          <ListItemText sx={{ color: "red" }}>
                            {t(LOCALIZATION.Pooja_Booking_Closed)}
                          </ListItemText>
                        )}
                      </List>

                      <Button
                        type="button"
                        className="continueBtn desktopOnly"
                        onClick={() => {
                          if (isSingleDatePuja) {
                            bookedSingleDatePuja();
                          } else {
                            handleSetIsDateTimePicker(true);
                          }
                        }}
                        disabled={
                          isPujaBookingClosed() ||
                          (isSingleDatePuja && !isSingleDatePujaEnabled)
                        }
                      >
                        {isPujaBookingClosed() ||
                        (isSingleDatePuja && !isSingleDatePujaEnabled)
                          ? t(LOCALIZATION.Pooja_Booking_Closed)
                          : t(LOCALIZATION.Continue_to_Book)}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {packageSelected ? (
                <Box className="SelectPackage">
                  <SubtitleTypographyPrimary
                    title={t("Select_Puja_Package")}
                  ></SubtitleTypographyPrimary>
                  <Box className="packageItem">
                    <Carousel
                      responsive={PUJA_PACKAGE_SCROLL_RESPONSIVE}
                      swipeable={true}
                      draggable={true}
                      showDots={false}
                      keyBoardControl={true}
                      removeArrowOnDeviceType={CAROUSEL_REMOVE_ARRAOW_ON_DEVICE}
                      className="pujaPackCarousel"
                    >
                      {pujaPackages.map((item: any) => {
                        let activeFlg = "inactive";
                        if (
                          packageSelected &&
                          packageSelected.acctualPrice === item.acctualPrice
                        ) {
                          activeFlg = "active";
                        }

                        return (
                          <PujaPackageCard
                            setPackageSelected={props.setPackageSelected}
                            getDevoteeImage={getDevoteeImage}
                            activeFlg={activeFlg}
                            packageDetails={item}
                            selectedTempleDetails={selectedTempleDetails}
                            pujaDetails={pujaDetails}
                            overSeasRate={overSeasRate}
                            currencySymbols={currencySymbols}
                            isOverseas={isOverseas}
                            isCountryDisabledForBooking={
                              isCountryDisabledForBooking
                            }
                          />
                        );
                      })}
                    </Carousel>
                  </Box>
                  <Box className="packagePujaInfo">
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={2}
                      className="packagePujaTitle"
                    >
                      <img
                        src={getDevoteeImage(packageSelected?.maxMemberAllowed)}
                        alt=""
                      />
                      <Box>
                        <Typography variant="h6">
                          {pujaDetails?.pujaName}
                        </Typography>
                        <Typography component="p">
                          {t(LOCALIZATION.Participants_Upto)}{" "}
                          {packageSelected?.maxMemberAllowed}{" "}
                          {packageSelected?.maxMemberAllowed > 1
                            ? t(LOCALIZATION.Devotees)
                            : t(LOCALIZATION.Devotee)}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                </Box>
              ) : null}

              <Box className="overViewText">
                <SubtitleTypographyPrimary
                  title={t(LOCALIZATION.Puja_Overview)}
                ></SubtitleTypographyPrimary>
                <ParagraphTypography
                  title={`${pujaDetails?.description}`}
                ></ParagraphTypography>
              </Box>

              <Box className="templeDekhoVideo">
                <SubtitleTypographyPrimary
                  title={t(LOCALIZATION.About_Temple_Dekho)}
                ></SubtitleTypographyPrimary>
                <Typography>
                  {t(LOCALIZATION.Please_watch_our_feature_video)}{" "}
                  <span>{t(LOCALIZATION.About_Temple_Dekho)}</span> app.
                </Typography>
                <Button
                  onClick={() => navigateToSeeVideoView()}
                  variant="contained"
                  className="seeVideoBtn"
                >
                  {t(LOCALIZATION.See_VIDEO)}
                </Button>
              </Box>

              <Box className="pujaBenefits">
                <SubtitleTypographyPrimary
                  title={t(LOCALIZATION.Benefits_of_Puja)}
                ></SubtitleTypographyPrimary>
                {pujaDetails &&
                  pujaDetails.benefitList &&
                  pujaDetails.benefitList.map((item: any) => {
                    let updatedBenefitsData = item;
                    const benefitDetais = getImageForBenefitsDataByBenefitId(
                      item.benefitId
                    );

                    if (benefitDetais) {
                      updatedBenefitsData = benefitDetais;
                    }
                    return (
                      <Box className="benefitsCard">
                        <SubtitleTypography
                          title={`${updatedBenefitsData.name}`}
                        ></SubtitleTypography>
                        <Box className="benefitCardImg">
                          <img src={`${updatedBenefitsData.url}`} alt=""></img>
                        </Box>
                        <ParagraphTypography
                          title={`${updatedBenefitsData.description}`}
                        ></ParagraphTypography>
                      </Box>
                    );
                  })}
              </Box>

              <Box className="pujaProccessSteps">
                <SubtitleTypographyPrimary
                  title={t(
                    LOCALIZATION.Quick_Steps_for_a_Hassle_Free_Puja_Booking
                  )}
                ></SubtitleTypographyPrimary>
                <List>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Box className="listCounter">
                          <Typography
                            component="span"
                            className="pujaStepsListCount"
                          >
                            1
                          </Typography>

                          <Typography>
                            {t(LOCALIZATION.Select_and_add_puja)}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Box className="listCounter">
                          <Typography
                            component="span"
                            className="pujaStepsListCount"
                          >
                            2
                          </Typography>

                          <Typography>
                            {t(LOCALIZATION.Choose_the_date)}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Box className="listCounter">
                          <Typography
                            component="span"
                            className="pujaStepsListCount"
                          >
                            3
                          </Typography>

                          <Typography>
                            {t(LOCALIZATION.Enter_your_details_steps)}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Box className="listCounter">
                          <Typography
                            component="span"
                            className="pujaStepsListCount"
                          >
                            4
                          </Typography>

                          <Typography>
                            {t(LOCALIZATION.Select_add_your_address)}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Box className="listCounter">
                          <Typography
                            component="span"
                            className="pujaStepsListCount"
                          >
                            5
                          </Typography>

                          <Typography>
                            {t(LOCALIZATION.Complete_payment_securely)}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Box className="listCounter">
                          <Typography
                            component="span"
                            className="pujaStepsListCount"
                          >
                            6
                          </Typography>

                          <Typography>
                            {t(
                              LOCALIZATION.Receive_transaction_and_puja_updates_via_WhatsApp
                            )}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Box className="listCounter">
                          <Typography
                            component="span"
                            className="pujaStepsListCount"
                          >
                            7
                          </Typography>

                          <Typography>
                            {t(LOCALIZATION.Get_puja_video_picture_on_WhatsApp)}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Box className="listCounter">
                          <Typography
                            component="span"
                            className="pujaStepsListCount"
                          >
                            8
                          </Typography>

                          <Typography>
                            {t(
                              LOCALIZATION.Get_Prasad_and_puja_samagri_delivered_within_7_business_days
                            )}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                </List>
              </Box>
              <Box
                onClick={() => navigateToPujaStepsView()}
                className="viewPujaSteps"
              >
                <img src={viewPujaSteps}></img>
              </Box>
              {/* FAQ */}
              <Box className="chadhavaFaq">
                <SubtitleTypographyPrimary
                  title={t(LOCALIZATION.Frequently_Asked_Questions)}
                ></SubtitleTypographyPrimary>

                <Box className="chadhavaFaqList">
                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel1"}
                    onChange={handleChange1("panel1")}
                    sx={{
                      "&.Mui-expanded::before": {
                        opacity: 1,
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel1" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel1"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                      }}
                    >
                      {t(LOCALIZATION.What_is_Temple_Dekho_FAQ)}
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      {t(LOCALIZATION.What_is_Temple_Dekho_FAQ_Answer)}
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel2"}
                    onChange={handleChange1("panel2")}
                    sx={{
                      "&.Mui-expanded::before": {
                        opacity: 1,
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel2" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel2"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel2-content"
                      id="panel2-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                      }}
                    >
                      {t(
                        LOCALIZATION.What_makes_your_virtual_puja_services_FAQ
                      )}
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      {t(
                        LOCALIZATION.What_makes_your_virtual_puja_services_FAQ_Answer
                      )}
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel3"}
                    onChange={handleChange1("panel3")}
                    sx={{
                      "&.Mui-expanded::before": {
                        opacity: 1,
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel3" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel3"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel3-content"
                      id="panel3-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                      }}
                    >
                      {t(LOCALIZATION.How_do_you_prioritize_the_security_FAQ)}
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      {t(
                        LOCALIZATION.How_do_you_prioritize_the_security_FAQ_Answer
                      )}
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel4"}
                    onChange={handleChange1("panel4")}
                    sx={{
                      "&.Mui-expanded::before": {
                        opacity: 1,
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel4" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel4"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel4-content"
                      id="panel4-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                      }}
                    >
                      {t(LOCALIZATION.Who_is_designated_to_perform_my_Puja_FAQ)}
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      {t(
                        LOCALIZATION.Who_is_designated_to_perform_my_Puja_FAQ_Answer
                      )}
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel5"}
                    onChange={handleChange1("panel5")}
                    sx={{
                      "&.Mui-expanded::before": {
                        opacity: 1,
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel5" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel5"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel5-content"
                      id="panel5-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                      }}
                    >
                      {t(
                        LOCALIZATION.Can_I_participate_in_a_Puja_if_I_don_know_my_Gotram_FAQ
                      )}
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      {t(
                        LOCALIZATION.Can_I_participate_in_a_Puja_if_I_don_know_my_Gotram_FAQ_Answer
                      )}
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel6"}
                    onChange={handleChange1("panel6")}
                    sx={{
                      "&.Mui-expanded::before": {
                        opacity: 1,
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel6" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel6"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel6-content"
                      id="panel6-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                      }}
                    >
                      {t(LOCALIZATION.How_do_I_get_evidence_of_my_Puja_FAQ)}
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      {t(
                        LOCALIZATION.How_do_I_get_evidence_of_my_Puja_FAQ_Answer
                      )}
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel7"}
                    onChange={handleChange1("panel7")}
                    sx={{
                      "&.Mui-expanded::before": {
                        opacity: 1,
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel7" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel7"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel7-content"
                      id="panel7-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                      }}
                    >
                      {t(
                        LOCALIZATION.Who_should_I_reach_out_to_for_more_details_FAQ
                      )}
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      {t(
                        LOCALIZATION.Who_should_I_reach_out_to_for_more_details_FAQ_Answer
                      )}
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel8"}
                    onChange={handleChange1("panel8")}
                    sx={{
                      "&.Mui-expanded::before": {
                        opacity: 1,
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel8" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel8"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel8-content"
                      id="panel8-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                      }}
                    >
                      {t(LOCALIZATION.How_do_I_receive_the_prasad_FAQ)}
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      {t(LOCALIZATION.How_do_I_receive_the_prasad_FAQ_Answer)}
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
              <Box className="blessContinue">
                <Box className="viewBless">
                  <img src={viewBless}></img>
                </Box>
                {actualPujaPriceAggregated > 0 && (
                  <Box className="continueCart">
                    <Box className="continueCenterBlock">
                      <Box className="continueLt">
                        {`${currencySymbols}${calculateOverSeasPrice(
                          actualPujaPriceAggregated,
                          overSeasRate
                        )}`}
                        /-
                      </Box>
                      <Box className="continueRt">
                        <Button
                          type="button"
                          className="continueBtn"
                          disabled={
                            isPujaBookingClosed() ||
                            (isSingleDatePuja && !isSingleDatePujaEnabled)
                          }
                          onClick={() => {
                            if (isSingleDatePuja) {
                              bookedSingleDatePuja();
                            } else {
                              handleSetIsDateTimePicker(true);
                            }
                          }}
                        >
                          {isPujaBookingClosed() ||
                          (isSingleDatePuja && !isSingleDatePujaEnabled)
                            ? "Booking Closed"
                            : t(LOCALIZATION.Continue)}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={2} lg={3} xl={3} className="pagehdRightBlock">
            {/* <RightBlockCard /> */}
          </Grid>
        </Grid>
        <Dialog
          className="dateTimingSelection"
          onClose={() => handleSetIsDateTimePicker(false)}
          open={isDateTimePicker}
        >
          <Box className="dateTimePopup">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className="calendarTopInfo"
            >
              <TitleTypography
                title={t(LOCALIZATION.Select_Date)}
              ></TitleTypography>
              <IconButton
                aria-label="close"
                onClick={() => handleSetIsDateTimePicker(false)}
                className="topCloseIconBtn"
                sx={{
                  position: "absolute",
                  right: 14,
                  top: 14,
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>

            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="flex-start"
              className="pujaReminderSec"
            >
              <ErrorOutlineOutlinedIcon />
              <Typography className="pujaReminderText">
                {t(
                  LOCALIZATION.The_booking_for_the_puja_will_be_closed_two_days_before_the_scheduled_date
                )}{" "}
              </Typography>
            </Stack>

            <Box className="calendarInfo">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  showDaysOutsideCurrentMonth={true}
                  shouldDisableDate={shouldDisableDate}
                  disablePast={true}
                  minDate={minDate}
                  referenceDate={defaultCalendardate}
                  disableHighlightToday={true}
                  onChange={(value: any) => {
                    setPujaDateAndTime({
                      ...pujaDateAndTime,
                      pujaDate: value,
                    });
                  }}
                />
              </LocalizationProvider>
            </Box>
            <Box className="btnContinue">
              <RoundedButton
                title={t(LOCALIZATION.Continue)}
                disabled={
                  pujaDateAndTime && pujaDateAndTime.pujaDate ? false : true
                }
                onClick={() => {
                  handleSetIsDateTimePicker(false);
                  if (cartInfo && cartInfo.length > 0) {
                    setPopupPujaAlreadyAdded(true);
                  } else {
                    preprocessingAndProceedToAddmemberScreen();
                  }
                }}
              ></RoundedButton>
            </Box>
          </Box>
        </Dialog>

        <Dialog
          onClose={() => setPopupPujaAlreadyAdded(false)}
          open={popupPujaAlreadyAdded}
        >
          <Box style={{ padding: "1em" }}>
            <Typography>
              {t(LOCALIZATION.A_puja_already_added_in_your_cart)}
            </Typography>
            <Box sx={{ textAlign: "end" }}>
              <Button onClick={() => setPopupPujaAlreadyAdded(false)}>
                {t(LOCALIZATION.No)}
              </Button>
              <Button
                onClick={() => preprocessingAndProceedToAddmemberScreen()}
              >
                {t(LOCALIZATION.Yes)}
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Text copied to clipboard"
      />
      <AlertDialog
        open={openAlertDialogForBookingNotAllowed}
        onClose={handleCloseAlertDialogForBookingNotAllowed}
        message={t(LOCALIZATION.Bookings_are_not_permitted_in_your_country)}
      />
    </Box>
  );
}

export default PujaView;
