import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";
import { Stack } from "react-bootstrap";
import MultipleSelectAutocomplete from "../../../components/AutoComplete/MultipleSelectAutocomplete";
import AddWeightageDialog from "./AddWeightageDialog";
import "./SpecialChadhavaForm.scss";

interface SpecialChadhavaFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (formData: any) => Promise<any>;
  templeList: any;
  handleTempleSelect: ([]: any) => any;
  allChadhavaList: any;
  handleChadhavaSelect: (chadhavaList: any) => any;
  seletedTempleOptions: any;
  seletedChadhavaOptions: any;
  selectedSpecialChadhava: any;
  setSeletedTempleOptions: ([]: any) => any;
  setSeletedChadhavaOptions: ([]: any) => any;
}

const SpecialChadhavaForm: React.FC<SpecialChadhavaFormModalProps> = ({
  isOpen,
  onClose,
  onSave,
  templeList,
  handleTempleSelect,
  allChadhavaList,
  handleChadhavaSelect,
  seletedTempleOptions,
  seletedChadhavaOptions,
  selectedSpecialChadhava,
  setSeletedTempleOptions,
  setSeletedChadhavaOptions,
}) => {
  const [formData, setFormData] = useState({
    id: "",
    specialChadhavaName: "",
    searchKey:"",
    specialChadhavaWeightage: "",
    isPromotion: false,
    dashBoardImage: "",
    chadhavaListImage: "",
  });
  const [errors, setErrors] = useState<any>({
    specialChadhavaName: "",
    searchKey:"",
    specialChadhavaWeightage: "",
    dashBoardImage: "",
    chadhavaListImage: "",
    templeList: "",
    chadhavaList: "",
  });
  const [localImageDashBoard, setLocalImageDashBoard] = useState<any>("");
  const [localImageChadhavaList, setLocalImageChadhavaList] = useState<any>("");
  const [isAddWeightageDialogOpen, setIsAddWeightageDialogOpen] =
    useState(false);
  const [selectedTemple, setSelectedTemple] = useState<any>(null);
  const [weightageContext, setWeightageContext] = useState<"Temple" | "Chadhava">(
    "Temple"
  ); 
  const [isFormSubmit, setIsFormSubmit] = useState<boolean>(false)
  useEffect(() => {
    if (selectedSpecialChadhava) {
      let loadFormData: any = {
        id: selectedSpecialChadhava?.id,
        specialChadhavaName: selectedSpecialChadhava?.specialChadhavaName,
        searchKey: selectedSpecialChadhava?.searchKey,
        specialChadhavaWeightage: selectedSpecialChadhava?.specialChadhavaWeightage,
        isPromotion: selectedSpecialChadhava?.isPromotion,
        dashBoardImage: selectedSpecialChadhava?.dashBoardBg?.uri,
        chadhavaListImage: selectedSpecialChadhava?.chadhavaListBg?.uri,
      };
      setFormData({ ...loadFormData });
    }
  }, [selectedSpecialChadhava]);

  const resetForm = () => {
    setLocalImageDashBoard("");
    setLocalImageChadhavaList("");
    setSeletedTempleOptions([]);
    setSeletedChadhavaOptions([])
    setFormData({
      id: "",
      specialChadhavaName: "",
      searchKey:"",
      specialChadhavaWeightage: "",
      isPromotion: false,
      dashBoardImage: "",
      chadhavaListImage: "",
    });
    setErrors({
      specialChadhavaName: "",
      searchKey:"",
      specialChadhavaWeightage: "",
      dashBoardImage: "",
      chadhavaListImage: "",
      templeList: "",
      chadhavaList: "",
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked, files } = e.target;
    setErrors((prev:any) => ({
      ...prev,
      [name]: "",
    }));

    if (name === "name") {
      const regex = /^[a-zA-Z\s]*$/;
      if (!regex.test(value)) return;
    }

    if (name === "specialChadhavaWeightage") {
      const regex = /^[0-9]*$/;
      if (!regex.test(value)) return;
    }
    if (type === "file" && files) {
      const reader = new FileReader();

      reader.onload = (e) => {
        if (name === "dashBoardImage") {
          setLocalImageDashBoard(e.target?.result);
        }
        if (name === "chadhavaListImage") {
          setLocalImageChadhavaList(e.target?.result);
        }
      };
      reader.readAsDataURL(files[0]);

      setFormData((prev) => ({
        ...prev,
        [name]: files[0],
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };
  const validateForm = () => {
    const newErrors = {
      specialChadhavaName: formData?.specialChadhavaName?.trim() ? "" : "Name is required",
      searchKey: formData?.searchKey?.trim() ? "" : "Search Key is required",
      specialChadhavaWeightage:
        formData?.specialChadhavaWeightage?.trim() &&
        Number(formData?.specialChadhavaWeightage) > 0
          ? ""
          : "Weightage must be a positive number",
      dashBoardImage:
        localImageDashBoard || formData?.dashBoardImage
          ? ""
          : "Dashboard Image is required",
      chadhavaListImage:
        localImageChadhavaList || formData?.chadhavaListImage
          ? ""
          : "ChadhavaList Image is required",
      templeList : seletedTempleOptions?.length > 0 ? "" : "Plese select temples",
      chadhavaList:  seletedChadhavaOptions?.length > 0 ? "" : "Plese select pchadhava"
    };

    setErrors(newErrors);

    return !Object.values(newErrors).some((error) => error);
  };

  const handleSubmit = async (e:any) => {
    e.preventDefault();
    if(isFormSubmit) return;
    if (!validateForm()) return;
    setIsFormSubmit(true)
    onSave(formData).then(()=>{
      setIsFormSubmit(false);
      resetForm();
    }).catch(()=>{
      setIsFormSubmit(false)
    });
    
  };

  const handleCloseFormModal = () => {
    onClose();
    resetForm();
  };

  const handleOpenWeightageDialog = (context: "Temple" | "Chadhava", temple?: any) => {
    setWeightageContext(context);
    setSelectedTemple(temple || null);
    setIsAddWeightageDialogOpen(true);
  };

  const handleCloseWeightageDialog = () => {
    setIsAddWeightageDialogOpen(false);
    setSelectedTemple(null);
  };
  const handleWeightageSubmit = (weightage: string) => {
    
    handleCloseWeightageDialog();
  };


  return (
  <>
    <Dialog open={isOpen} onClose={handleCloseFormModal} className="specialChadhavaAdminDialog">
      <DialogTitle className="spAdminDialogTitle">{selectedSpecialChadhava ? "Edit" : "Add"} Special Chadhava Item</DialogTitle>
      <DialogContent className="innerContent" sx={{ minWidth: 600 }}>
        <TextField
          className="itemList"
          name="specialChadhavaName"
          label="Special Chadhava Name"
          fullWidth
          margin="normal"
          value={formData?.specialChadhavaName}
          onChange={handleChange}
          error={!!errors?.specialChadhavaName}
          helperText={errors?.specialChadhavaName}
          FormHelperTextProps={{
            sx: { marginLeft: "3px" },
          }}
        />
         <TextField
          className="itemList"
          name="searchKey"
          label="Add Search Key"
          fullWidth
          margin="normal"
          value={formData?.searchKey}
          onChange={handleChange}
          error={!!errors?.searchKey}
          helperText={errors?.searchKey}
          FormHelperTextProps={{
            sx: { marginLeft: "3px" },
          }}
        />
        <TextField
          className="itemList"
          name="specialChadhavaWeightage"
          label="Special Chadhava Weightage"
          fullWidth
          margin="normal"
          type="number"
          InputProps={{
            inputMode: "numeric",
          }}
          value={formData?.specialChadhavaWeightage}
          onChange={handleChange}
          error={!!errors?.specialChadhavaWeightage}
          helperText={errors?.specialChadhavaWeightage}
          FormHelperTextProps={{
            sx: { marginLeft: "3px" },
          }}
        />
        <Box className="itemList">
          <MultipleSelectAutocomplete
            options={templeList}
            label="Temple List"
            placeholder="Choose Temple"
            onChange={(evt:any)=>{
              setErrors((prev:any)=>{
                return {
                  ...prev,
                  templeList: "",
                }
              })
              handleTempleSelect(evt)
            }}
            alreadySelectedOptions={seletedTempleOptions}
          />
          {!!errors?.templeList && (<Box sx={{color: "#d32f2f", margin: "0",fontFamily: "Roboto"}} > {errors?.templeList} </Box>)}
        </Box>

        <Box className="itemList">
          <MultipleSelectAutocomplete
            options={allChadhavaList}
            label="Chadhava List"
            placeholder="Choose Chadhava"
            onChange={(evt:any)=>{
              setErrors((prev:any)=>{
                return {
                  ...prev,
                  chadhavaList: "",
                }
              })
              handleChadhavaSelect(evt)
            }}
            alreadySelectedOptions={seletedChadhavaOptions}
          />
          {!!errors?.chadhavaList && (<Box sx={{color: "#d32f2f", margin: "0",fontFamily: "Roboto"}} > {errors?.chadhavaList} </Box>)}
        </Box>

        <Box className="weightageBtnRow itemList">
            <Button
              variant="outlined"
              onClick={() => handleOpenWeightageDialog("Temple", selectedTemple)}
            >
              Add Temple Weightage
            </Button>
 
            <Button
              variant="outlined"
              onClick={() => handleOpenWeightageDialog("Chadhava", selectedTemple)}
            >
              Add Chadhava Weightage
            </Button>
          </Box>

        <Stack className="chooseImgInfo itemList">
          <TextField
            name="dashBoardImage"
            label="Deshboard Image"
            type="file"
            fullWidth
            margin="normal"
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              accept: "image/*",
            }}
            error={!!errors?.dashBoardImage}
            helperText={errors?.dashBoardImage}
            FormHelperTextProps={{
              sx: { marginLeft: "3px" },
            }}
          />
          {localImageDashBoard?.length === 0 &&
            formData?.dashBoardImage?.length > 0 && (
              <img src={formData?.dashBoardImage} alt=""/>
            )}
          {localImageDashBoard?.length > 0 && <img src={localImageDashBoard} alt="" />}
        </Stack>

        <Stack className="chooseImgInfo itemList">
          <TextField
            name="chadhavaListImage"
            label="Chadhava List Image"
            type="file"
            fullWidth
            margin="normal"
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              accept: "image/*",
            }}
            error={!!errors?.chadhavaListImage}
            helperText={errors?.chadhavaListImage}
            FormHelperTextProps={{
              sx: { marginLeft: "3px" },
            }}
          />
          {localImageChadhavaList?.length === 0 &&
            formData?.chadhavaListImage?.length > 0 && (
              <img src={formData?.chadhavaListImage} alt=""/>
            )}
          {localImageChadhavaList?.length > 0 && <img src={localImageChadhavaList} alt=""/>}
        </Stack>

        <FormControlLabel
          control={
            <Checkbox
              checked={formData?.isPromotion}
              onChange={handleChange}
              name="isPromotion"
            />
          }
          label="Check To Enable"
        />
      </DialogContent>
      <DialogActions className="spAdminModalFooter">
        <Button onClick={handleCloseFormModal} className="btnCancel grey">
          Cancel
        </Button>
        <Button className="saveBtn" onClick={handleSubmit} variant="outlined" color="primary" disabled={isFormSubmit}>
          {isFormSubmit ?'Saving...' :'Save'}
        </Button>
      </DialogActions>
    </Dialog>

    <AddWeightageDialog
    open={isAddWeightageDialogOpen}
    onClose={handleCloseWeightageDialog}
    onSubmit={handleWeightageSubmit}
    seletedTempleOptions={seletedTempleOptions}
    context={weightageContext}
    seletedChadhavaOptions={seletedChadhavaOptions}
    setSeletedChadhavaOptions={setSeletedChadhavaOptions}
    setSeletedTempleOptions={setSeletedTempleOptions}
    templeList = {templeList}
    allChadhavaList={allChadhavaList}
 />
 </>
  );
};

export default SpecialChadhavaForm;
