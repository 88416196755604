import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { manageNewOnTD } from "../../../utils/ConstantsMessages";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import DeleteConfirmDialog from "../../../components/dialog/DeleteConfirmDialog";
import ManageNewOnTempleDekhoForm from "./ManageNewOnTempleDekhoForm";

interface ManageNewOnTempleDekhoViewProps {
  onSubmit: (formData: any) => void;
  manageNewOnTDList: any;
  handleEditManageNewOnTDItem: any;
  openManageNewOnForm: boolean;
  handleOpenManageNewOnForm: () => void;
  handleCloseManageNewOnForm: () => void;
  selectedManageNewOnTDItem: any;
  setSelectedManageNewOnTDItem: any;
  handleDeleteManageNewOnTD: any;
}

const ManageNewOnTempleDekhoView: React.FC<ManageNewOnTempleDekhoViewProps> = (
  props: any
) => {
  const {
    onSubmit,
    manageNewOnTDList,
    handleEditManageNewOnTDItem,
    openManageNewOnForm,
    handleOpenManageNewOnForm,
    handleCloseManageNewOnForm,
    selectedManageNewOnTDItem,
    handleDeleteManageNewOnTD,
  } = props;

  const [selectedItem, setSelectedItem] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleDeleteClick = (item: any) => {
    setSelectedItem(item);
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    if (selectedItem) {
      handleDeleteManageNewOnTD(selectedItem);
      setSelectedItem(null);
    }
    handleCloseDeleteDialog();
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setSelectedItem(null);
  };

  return (
    <Box className="pageContainer specialPujaAdmin">
      <Box className="topTitle">
        <Typography className="adminTitleText">
          {manageNewOnTD.title}
        </Typography>
      </Box>

      <Box className="btnAddSpecialPuja">
        <Button onClick={handleOpenManageNewOnForm} variant="contained">
          <AddCircleOutlineIcon sx={{ fontSize: "20px", mr: 1 }} />
          {manageNewOnTD.button}
        </Button>
      </Box>

      {manageNewOnTDList && manageNewOnTDList?.length > 0 ? (
        <List className="specialPujaItemListAdmin">
          {manageNewOnTDList.map((_manageNewOnTDList: any, index: number) => (
            <ListItem
              key={_manageNewOnTDList?.id}
              className="specialPujaAdminCard"
              sx={{
                backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#d569391a",
              }}
            >
              <ListItemAvatar>
                <Avatar
                  src={_manageNewOnTDList?.dashboardImage}
                  sx={{
                    width: 80,
                    height: 80,
                    marginRight: 2,
                    borderRadius: "10px",
                    border: "1px solid #cdcdcd",
                  }}
                />
              </ListItemAvatar>

              <ListItemText
                className="listText"
                primary={
                  <Box>
                    <Typography variant="body1" fontWeight="bold">
                      {_manageNewOnTDList?.onClick?.params?.title}
                    </Typography>
                  </Box>
                }
              />
              <IconButton
                className="spPujaEditBtn"
                aria-label="edit"
                onClick={(e) => {
                  e.preventDefault();
                  handleEditManageNewOnTDItem(_manageNewOnTDList);
                }}
              >
                <EditIcon sx={{ fontSize: "18px" }} />
              </IconButton>
              <IconButton
                className="spPujaDeleteBtn"
                aria-label="delete"
                onClick={() => handleDeleteClick(_manageNewOnTDList)}
              >
                <DeleteIcon sx={{ fontSize: "18px" }} />
              </IconButton>
            </ListItem>
          ))}
        </List>
      ) : (
        <NoDataFound />
      )}

      <Dialog
        open={openManageNewOnForm}
        onClose={handleCloseManageNewOnForm}
        fullWidth
      >
        <DialogContent>
          <ManageNewOnTempleDekhoForm
            onSubmit={onSubmit}
            onClose={handleCloseManageNewOnForm}
            selectedManageNewOnTDItem={selectedManageNewOnTDItem}
          />
        </DialogContent>
      </Dialog>
      <DeleteConfirmDialog
        open={isDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleConfirmDelete}
        msg="Are you sure you want to delete this item?"
      />
    </Box>
  );
};

export default ManageNewOnTempleDekhoView;
