
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

interface SpecialPujaWeightageDialogProps {
  open: boolean;
  onClose: () => void;
  seletedTempleOptions: any;
  context: "Temple" | "Chadhava";
  seletedChadhavaOptions:any
  onSubmit:any;
  setSeletedChadhavaOptions:(item:any)=>any;
  setSeletedTempleOptions:(item:any)=>any;
  templeList: any;
  allChadhavaList:any;
}

const SortableItem = ({ id, text }: { id: string; text: string }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    background: "white",
    padding: "10px",
    marginBottom: "8px",
    borderRadius: "4px",
    border: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
  };

  return (
    <ListItem ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <ListItemText primary={text} />
    </ListItem>
  );
};

const AddWeightageDialog: React.FC<SpecialPujaWeightageDialogProps> = ({
  open,
  onClose,
  seletedTempleOptions,
  seletedChadhavaOptions,
  setSeletedTempleOptions,
  setSeletedChadhavaOptions,
  context,
  templeList,
  allChadhavaList,
}) => {
  const [formError, setFormError] = useState<boolean>(false);
  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    if (context === "Temple" && seletedTempleOptions) {
      if(seletedTempleOptions.length > 0 && typeof seletedTempleOptions[0] === 'string'){
        const filteredTemple = templeList?.filter((itm:any)=>seletedTempleOptions?.includes(itm?.value))
        setItems(filteredTemple)
        return;
      }
      setItems(seletedTempleOptions);
    } else if (context === "Chadhava" && seletedChadhavaOptions) {
      if(seletedChadhavaOptions?.length > 0 && typeof seletedChadhavaOptions[0] === 'string'){
        const filteredPujas = allChadhavaList?.filter((itm:any)=>seletedChadhavaOptions?.includes(itm?.value))
        setItems(filteredPujas)
        return;
      }
      setItems(seletedChadhavaOptions);
    }
  }, [seletedTempleOptions, seletedChadhavaOptions, context, templeList, allChadhavaList]);

  const handleSubmit = () => {
    if (context === "Temple"  ) {
      setSeletedTempleOptions(items);
    } else if (context === "Chadhava" ) {
      setSeletedChadhavaOptions(items);
    }
    setFormError(false);
    handleCloseWeightageDialog();
  };

  const handleCloseWeightageDialog = () => {
    onClose();
    setFormError(false);
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item.value === active.id);
      const newIndex = items.findIndex((item) => item.value === over.id);
      setItems((items) => arrayMove(items, oldIndex, newIndex));
    }
  };

  return (
    <Dialog open={open} onClose={handleCloseWeightageDialog}>
      <DialogTitle>
        Add {context === "Temple" ? "Temple" : "Puja"} Weightage
      </DialogTitle>
      <DialogContent sx={{ minWidth: 600 }}>
        <Box mt={2}>
          <DndContext
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={items.map((item) => item.value)} 
              strategy={verticalListSortingStrategy}
            >
              <List>
                {items.map((item) => (
                  <SortableItem
                    key={item.value}
                    id={item.value} 
                    text={item.label}
                  />
                ))}
              </List>
            </SortableContext>
          </DndContext>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseWeightageDialog}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddWeightageDialog;

