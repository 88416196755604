import React, { useEffect, useState } from "react";
import { Box, Button, DialogTitle, Stack, TextField } from "@mui/material";
import "./ManageOnboardingBenefitsForm.scss";

interface ManageOnboardingBenefitsFormProps {
  onSubmit: (formData: any, isEdit: boolean) => void;
  onClose: () => void;
  selectedManageOnboardingBenefitsItem: any;
}

const ManageOnboardingBenefitsForm: React.FC<
  ManageOnboardingBenefitsFormProps
> = ({ onSubmit, onClose, selectedManageOnboardingBenefitsItem }) => {
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [
    localImageManageOnboardingBenefits,
    setLocalImageManageOnboardingBenefits,
  ] = useState<string>("");
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [formData, setFormData] = useState({
    category: "",
    description: "",
    name: "",
    url: "",
  });

  const [errors, setErrors] = useState({
    category: "",
    description: "",
    name: "",
    url: "",
  });

  useEffect(() => {
    if (selectedManageOnboardingBenefitsItem) {
      setIsEdit(true);
      const loadFormData: any = {
        name: selectedManageOnboardingBenefitsItem?.name ?? "",
        description: selectedManageOnboardingBenefitsItem?.description ?? "",
        category: selectedManageOnboardingBenefitsItem?.category ?? "",
        url: selectedManageOnboardingBenefitsItem?.url ?? "",
      };
      setFormData({ ...loadFormData });
      setLocalImageManageOnboardingBenefits(
        loadFormData?.url ? loadFormData?.url : ""
      );
    } else {
      resetForm();
    }
  }, [selectedManageOnboardingBenefitsItem]);

  const resetForm = () => {
    setFormData({
      category: "",
      description: "",
      name: "",
      url: "",
    });
    setLocalImageManageOnboardingBenefits("");
    setErrors({
      category: "",
      description: "",
      name: "",
      url: "",
    });
    setIsEdit(false);
  };

  const validateForm = () => {
    let tempErrors: any = {};
    let isValid = true;

    if (!formData.name.trim()) {
      tempErrors.name = "Name is required";
      isValid = false;
    }
    if (!formData.description.trim()) {
      tempErrors.description = "Description is required";
      isValid = false;
    }
    if (!localImageManageOnboardingBenefits && !formData?.url) {
      tempErrors.url = "Image upload is required.";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked, files } = event.target;

    if (type === "file" && files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setLocalImageManageOnboardingBenefits(e.target?.result as string);
      };
      reader.readAsDataURL(files[0]);
      setFormData({
        ...formData,
        [name]: files[0],
      });

      setErrors((prevErrors: any) => ({
        ...prevErrors,
        url: "",
      }));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });

      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmit(true);

    if (!validateForm()) {
      setIsSubmit(false);
      return;
    }

    const updatedFormData = {
      ...formData,
    };

    onSubmit(updatedFormData, isEdit);
    resetForm();
    setIsSubmit(false);
    onClose();
  };

  const handleCancel = () => {
    resetForm();
    onClose();
  };

  return (
    <>
      <DialogTitle className="manage-onboarding-benefits__title">
        {isEdit ? "Edit Onboarding Benefits" : "Add Onboarding Benefits"}
      </DialogTitle>
      <Box className="manage-onboarding-benefits__form">
        <form onSubmit={handleSubmit}>
          <Box mb={1}>
            <TextField
              label="Name"
              variant="outlined"
              name="name"
              value={formData?.name}
              onChange={handleChange}
              fullWidth
              error={!!errors.name}
              helperText={errors.name}
            />
          </Box>

          <Box mb={1}>
            <TextField
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              fullWidth
              multiline
              rows={4}
              margin="normal"
              error={!!errors.description}
              helperText={errors.description}
            />
          </Box>

          <Box mb={2} className="manage-onboarding-benefits__image-upload">
            <TextField
              name="url"
              label="Upload Image *"
              type="file"
              fullWidth
              margin="normal"
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                accept: "image/*",
              }}
              error={!!errors.url}
              helperText={errors.url}
            />
            {(localImageManageOnboardingBenefits || formData?.url) && (
              <Box mt={2} className="manage-onboarding-benefits__image-preview">
                <img
                  src={localImageManageOnboardingBenefits || formData?.url}
                  alt="Uploaded Preview"
                  className="manage-onboarding-benefits__image-preview-img"
                />
              </Box>
            )}
          </Box>

          <Box mb={3}>
            <TextField
              label="Category"
              name="category"
              value={formData.category}
              onChange={handleChange}
              fullWidth
              margin="normal"
              error={!!errors.category}
              helperText={errors.category}
            />
          </Box>

          <Stack spacing={2} direction="row" justifyContent="flex-end" mt={2}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCancel}
              className="manage-onboarding-benefits__cancel-btn"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="manage-onboarding-benefits__submit-btn"
              disabled={isSubmit}
            >
              {isSubmit ? "Saving..." : "Save"}
            </Button>
          </Stack>
        </form>
      </Box>
    </>
  );
};

export default ManageOnboardingBenefitsForm;
