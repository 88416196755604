import { useEffect, useState } from "react";
import { CommonService } from "td_server";
import { useDispatch, useSelector } from "react-redux";
import { replaceSpacesWithHyphen } from "../../../utils/HelperFunctions";
import ManageSpecialChadhavaView from "../../../Views/Admin/ManageSpecialChadhava/ManageSpecialChadhavaView";
import {
  addSpecialChadhavaFromWeb,
  deleteSpecialChadhavaFromWeb,
  editSpecialChadhavaFromWeb,
  getAllSpecialChadhavaFromWeb,
  getChadhavaForTempleById,
} from "../../../store/actions/ChadhawaAction";


function ManageSpecialChadhavaScreen() {
  const dispatch: any = useDispatch();
  const { allTempleList } = useSelector((state: any) => state?.temple);
  const { allPromotionalChadhava } = useSelector((state: any) => state.chadhawa);
  const [templeList, setTempleList] = useState<any>([]);
  const [allChadhavaList, setAllChadhavaList] = useState<any>([]);
  const [
    isAddEditSpecialChadhavaModalOpen,
    setIsAddEditSpecialChadhavaModalOpen,
  ] = useState(false);
  const [seletedTempleOptions, setSeletedTempleOptions] = useState<any>([]);
  const [seletedChadhavaOptions, setSeletedChadhavaOptions] = useState<any>([]);
  const [promotionalList, setPromotionalList] = useState([]);
  const [selectedSpecialChadhava, setSelectedSpecialChadhava] =
    useState<any>(null);
  const [isTempleListModified, handleIsTempleListModified] =
    useState<boolean>(false);
  const [isChadhavaListModified, handleIsChadhavaListModified] =
    useState<boolean>(false);

  useEffect(() => {
    try {
      dispatch(getAllSpecialChadhavaFromWeb());
    } catch (error) {
      console.log("Error:", error);
    }
  }, [dispatch]);

  useEffect(() => {
    setPromotionalList(allPromotionalChadhava);
  }, [allPromotionalChadhava]);

  useEffect(() => {
    if (allTempleList && allTempleList?.length) {
      let formatedList = allTempleList?.map((item: any) => {
        return { value: item?.id, label: item?.personalInfo?.name };
      });
      formatedList = [...formatedList];
      setTempleList(formatedList);
    }
  }, [allTempleList]);

  const handleTempleSelect = async (event: any) => {
    setSeletedTempleOptions(event);
    handleIsTempleListModified(true);
    let chadhavas: { value: string; label: string }[] = [];
    const selectedTempleIds = event?.map((item: any) => item?.value);
    const selectedTempleNames = event?.map((item: any) => item?.label);

    if (selectedTempleIds.length > 0 && selectedTempleNames.length > 0) {
      for( let [indexTmpl,tmpl] of selectedTempleIds.entries()){
        const resChadhava = await fetchTempleChadhava(
          tmpl
        );
        if(resChadhava.length > 0 && selectedTempleNames.length > 0){
          for( const [index, _chadhavaItem] of resChadhava.entries()){
                chadhavas.push({
                value: `${tmpl}_${_chadhavaItem?.id}`,
                label: `${selectedTempleNames[indexTmpl]}-${_chadhavaItem?.name}`,
              });
          }
        }

      }
    }
    
    setAllChadhavaList(chadhavas);
  };

  const handleChadhavaSelect = (event: any) => {
    handleIsChadhavaListModified(true);
    setSeletedChadhavaOptions(event);
  };

  const handleEditSpecialChadhavaItem = async(item: any) => {
    handleIsChadhavaListModified(false);
    handleIsTempleListModified(false);
    setSelectedSpecialChadhava(item);
    setIsAddEditSpecialChadhavaModalOpen(true);

    let chadhavas: { value: string; label: string }[] = [];
    const templesDetailInPromotionalChadhava = item?.templeList ?? [];
    if (templesDetailInPromotionalChadhava.length > 0) {
      for( let temple of templesDetailInPromotionalChadhava){
          const templeChadhavaList = await fetchTempleChadhava(temple?.id);
          if(templeChadhavaList && templeChadhavaList.length > 0){
            for(let chdv of templeChadhavaList){
              let templeChadhavaId = {
                value: `${temple?.id}_${chdv?.id}`,
                label: `${temple?.name}-${chdv?.name}`
              }
              chadhavas.push(templeChadhavaId);
            }
          }
  
        
      }
    }
  
    // Selected temple and chadhava
    let selectedTempleIds: string[] = [];
    let selectedChadhavaIds: string[] = [];
    if (item?.templeList?.length > 0) {
      for(let el of item?.templeList){
        selectedTempleIds.push(el?.id);
        if (el?.templeChadhava?.length > 0) {
          for( let elp of el?.templeChadhava){
                    let templeChadhavaId = `${el?.id}_${elp?.id}`;
                    selectedChadhavaIds.push(templeChadhavaId);
          }
        }
      }
    }
    setAllChadhavaList(chadhavas);
    setSeletedTempleOptions([...selectedTempleIds]);
    setSeletedChadhavaOptions([...selectedChadhavaIds]);
  };

  const fetchTempleChadhava = async (templeId: string) => {
    try {
      const res = await dispatch(getChadhavaForTempleById(templeId));
      return res;
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleSubmitSpecialChadhava = async (formData: any):Promise<any> => {
    try {
      let payload: any = {};
      if (formData?.dashBoardImage instanceof File) {
        formData["dashBoardBg"] = {
          uri: await CommonService.fileUpload(
            {
              uri: formData?.dashBoardImage,
              platform: "web",
            },
            "users_details/special_chadhava_picture"
          ),
        };

        delete formData["dashBoardImage"];
      } else {
        formData["dashBoardBg"] = { ...selectedSpecialChadhava?.dashBoardBg };
        delete formData["dashBoardImage"];
      }

      if (formData?.chadhavaListImage instanceof File) {
        formData["chadhavaListBg"] = {
          uri: await CommonService.fileUpload(
            {
              uri: formData?.chadhavaListImage,
              platform: "web",
            },
            "users_details/special_chadhava_picture"
          ),
        };
        delete formData["chadhavaListImage"];
      } else {
        formData["chadhavaListBg"] = {
          ...selectedSpecialChadhava?.chadhavaListBg,
        };
        delete formData["chadhavaListImage"];
      }
      // ADD
      if (!selectedSpecialChadhava) {
        payload = { ...formData };
        delete payload["id"];
        let templeListForChadhava: any = [];
        let modifiedPujaName = replaceSpacesWithHyphen(
          payload?.specialChadhavaName
        );
        payload["specialChadhavaNameKey"] = modifiedPujaName;
        if (seletedTempleOptions.length > 0) {
          // For Temple
          for (let templeItem of seletedTempleOptions) {
            let templeInfoData: any = {};
            const templeData = allTempleList?.find(
              (_tmp: any) => _tmp?.id === templeItem?.value
            );
            if (templeData) {
              templeInfoData.id = templeData?.id ?? "";
              templeInfoData.name = templeData?.personalInfo?.name ?? "";
              templeInfoData.nameKey = templeData?.nameKey ?? "";
              templeInfoData.personalInfo = templeData?.personalInfo ?? {};
            }
            let templeChadhava: any = [];
            const chadhavaForTemple = await fetchTempleChadhava(
              templeItem?.value
            );
            const seletedChadhavaOptionsForTemple =
              seletedChadhavaOptions.filter(
                (itm: any) => itm?.value?.split("_")[0] === templeItem?.value
              );
            if (
              seletedChadhavaOptionsForTemple.length > 0 &&
              chadhavaForTemple?.length > 0
            ) {
              // For Chadhava in temple
              seletedChadhavaOptionsForTemple?.forEach((_chadItem: any) => {
                let chadhavaId = _chadItem?.value?.split("_")[1];
                let selectedChadhava = chadhavaForTemple?.find(
                  (_itm: any) => _itm?.id === chadhavaId
                );
                templeChadhava.push(selectedChadhava);
              });
            }

            templeInfoData.templeChadhava = [...templeChadhava];
            templeListForChadhava.push(templeInfoData);
          }
        }
        payload.templeList = [...templeListForChadhava];
        payload.version = "0.0";
        await dispatch(addSpecialChadhavaFromWeb(payload));
      }
      // EDIT
      if (selectedSpecialChadhava) {
        payload = { ...selectedSpecialChadhava, ...formData };

        if (isTempleListModified && !isChadhavaListModified) {
          let templeListForChadhava: any = [];
          if (seletedTempleOptions.length > 0) {
            // For Temple
            for (let templeItem of seletedTempleOptions) {
              let templeInfoData: any = {};
              const templeData = allTempleList?.find(
                (_tmp: any) => _tmp?.id === templeItem?.value
              );
              if (templeData) {
                templeInfoData.id = templeData?.id ?? "";
                templeInfoData.name = templeData?.personalInfo?.name ?? "";
                templeInfoData.nameKey = templeData?.nameKey ?? "";
                templeInfoData.personalInfo = templeData?.personalInfo ?? {};
              }
              let templeChadhava: any = [];
              const chadhavaForTemple = await fetchTempleChadhava(
                templeItem?.value
              );
              const seletedChadhavaOptionsForTemple =
                seletedChadhavaOptions.filter(
                  (itm: any) => itm?.split("_")[0] === templeItem?.value
                );
              if (
                seletedChadhavaOptionsForTemple.length > 0 &&
                chadhavaForTemple?.length > 0
              ) {
                // For Chadhava in temple
                seletedChadhavaOptionsForTemple?.forEach((_chadItem: any) => {
                  let chadhavaId = _chadItem?.split("_")[1];
                  let selectedChadhava = chadhavaForTemple?.find(
                    (_itm: any) => _itm?.id === chadhavaId
                  );
                  templeChadhava.push(selectedChadhava);
                });
              }

              templeInfoData.templeChadhava = [...templeChadhava];
              templeListForChadhava.push(templeInfoData);
            }
          }
          payload.templeList = [...templeListForChadhava];
        }

        //  only chadhava modified
        if (isChadhavaListModified && !isTempleListModified) {
          let modifiedTempleList:any = [];
          for( let _templeItem of seletedTempleOptions) {
            let filtredTemple = payload?.templeList?.find((_itm:any)=> _itm?.id === _templeItem ) ?? [];
            let chadhavaList:any = [];
            for( let _chadhavaItem of seletedChadhavaOptions){
              const chadhavId = _chadhavaItem?.value?.split("_")[1];
              let tempChadhava:any = await fetchTempleChadhava(
                _templeItem
              );
              tempChadhava = tempChadhava?.find((itm:any)=>itm?.id === chadhavId)
              if(tempChadhava){
                chadhavaList.push(tempChadhava);
              }
              
            }
            let newFilteredTemple = { ...filtredTemple }
            newFilteredTemple["templeChadhava"] = [ ...chadhavaList ]
            modifiedTempleList.push(newFilteredTemple);
          }
          payload["templeList"] = [ ...modifiedTempleList ]
        }

        // SAME as ADD
        if (isTempleListModified && isChadhavaListModified) {
          let templeListForChadhava: any = [];
          if (seletedTempleOptions.length > 0) {
            // For Temple
            for (let templeItem of seletedTempleOptions) {
              let templeInfoData: any = {};
              const templeData = allTempleList?.find(
                (_tmp: any) => _tmp?.id === templeItem?.value
              );
              if (templeData) {
                templeInfoData.id = templeData?.id ?? "";
                templeInfoData.name = templeData?.personalInfo?.name ?? "";
                templeInfoData.nameKey = templeData?.nameKey ?? "";
                templeInfoData.personalInfo = templeData?.personalInfo ?? {};
              }
              let templeChadhava: any = [];
              const chadhavaForTemple = await fetchTempleChadhava(
                templeItem?.value
              );
              const seletedChadhavaOptionsForTemple =
                seletedChadhavaOptions.filter(
                  (itm: any) => itm?.value?.split("_")[0] === templeItem?.value
                );
              if (
                seletedChadhavaOptionsForTemple.length > 0 &&
                chadhavaForTemple?.length > 0
              ) {
                // For Chadhava in temple
                seletedChadhavaOptionsForTemple?.forEach((_chadItem: any) => {
                  let chadhavaId = _chadItem?.value?.split("_")[1];
                  let selectedChadhava = chadhavaForTemple?.find(
                    (_itm: any) => _itm?.id === chadhavaId
                  );
                  templeChadhava.push(selectedChadhava);
                });
              }

              templeInfoData.templeChadhava = [...templeChadhava];
              templeListForChadhava.push(templeInfoData);
            }
          }
          payload.templeList = [...templeListForChadhava];
        }
        await dispatch(editSpecialChadhavaFromWeb(payload,payload?.id))
      }
      handleCloseAddEditSpecialChadhavaForm();
    } catch (error) {

    }
  };


  const handleCloseAddEditSpecialChadhavaForm = () => {
    handleIsChadhavaListModified(false);
    handleIsTempleListModified(false);
    setIsAddEditSpecialChadhavaModalOpen(false);
    setSelectedSpecialChadhava(null);
    setSeletedTempleOptions([]);
    setSeletedChadhavaOptions([]);
  };

  const handleDeleteSpecialChadhava = (specialChadhava: any) => {
    try {
      dispatch(deleteSpecialChadhavaFromWeb(specialChadhava?.id));
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleSearchSpChadhavalist = (str: any) => {
    if (str?.length > 0) {
      const newStr = str?.toLowerCase();
      const newList = allPromotionalChadhava.filter((item: any) =>
        item?.specialChadhavaName?.toLowerCase()?.includes(newStr)
      );
      setPromotionalList(newList);
    } else {
      setPromotionalList(allPromotionalChadhava);
    }
  };

  return (
    <ManageSpecialChadhavaView
      isAddEditSpecialChadhavaModalOpen={isAddEditSpecialChadhavaModalOpen}
      setIsAddEditSpecialChadhavaModalOpen={
        setIsAddEditSpecialChadhavaModalOpen
      }
      promotionalList={promotionalList}
      templeList={templeList}
      handleTempleSelect={handleTempleSelect}
      allChadhavaList={allChadhavaList}
      handleChadhavaSelect={handleChadhavaSelect}
      seletedTempleOptions={seletedTempleOptions}
      seletedChadhavaOptions={seletedChadhavaOptions}
      selectedSpecialChadhava={selectedSpecialChadhava}
      setSelectedSpecialChadhava={setSelectedSpecialChadhava}
      setSeletedTempleOptions={setSeletedTempleOptions}
      setSeletedChadhavaOptions={setSeletedChadhavaOptions}
      handleEditSpecialChadhavaItem={handleEditSpecialChadhavaItem}
      handleSubmitSpecialChadhava={handleSubmitSpecialChadhava}
      handleCloseAddEditSpecialChadhavaForm={
        handleCloseAddEditSpecialChadhavaForm
      }
      handleDeleteSpecialChadhava={handleDeleteSpecialChadhava}
      handleSearchSpChadhavalist={handleSearchSpChadhavalist}
    />
  );
}

export default ManageSpecialChadhavaScreen;
