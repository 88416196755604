import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import "./TermsAndConditionsView.scss"

const TermsAndConditions = (props: any) => {
    return (

        <Box 
        className={`${
            props.parentViewCheck
              ? "pageContainer"
              : "pageContainerTermsAndConditionNative"
          } termsAndCondition`}
        >
            <Container className="container padding-0">
                <div className="sectionHead text-center style-4 mb-40">
                    <small className="title_small d-none"></small>
                    <Typography className="mainTitle mb-30">
                        Temple Dekho <span>Terms</span> <span>and Condition</span>
                    </Typography>
                </div>
                <div className="tAndcInfoDetails mb-40">
                    <Typography variant="body1" className="mb-20">
                        PLEASE CAREFULLY REVIEW THESE TERMS OF USE. BY USING THE PLATFORM, YOU AGREE TO BE BOUND BY ALL OF THE FOLLOWING TERMS AND CONDITIONS AS WELL AS THE PRIVACY POLICY.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Introduction</Typography>
                        These Terms of Use govern your access to and use of our website located at <a href="https://templedekho.com">(https://templedekho.com)</a> and/or the Temple Dekho mobile application (together, the "Platform") provided by Omkara Infotech Pvt. Ltd. ("Temple Dekho", "Company", "we", "us", and "our"), a private company incorporated in India under the Companies Act, 2013, with its registered office at 15A, Hemanta Basu Sarani, Lal Dighi, B.B.D. Bagh, Kolkata, West Bengal 700001.
                        <br></br>
                        <br></br>
                        Adherence to these standards and Terms of Use is a prerequisite for engaging in business with Omakara Infotech Pvt. Ltd., as they establish the non-negotiable minimum standards that we expect our Vendors or Vendors and their sub-tier Vendors or subcontractors ("the Vendor") to uphold.
                        These Terms must be read in conjunction with the Temple Dekho Content Guidelines and Temple Dekho Privacy and Cookie Policy. Your use of the Services (as defined herein) is subject to these terms and conditions ("Terms of Use").
                        <br></br>
                        <br></br>
                        Please carefully review these Terms of Use, in addition to the Privacy Policy available at [URL] and all other rules and policies accessible or published on the Platform, as they govern your use of the Platform and the services provided therein.
                        By using or accessing the Platform, you indicate your acceptance of these Terms of Use and the Privacy Policy.
                        These Terms of Use constitute an electronic record as defined under the Information Technology Act, 2000 (as amended/re-enacted) and its accompanying rules. They are published in accordance with Rule 3(1) of the Information Technology (Intermediaries Guidelines and Digital Media Ethics Code) Rules, 2021, which mandates the publication of rules, regulations, privacy policies, and terms and conditions for access or usage of any application or website. This electronic record is generated by a computer system and does not require any physical or digital signature. Additionally, certain Services may be subject to additional terms and conditions specified by us from time to time, and your use of such services is subject to those additional terms and conditions, which are incorporated into these Terms of Use by reference.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Terms</Typography>
                        By accessing this Website and Application, referred to henceforth as the "Platform," available at <a href="https://templedekho.com">(https://templedekho.com)</a>, you agree to abide by the Terms and Conditions of Use outlined herein and acknowledge your responsibility to comply with all applicable local laws. If you do not agree with any of these terms, you are not permitted to use this platform. The materials on this platform are protected by copyright and trademark law.
                        <br></br>
                        <br></br>
                        Our Services, as detailed below, and these Terms are in compliance with the Indian Penal Code, 1860, and the Information Technology Act, 2000, including all its amendments and regulations. By creating an account or using our Platform or any of our Services, you implicitly accept and consent to these Terms. However, please note that we do not claim compliance with the laws of any country other than India. If you intend to use our Services, ensure that such use is permitted within your jurisdiction.
                        <br></br>
                        <br></br>
                        By using our Platform, you consent to the access of your Personal Data, provided to us via the Website and Application, in the form of electronic records, books, registers, correspondences, information, documents, or other materials. We may provide data such as Name, Gotra, Date of Birth, or any other necessary information to the Temple Authorities for conducting the Puja Process.
                        <br></br>
                        <br></br>
                        Both you and we are obligated to adhere to certain rules while using our Platform, which are delineated in this document. Please review these Terms and all other associated hyperlinks carefully. By using our Platform, you agree to abide by these Terms. Furthermore, if you are accessing these services outside India, ensure compliance with your local laws.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Changes to Terms of Service</Typography>
                        Our Platform is dynamic and undergoes rapid development. Consequently, we retain the right to modify the services we offer at our discretion. This includes the option to temporarily or permanently discontinue delivering Services or any features to you.
                        <br></br>
                        <br></br>
                        We reserve the privilege to add or remove features from our Platform and Services as needed. In instances where a change necessitates your approval, we will seek it accordingly. We encourage you to revisit this page periodically to remain informed about our latest enhancements and updates.
                        <br></br>
                        <br></br>
                        Please visit this page regularly to review any changes we may implement and any new services we may introduce or modify.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Platform Eligibility Criteria</Typography>
                        Our Platform facilitates communication with your places of worship, temples, fellow devotees, and friends, enabling you to share images, videos, texts, and blogs. We tailor your newsfeed to display posts, pictures, videos, and suggest content available on our Platform ("Service/Services") based on your preferences.
                        <br></br>
                        <br></br>
                        You may only utilize our Services if you are capable of forming a binding agreement with us and are legally permitted to do so. If you are accepting these Terms on behalf of a company or other legal entity, you represent and warrant that you have the authority to bind such entity to these Terms, and references to "you" and "your" shall include the company.
                        <br></br>
                        <br></br>
                        Please ensure that your use of our services complies with applicable laws.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Platform Materials Disclaimer</Typography>
                        All materials on the Temple Dekho Platform, available at <a href="https://templedekho.com">(https://templedekho.com)</a>, are provided "as is". Temple Dekho makes no warranties, whether expressed or implied, and hereby disclaims all other warranties. Additionally, Temple Dekho makes no representations regarding the accuracy or reliability of the materials on its Platform or any sites linked to it.
                        Temple Dekho is represented at all listed places/religious organizations by direct temple trusts & authorities, temple priests, or volunteers on the ground. Temple Dekho endeavors to ensure that every service/ritual is conducted on-site at the specified locations on behalf of devotees, mimicking offline scenarios.
                        <br></br>
                        <br></br>
                        Temple Dekho will provide photos and videos as proof of the authenticity of the Spiritual Services offered, subject to permission from the Spiritual Leaders or authorities of the respective places of worship. While we strive to provide relevant media indicating the completion of Spiritual Services, failure to do so does not entail liability or constitute fraud or cheating on our part.
                        <br></br>
                        <br></br>
                        The cost of offerings mentioned on the Platform includes temple charges, dakshina, and overheads such as packaging, delivery, and service charges.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Revisions & Errata</Typography>
                        The materials featured on the Temple Dekho Platform may contain technical, typographical, or photographic errors.  Temple Dekho does not guarantee that any of the materials on this Platform are accurate, complete, or up-to-date.  Temple Dekho reserves the right to modify the materials on its Platform at any time without prior notice. However, Temple Dekho does not commit to updating the materials.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Link Disclaimer</Typography>
                        Temple Dekho has not reviewed all of the websites linked to its Platform and is not responsible for the content of any such linked sites. The inclusion of any link does not imply endorsement by Temple Dekho of the linked site. The use of any linked website is at the user's own risk.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Guidelines for Service Utilization</Typography>
                        We've designed a distinctive platform accessible in both English and regional languages. Our aim is to offer personalized content tailored to your preferences. Additionally, we enable content downloading and social media sharing to enhance your experience. To access our Services, registration on our Platform is required. Simply input your phone number and the One-Time-Password sent via SMS. By registering through the Utsav App mobile application, you grant us permission to access certain features of your mobile device, including your phone book, SMS inbox, gallery, storage, and camera. Rest assured, we do not access any information stored on your devices or computer without your explicit consent.
                        <br></br>
                        <br></br>
                        To deliver our Services effectively, access to specific features of your mobile device is necessary.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Privacy Policy</Typography>
                        In order to efficiently deliver and introduce new Services to you, we collect specific information such as your phone number and name. Additionally, we may request and securely store additional information. This data is stored on Microsoft Azure and Google Cloud Platform servers, and is subject to the terms outlined in their respective privacy policies. The Temple Dekho Privacy Policy elucidates the methods by which we gather, utilize, share, and safeguard collected information. Furthermore, it delineates your rights under the law and provides instructions on how you can manage the data you provide to us.
                        <br></br>
                        <br></br>
                        Detailed information regarding the storage and utilization of this data can be found in the Temple Dekho Privacy Policy.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">User Responsibilities</Typography>
                        In order to maintain a safe and secure service for our diverse community, collective effort is required from all parties involved. We kindly request that you make certain pledges to us in return for our dedication to providing our Services. Please be aware that you will bear full responsibility for the costs and consequences of any actions undertaken on the Temple Dekho Platform (including any breach of these Terms), as well as the commitments you make below.
                        <br></br>
                        <br></br>
                        By utilizing our Services, you consent to and acknowledge the following:
                        <Box className="ml-20">
                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Authenticity Requirement</Typography>
                            You must provide your accurate phone number to access our Services. Impersonating another individual or falsely representing yourself or someone else is strictly prohibited.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Device Protection</Typography>
                            We have implemented security measures to safeguard our Platform. While we strive to maintain a secure environment, we cannot guarantee immunity from hacking or malware attacks. To ensure the safety of your mobile device and computer, it is recommended to install anti-malware and antivirus software. You are also responsible for safeguarding your phone number and preventing multiple accounts from being associated with it. You will be held responsible for any content uploaded by accounts linked to your phone number and email address.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Content Regulation and Account Management</Typography>
                            The utilization of our Platform is governed by the Utsav App Content and Community Guidelines. If any user reports your content for violating these guidelines, we reserve the right to remove it from our Platform. Repeated violations may result in the termination of your account and a ban from further registration. If you wish to contest any content removal, you may contact us at support@templedekho.com.
                            <br></br>
                            <br></br>
                            We may take down any content shared on our Platform that contravenes the Temple Dekho Content and Community Guidelines.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Platform Use and Legal Compliance</Typography>
                            Our Platform is designed to accommodate a diverse range of languages, religions, and cultures, along with various types of content. Therefore, it is imperative that you accurately label the nature of the content you post by using appropriate hashtags. However, you are strictly prohibited from sharing content that is obscene, pornographic, harmful to minors, discriminatory, constitutes hate speech, incites violence or hatred against individuals, violates Indian laws, or is legally prohibited from sharing. We reserve the right to remove inappropriate content. Please refer to the Temple Dekho Content and Community Guidelines for further clarification.
                            <br></br>
                            <br></br>
                            Additionally, we may disclose your information to law enforcement authorities if we reasonably believe it is necessary to comply with legal obligations or government requests, protect rights or prevent harm to property, safety, customers, or the general public, or address matters related to public safety, fraud, security, or technology. You acknowledge, however, that we cannot be held responsible for any actions taken against you by third parties or users as a result of your Platform usage.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Content Ownership and Usage Rights</Typography>
                            We strongly uphold personal freedom and freedom of expression, allowing you to share various content types on our Platform, including pictures, images, and videos. It is important to note that we do not possess any ownership rights to the content you share; all rights remain exclusively yours. You agree not to utilize our Platform to violate or infringe upon our intellectual property rights or those of any third party. Any such content contravening the Temple Dekho Content and Community Guidelines may be subject to removal from the Platform. Additionally, if you utilize any content created by us, we shall retain ownership of the intellectual property rights vested in such content.
                            <br></br>
                            <br></br>
                            By sharing, posting, or uploading content using our Services, you grant us a non-exclusive, royalty-free, transferable, sub-licensable, worldwide license to utilize, distribute, reproduce, publicly perform or display, translate, and create derivative works of your content (in accordance with your privacy and application settings). You maintain the option to delete your content and/or account at any time, though your content may persist on the Platform if it has been shared with others. For further details on information usage and content control or deletion, please refer to the Temple Dekho Privacy Policy.
                            <br></br>
                            <br></br>
                            You remain fully responsible for the content you publish on our Platform. We do not endorse nor are we liable for any content shared or posted on or via our Platform, including any resulting consequences. The presence of our logo or other trademarks on your content does not imply our endorsement or support. Moreover, we bear no responsibility for the outcomes of any transactions you engage in with other Platform users or advertisers.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Intermediary Status and Non-Liability</Typography>
                            As defined by the Information Technology Act, 2000 and the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021, we operate as an intermediary. These Terms are published in compliance with Rule 3(1) of the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021, mandating the publication of rules, regulations, the Temple Dekho Privacy Policy, and Temple Dekho Terms of Use for accessing and using our Platform. Our role is confined to providing a platform for users to upload, share, and display content, whether created or shared by you or other users.
                            <br></br>
                            <br></br>
                            We do not exert control over the actions of users on the Platform and, therefore, bear no responsibility for the outcomes of such actions, whether online or offline. Furthermore, we disclaim responsibility for services and features offered by third parties, even if accessed through our Services. Our accountability for activities occurring on our Platform is strictly governed by Indian laws and is limited accordingly. You acknowledge that we shall not be liable for any loss of profits, revenues, information, or data, or any consequential, special, indirect, exemplary, punitive, or incidental damages arising from these Terms, even if we are aware of the possibility of such damages. This includes the deletion of your content, information, or account.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Prohibition of Service Disruption Attempts</Typography>
                            Our platform thrives on community collaboration. Hence, you agree not to access or utilize non-public sections of our Platform, Services, or technical infrastructure. It is prohibited to introduce trojans, viruses, or any other harmful software, or employ bots to extract user information from our Platform. Additionally, probing, scanning, or testing the vulnerability of any system, security, or authentication measures we have implemented is strictly forbidden. Tampering or attempting to tamper with our technical framework will result in the cancellation of your user profile and access to our services. We reserve the right to notify law enforcement authorities of such behavior and take legal action against you.
                        </Box>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Permissions Granted To Us</Typography>
                        <Box className="ml-20">
                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Profile Information Sharing Authorization</Typography>
                            You agree to these Terms and grant us specific permissions to enhance your user experience. One such permission is the authorization to share your profile information with third parties. While our Platform remains freely accessible, we may share collected data, such as your username, profile pictures, and engagement patterns, to display sponsored content or advertisements. However, we do not endorse or guarantee the authenticity of any products advertised, and we are not liable to share revenue if you make purchases based on such advertisements. Prior consent will be sought if we intend to share sensitive personal information, as defined by applicable laws.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Permission for App Updates</Typography>
                            We consistently update our Platform and Services to improve user experience. To access our Platform, you may need to download the Temple Dekho mobile application and ensure it is regularly updated.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Consent for Cookie Usage</Typography>
                            We may utilize various technologies, such as cookies and web beacons, to gather and store information regarding your use of the Services and third-party websites. This includes the storage of cookies on your web browser for tracking usage data.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Data Retention Rights</Typography>
                            We reserve the right to retain certain information related to your Platform usage, as outlined in the Temple Dekho Privacy Policy. By using our Platform, you grant us the authority to process, store, and retain information provided by you.
                        </Box>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Our Agreement and Dispute Resolution</Typography>
                        <Box className="ml-20">
                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Rights Under These Terms</Typography>
                            The rights and responsibilities outlined in these Terms are exclusively conferred upon you and cannot be transferred to any third party without our explicit consent. However, we reserve the right to assign our rights and obligations under these Terms to others.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Dispute Resolution Procedure</Typography>
                            In the event of any disputes, you agree that they will be governed by Indian laws, and the courts of Kolkata will have sole jurisdiction over such matters.
                        </Box>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Business Ethics And Integrity</Typography>
                        We uphold principles of honesty, integrity, and impartiality in all our dealings. Omkara Infotech Private Limited adheres to all relevant anti-bribery and corruption laws, including the Prevention of Corruption Act, 1988. Vendors are required to ensure strict compliance with anti-bribery and corruption regulations.
                        <br></br>
                        <br></br>
                        We maintain a strict zero-tolerance stance towards bribery and corruption, as they contradict our core values. Neither bribery nor corruption, including payments made by third parties acting on our behalf such as agents, consultants, vendors, and contractors, are tolerated.

                        <Typography variant="body1" className="mb-10 mt-10 fw-bold">Vendors are expected to:</Typography>
                        <ul>
                            <li>1. Establish monitoring and enforcement mechanisms to ensure adherence to anti-bribery and corruption laws.</li>
                            <li>2. Invoice and claim payments accurately, supported by appropriate documentation.</li>
                            <li>3. Conduct all business transactions transparently, maintaining accurate records.</li>
                            <li>4. Refrain from offering gifts, entertainment, or any form of value to individuals, including government officials, customers, or their representatives, to gain business advantages.</li>
                            <li>5. Avoid engaging in bribery, kickbacks, or facilitation payments.</li>
                            <li>6. Adhere to all pertinent laws and regulations in their operating jurisdictions, encompassing corporate governance, occupational health and safety, labor standards, and environmental management.</li>
                            <li>7. Act ethically, fairly, and professionally in all interactions with Temple Dekho and its stakeholders, promptly reporting any breaches or potential violations of these standards.</li>
                            <li>8. Disclose any potential conflicts of interest to Temple Dekho and promptly inform us of any personal relationships with our employees that could influence their engagements.</li>
                        </ul>

                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Ethical Standards & Human Rights</Typography>
                        Omkara Infotech Pvt. Ltd. ("Temple Dekho") holds a firm commitment to upholding human rights standards throughout its operations. Our expectations for vendors encompass various aspects of ethical conduct and respect for human rights.

                        <Box className="ml-20">
                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Voluntary Labor and Non-Coercion</Typography>
                            Vendors are obligated to ensure that all workers, regardless of their employment status, join the workforce voluntarily and without coercion. The use of forced, bonded, or involuntary labor, including any form of human trafficking, is strictly prohibited.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Minimum Age Requirement</Typography>
                            Under no circumstances shall vendors employ individuals below the age of 15 or the minimum age specified by local laws, whichever is higher.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Employment Practices and Worker Validation</Typography>
                            Vendors must adhere to all legal requirements regarding employment eligibility and provide workers with a safe and fair working environment. Documentation verifying the eligibility of workers must be maintained.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Health, Safety, and Wages</Typography>
                            Vendors are expected to prioritize the health, safety, and well-being of their employees, providing adequate compensation and benefits as mandated by law. Additionally, compliance with environmental regulations is essential to ensure sustainability.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Non-Discrimination and Dignity</Typography>
                            Discrimination in any form, including but not limited to race, gender, religion, or disability, is strictly prohibited. Vendors must maintain a workplace culture that upholds the dignity and equality of all individuals.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Environmental Sustainability</Typography>
                            Vendors should actively pursue environmental sustainability measures, including efficient resource management and waste reduction.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Gifts, Hospitality, and Third-Party Representation</Typography>
                            Business gifts and hospitality should be modest, appropriate, and comply with legal and corporate guidelines. Vendors must not represent Utsav App without explicit permission and must adhere to confidentiality obligations.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Protection of Company Assets and Intellectual Property</Typography>
                            Assets, both tangible and intangible, must be utilized responsibly and protected from misappropriation. Intellectual property rights must be respected, and technology transfers should safeguard intellectual property.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Confidentiality and Privacy</Typography>
                            Vendors must maintain the confidentiality of sensitive information and respect the privacy of individuals with whom they interact.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Supply Chain Responsibility</Typography>
                            Vendors are expected to enforce ethical standards within their own supply chains, promoting fair labor practices and human rights principles.
                        </Box>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Mechanism For Addressing Grievances</Typography>
                        In our ongoing efforts to prioritize user privacy and safety, we collaborate closely with governmental authorities to ensure the well-being of our users. Below are the various avenues available for addressing grievances:

                        <Box className="ml-20">
                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">User Reporting and Complaints</Typography>
                            Users have the option to report profiles and content that violate our community guidelines. Complaints can be lodged by clicking on the three dots adjacent to the offending post/comment/user profile and selecting the report option. Additionally, users can utilize the Help & Support feature in the Settings menu to report issues.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Notification and Compliance</Typography>
                            In the event of a complaint against a user or their uploaded content, an email notification outlining the next steps will be dispatched to the concerned individual. Adherence to the provided instructions is imperative. Failure to comply may result in the permanent closure of the user's account on Temple Dekho.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Alternate Channels for Complaints</Typography>
                            Users can also lodge complaints via support@templedekho.com or the WhatsApp Support Number +91 90733 00055. Upon submission, users will receive a unique ticket number generated by our Support Team, and subsequent actions will be taken in accordance with platform policies and governmental regulations.
                        </Box>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Exemption From Liability</Typography>
                        We hereby disclaim any responsibility for losses or damages, whether direct or indirect, arising from inaccuracies, incompleteness of information, or breaches of warranties or guarantees attributable to any user of the Platform.
                        <br></br>
                        <br></br>
                        The Platform and Services are provided on an "as is" and "as available" basis, without any express or implied warranties, unless otherwise specified in writing. We do not guarantee the quality, uninterrupted provision, timeliness, security, or error-free nature of the Services or the Platform, nor their continued compatibility across devices, or correction of errors.
                        <br></br>
                        <br></br>
                        Under no circumstances shall we, our affiliates, successors, assigns, investors, directors, officers, employees, agents, service providers, or vendors be liable for any special, incidental, punitive, direct, indirect, or consequential damages arising from another user's breach of the Terms or reliance on the Services or the Platform.
                        <br></br>
                        <br></br>
                        Should any exclusion herein be deemed invalid for any reason, and we or any of our affiliates, officers, directors, or employees are found liable for loss or damage, our liability shall be limited to the charges or amounts paid to us for the use of the Platform or the Services in the month preceding the claim.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Agreement To Idemnify</Typography>
                        You hereby agree to indemnify, defend, and hold harmless us, along with our subsidiaries, affiliates, and agents, as well as their respective officers, directors, employees, successors, and assigns, from and against any and all claims, proceedings, losses, damages, liabilities, costs, demands, or expenses (including attorney's fees) arising from:

                        <ul>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">(i) Your Use of the Platform and Services: </Typography>
                                You agree to indemnify us for any claims or losses arising from your use of the Platform and Services.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">(ii) Breach of Agreement: </Typography>
                                You agree to indemnify us for any losses resulting from your failure to fulfill your obligations under this Agreement.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">(iii) Violation of Third-Party Rights: </Typography>
                                You agree to indemnify us for any claims or damages resulting from your infringement of third-party rights, including intellectual property, privacy, or consumer protection rights.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">(iv) Legal Violations: </Typography>
                                You agree to indemnify us for any losses or claims arising from violations of law or contractual obligations.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">(v) Negligence or Misconduct: </Typography>
                                You agree to indemnify us for any losses or damages caused by your negligence or willful misconduct.
                            </li>
                        </ul>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Unsolicited Submissions</Typography>
                        We welcome feedback and suggestions, but please note that if you provide us with any unsolicited material, we may use it without any obligation to compensate you and without any promise of confidentiality.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">General Disclaimers</Typography>
                        If any part of these Terms is found to be unenforceable, the remaining provisions will still apply.
                        <br></br>
                        <br></br>
                        Any changes or waivers to our Terms must be made in writing and signed by us.
                        <br></br>
                        <br></br>
                        If we choose not to enforce any part of these Terms, such as reporting illegal actions or suspending accounts, it does not mean that we waive our rights to enforce them in the future.
                        <br></br>
                        <br></br>
                        We reserve all rights that are not explicitly granted to you.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Overview of Services & Acceptence of Terms</Typography>
                        <Box className="ml-20">
                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Introduction to Services</Typography>
                            Our Services encompass digitizing temples, online puja bookings, and related offerings.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Acknowledgment and Consent to Terms</Typography>
                            By accessing, registering, or utilizing our Services, you agree to abide by these Terms of Use and the Privacy Policy. Failure to agree to these terms will result in the termination of service.
                        </Box>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Access</Typography>
                        <Box className="ml-20">
                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Service Provision</Typography>
                            We may offer you selected Services for your personal use only, not for third-party benefit. This includes any performed services, provided hardware, or downloaded widgets. Any discrepancies in service must be reported within 7 days for resolution.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Security and Usage</Typography>
                            You commit not to tamper with security features or mechanisms preventing unauthorized use or copying of restricted content on the Platform.
                        </Box>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Registration And Eligibility</Typography>
                        <Box className="ml-20">
                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Legal Capacity</Typography>
                            To access our Platform and Services, you must be capable of forming legally binding contracts under the Indian Contract Act, 1872. Minors and those deemed incompetent to contract under this act are ineligible to use our Services.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Age Requirement</Typography>
                            Individuals under the age of 18 are considered minors and are not permitted to register or use our Services. We reserve the right to deny access to anyone found ineligible.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Login Process</Typography>
                            You have the option to log in using your phone number as your unique identifier.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Prohibited Actions</Typography>
                            You agree not to provide false personal information, including fraudulent login details, or create accounts on behalf of others without explicit consent. Additionally, impersonating another individual using their login details is strictly prohibited.
                        </Box>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Your Responsibilities</Typography>

                        <ul>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">1. Understanding and Consent: </Typography>
                                You confirm that you have thoroughly read and comprehended the Terms of Use and Privacy Policy, and you agree to abide by them.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">2. Compliance with Laws: </Typography>
                                You pledge to ensure that your usage of the Platform and Services complies with all applicable laws and regulations.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">3. Limited Rights: </Typography>
                                You acknowledge that you have no ownership rights to the Platform or its underlying technology, except for the right to use them as outlined in these Terms of Use.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">4. Compliance with Terms: </Typography>
                                You commit to using the Platform and Services in a manner consistent with these Terms of Use and the Privacy Policy.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">5. Non-commercial Use: </Typography>
                                You agree not to resell or commercially exploit the Services or use them unlawfully or harmfully in any manner.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">6. Prohibited Actions: </Typography>
                                You undertake not to attempt reverse engineering, transmission of harmful software, or interference with the proper functioning of the Platform or its networks.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">7. Content Restrictions: </Typography>
                                You will refrain from posting or transmitting content that is false, harmful, defamatory, or infringes upon the rights of others.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">8. Prohibited Content: </Typography>
                                You will not promote violence, hate speech, illegal activities, or any content that may degrade individuals or groups.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">9. Disparagement: </Typography>
                                You will not make false or malicious statements against us or the Services.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">10. Interference: </Typography>
                                You will not disrupt the proper functioning of the Platform or any activities conducted on it.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">11. Bypassing Measures: </Typography>
                                You will not attempt to bypass any security measures implemented by us.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">12. Spam: </Typography>
                                You will not engage in spamming or use autoresponders on the Platform.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">13. Crawling or Spidering: </Typography>
                                You will not use software or devices to crawl or spider any part of the Services.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">14. Unauthorized Use: </Typography>
                                You will not modify, adapt, or exploit the Services in any unauthorized manner.
                            </li>
                            <li>
                                <Typography variant="body1" className="mb-10 mt-10 fw-bold">15. Preservation of Notices: </Typography>
                                You will not delete or modify any legal or proprietary notices within the Services.
                            </li>
                        </ul>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Conditions of Use</Typography>

                        <Box className="ml-20">
                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Service Availability</Typography>
                            The Services will be provided on a best-efforts basis. We will make reasonable efforts to ensure that you can use the Services without undue disruption, interruption, or delay.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Communications and Promotions</Typography>
                            Upon logging into the Platform, you may receive updates, promotional materials, and other information related to the Services or new services we may offer. By using the Platform, you consent to receiving all such communications from us. You can opt-out of these communications by contacting us at support@templedekho.com.
                            By agreeing to these Terms of Use, you also consent to us arranging a call with you on your mobile number, even if your number is registered with the DND (Do Not Disturb) service provided by your mobile service provider.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Responsibility for Device Protection</Typography>
                            We disclaim any responsibility for any harm resulting from anyone’s use of or access to the Services. If you use our Services, you are responsible for taking necessary precautions to protect yourself and your devices from malware, viruses, spyware, trojan horses, worms, trap doors, and other harmful or destructive software.
                            You also agree that we shall not be liable for any damages that may result from your use or misuse of our Platform.
                        </Box>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Intellectual Property</Typography>

                        <Box className="ml-20">
                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Ownership and Rights</Typography>
                            You agree and acknowledge that we are, and will remain, the owner of the Platform and the Services at all times. The copyright in works contained on the Platform and the Services, including but not limited to all features, functionality, software, design, text, sound recordings, and images, are our exclusive property or licensed by us, unless otherwise expressly stated. You may access the Platform as a bona fide visitor or only for your use of the Services offered.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Trademarks and Usage Restrictions</Typography>
                            All trademarks, service marks, trade names, trade dress, and other forms of intellectual property are proprietary to us. No information, code, algorithms, content, or material from the Platform or the Services may be copied, reproduced, republished, uploaded, posted, transmitted, or distributed in any way without our express written permission.
                        </Box>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Third Party Links</Typography>

                        <Box className="ml-20">
                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Accessing Third-Party Links</Typography>
                            The Platform includes links to third-party websites and/or applications. You acknowledge that when you access a third-party link that leaves the Platform:
                            <ul>
                                <li>
                                    <Typography variant="body1" className="mb-10 mt-10">1. The website or application you enter is not controlled by the Company, and different terms of use and privacy policies may apply.</Typography>
                                </li>
                                <li>
                                    <Typography variant="body1" className="mb-10 mt-10">2. The inclusion of a link does not imply any endorsement by the Company of the third-party website and/or application, the website’s and/or application’s provider, or the information on the third-party website and/or application.</Typography>
                                </li>
                                <li>
                                    <Typography variant="body1" className="mb-10 mt-10">3. If you submit any information or details on any of those websites and/or applications, such information is governed by the terms of use and privacy policies of those third-party websites and/or applications. The Company disclaims all responsibility or liability with respect to these terms of use, policies, or the websites and/or applications.</Typography>
                                </li>
                            </ul>
                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Reading Third-Party Policies</Typography>
                            You are encouraged to carefully read the terms of use and privacy policy of any third-party website and/or application that you visit. The Company reserves the right to disable third-party links from the Platform, although the Company is under no obligation to do so.
                        </Box>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Termination</Typography>

                        <Box className="ml-20">
                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Immediate Termination</Typography>
                            We may terminate your access to all or any part of the Service at any time, with or without cause, and with or without notice, effective immediately. Any suspected illegal, fraudulent, or abusive activity will also be grounds for terminating your access to the Platform and/or Services.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Rights Reserved</Typography>
                            We reserve the right, at our sole discretion, to (a) cease operating the Platform or any of the Services at any time without notice, and/or (b) terminate these Terms of Use.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Survival of Terms</Typography>
                            All provisions of these Terms of Use that by their nature should survive termination will survive termination. This includes, but is not limited to, clauses related to intellectual property (Clause 10), indemnity and limitation of liability (Clause 12), and disclaimer (Clause 14).
                        </Box>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Disclaimer</Typography>

                        <Box className="ml-20">
                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Service Scope</Typography>
                            The Services provided on the Platform are solely related to Sanatana Dharma. You agree and acknowledge that this restriction is not discriminatory towards other religions or religious practices, and it does not imply disrespect or favoritism towards Sanatana Dharma over other religions.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">No Warranty on Astrological Effects</Typography>
                            We do not warrant or take responsibility for the reality or reliability of astrological effects on human physiology or any other products or services represented and sold on the Platform. No advice or information obtained by you through the Platform will create any warranty by the Company. We do not endorse or tolerate content promoting actions involving black magic, witchcraft, voodoo, or tantrism. We do not provide medical advice or solutions for physical or mental health issues, and users should seek appropriate medical advice for such concerns.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Third-Party Service Providers</Typography>
                            The advisors, consultants, and temple partners or representatives are third-party service providers and not employees of the Platform or the Company. You connect with these providers at your own risk, and we are not responsible for any advice, information, or services they provide. We do not endorse, recommend, verify, or guarantee the validity, accuracy, completeness, safety, legality, quality, or applicability of their content or services. We will not be liable for any disputes between you and any third-party service provider. We reserve the right to terminate the services of any third-party provider at any time without liability.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">No Special Relationship</Typography>
                            We do not have a special relationship or fiduciary duty to you, except as required by law. We have no control over and no duty to act on the effects the Services may have on you.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">"As Is" Basis</Typography>
                            The Services are provided on an “as is” basis. We do not guarantee or warrant the accuracy, adequacy, correctness, validity, completeness, or suitability for any purpose of the Services, and we accept no liability or responsibility for your reliance on the statements or claims made by us while rendering our Services.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Jurisdictional Use</Typography>
                            The Company makes no representation or warranty that the content on the Platform is appropriate for use or access outside the Republic of India. Users accessing the Platform or availing the Services from outside India do so at their own risk and are responsible for complying with the laws of their jurisdiction.
                        </Box>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Governing Law</Typography>

                        <Box className="ml-20">
                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Admissibility in Proceedings</Typography>
                            A printed version of these Terms of Use and any notice given in electronic form shall be admissible in judicial or administrative proceedings to the same extent and under the same conditions as other business documents and records originally generated and maintained in printed form.

                            <Typography variant="h6" className="mb-10 mt-10 fw-bold">Jurisdiction and Applicable Law</Typography>
                            These Terms of Use shall be governed by and construed in accordance with the laws of India. You consent to the exclusive jurisdiction of the courts located in Bangalore, India. The use of the Service is not authorized in any jurisdiction that does not enforce all provisions of these Terms of Use, including this section.
                        </Box>
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Severability</Typography>
                        If any provision of these Terms of Use is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that the remaining Terms of Use will remain in full force and effect and enforceable. Failure to exercise any right herein shall not be deemed a waiver of any further rights. Waiver of compliance in any instance does not imply future waivers. For any waiver to be binding, it must be provided in writing by one of our authorized representatives.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Modifications of Terms of Use</Typography>
                        We reserve the right, at our sole discretion, to modify or replace any part of these Terms of Use, or change, suspend, or discontinue the Services (including the availability of any feature, database, or content) at any time by posting a notice or sending you notice through our Service or via your provided contact details. We may impose limits on certain features and services or restrict your access to parts or all of the Services without notice or liability. It is your responsibility to check these Terms of Use periodically for changes. Your continued use of the Services following the posting of any changes constitutes acceptance of those changes.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="h5" className="mb-10 mt-10 fw-bold">Miscellaneous</Typography>
                        Unless specified otherwise in these Terms of Use, all notices must be in writing and will be considered duly given when received or when receipt is electronically confirmed if sent by email. These Terms of Use and your use of the Services do not grant any rights or benefits to any person other than us and you. No third party is entitled to enforce any provision of these Terms of Use. We do not intend for any provision of these Terms of Use to be enforceable by a third party under any applicable law.
                    </Typography>

                    <Typography variant="body1" className="mt-30 fw-bold">
                        By using our Services, you confirm that you have fully read, understood, and voluntarily agree to all the provisions contained in these Terms of Use.
                    </Typography>

                    <Typography variant="body1" className="mb-20">
                        <Typography variant="body1" className="mt-10 fw-bold">Last updated on: </Typography>
                        OMKARA INFOTECH PRIVATE LIMITED
                        <br></br>
                        support@templedekho.com
                        
                    </Typography>

                </div>
            </Container>
        </Box>
    );
};

export default TermsAndConditions;
