import verifytick from "../../assets/verify-tick.png";
import "./VirtualPoojaBookingView.scss";
import Carousel from "react-multi-carousel";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@mui/material";
import PujaListCard from "../../components/card/Puja/PujaListCard";
import ImageViewer from "../../components/imageviewer/ImageViewer";
import templeIcon from "../../../src/assets/temple-icon.png";
import locationIcon from "../../../src/assets/loc-icon.png";
import ratingIcon from "../../../src/assets/rate-star.png";
import VirtualPujaBookDialog from "../../components/dialog/VirtualPujaBookDialog";
import {
  LOCALSTORAGE_KEY,
  VIRTUAL_PUJA_BOOKINGCAROUSAL_RESPONSIVE,
} from "../../utils/Constants";
import ShareLink from "../../components/sharelink/sharelink";
import { useEffect, useState } from "react";
import ImageViewerWithBlur from "../../components/imageviewer/ImageViewerWithBlur";
import { getTempleName } from "../../utils/HelperFunctions";
function VirtualPujaBookingView(props: any) {
  const templeProfilePic = props.templeProfilePic();
  const [urlToShare, setUrlToShare] = useState<string>("");
  const [filterPujaList, setFilterPujaList] = useState<any>([]);

  // const [selectedStaticPujaNameKey, setSelectedStaticPujaNameKey] = useState<string>('Loan-Mukti-Puja')
  // const [selectedStaticPujaPrice, setSelectedStaticPujaPrice] = useState<number>(72250)
  const savedLanguage =
    localStorage.getItem(LOCALSTORAGE_KEY.LANG_CODE) ?? "en";
  const templeTitle = getTempleName(props?.templeDetails, savedLanguage);

  useEffect(() => {
    const hostname =
      window.location.hostname === "localhost"
        ? "http://localhost:3000"
        : "https://" + window.location.hostname;
    setUrlToShare(
      `${hostname}/pagelink?pujaType=templePujas&templeNameKey=${props?.templeDetails?.nameKey}`
    );

    if (props?.templeDetails?.templePuja?.length > 0) {
      const templePuja =
        [...props?.templeDetails?.templePuja]?.sort(
          (a: any, b: any) => parseInt(b?.position || 0) - parseInt(a?.position || 0)
        ) ?? [];
      setFilterPujaList(templePuja);
    }
  }, [props.templeDetails]);

  return (
    <Box className="pageContainer pujaBookingPage">
      <Box className="pujaBookingBlock">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2} lg={2} xl={2} className="pagehdLeftBlock">
              {/* <LeftAddressCard
                header="Current Address"
                address="198/3, 3 Cross 80 Feet Road, Next.."
                footerButton="Edit or add an address"
              /> */}
            </Grid>
            <Grid item xs={12} md={8} lg={8} xl={8}>
              <Box className="pujaBookingCenterBlock">
                <Box className="pujaBookingTopBanner">
                  <Box style={{ zIndex: -10 }}>
                    {props.templeMediaList.length > 0 ? (
                      <Carousel
                        responsive={VIRTUAL_PUJA_BOOKINGCAROUSAL_RESPONSIVE}
                        swipeable={true}
                        draggable={true}
                        showDots={true}
                        keyBoardControl={true}
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                      >
                        {props.templeMediaList.map((item2: any, id: any) => {
                          return (
                            <ImageViewerWithBlur
                              id={id}
                              url={item2.pictureUrl}
                            />
                          );
                        })}
                      </Carousel>
                    ) : null}
                  </Box>
                  <Box className="shareIcon">
                    <ShareLink urlToShare={urlToShare}></ShareLink>
                  </Box>
                </Box>
                <Box className="pujaBookingTitle">
                  {/* <Typography>
                    {props.templePersonalInfo.name}{" "}
                    <ImageViewer id={0} pictureUrl={verifytick} />
                  </Typography> */}
                  <List>
                    <ListItem>
                      <ListItemAvatar sx={{ minWidth: "40px" }}>
                        <Avatar alt="Temple Icon" className="squreShape temple">
                          <img src={templeIcon}></img>
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        sx={{ display: "flex", alignItems: "center" }}
                        primary={templeTitle}
                        secondary={
                          <ImageViewer id={0} pictureUrl={verifytick} />
                        }
                      />
                    </ListItem>

                    <ListItem>
                      <ListItemAvatar sx={{ minWidth: "40px" }}>
                        <Avatar
                          alt="Location Icon"
                          className="squreShape location"
                        >
                          <img src={locationIcon}></img>
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={`${props.templePersonalInfo?.address?.area}, ${props.templePersonalInfo?.address?.state}`}
                      />
                    </ListItem>

                    <ListItem>
                      <ListItemAvatar sx={{ minWidth: "40px" }}>
                        <Avatar alt="Rating Icon" className="squreShape rating">
                          <img src={ratingIcon}></img>
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={props?.templeDetails?.googleData?.rating}
                      />
                    </ListItem>
                  </List>
                </Box>
                <Box className="templeCenterBlock">
                  {filterPujaList &&
                    filterPujaList.map((item: any, id: any) => {

                      return (
                        <PujaListCard
                        key={`pujaCard${item?.pujaId || id}`}
                          pujaData={{ pujaDetails: item }}
                          pujaName={item.pujaName}
                          templeProfilePic={
                            item?.pujaBg?.uri || templeProfilePic
                          }
                          description={item.description}
                          benefits={item.benefits}
                          acctualPrice={item.acctualPrice}
                          onClickAddPuja={props.onClickAddPuja}
                          temple={props.templePersonalInfo}
                          devoteeCount={item?.maxMemberAllowed}
                          benefitList={item?.benefitList}
                          isPujaDisable={item?.isPujaDisable}
                        />
                      );
                    })}
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              lg={2}
              xl={2}
              className="pagehdRightBlock"
            >
              {/* <RightBlockCard /> */}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <VirtualPujaBookDialog
        setAskUserToModifyCartPopUp={props.setAskUserToModifyCartPopUp}
        userConfirmUpdatePujaDetails={props.userConfirmUpdatePujaDetails}
        askUserToModifyCartPopUp={props.askUserToModifyCartPopUp}
      />
    </Box>
  );
}

export default VirtualPujaBookingView;
