import React, { useState, useRef, useEffect } from "react";
// import "./TempleDetailsView.scss";
import viewBless from "../../assets/bless-your-self.png";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  ButtonGroup,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import templeIcon from "../../assets/temple-icon.png";
import locationIcon from "../../assets/loc-icon.png";
import TitleTypography from "../../components/typography/TitleTypography";
import ParagraphTypography from "../../components/typography/ParagraphTypography";
import SubtitleTypographyPrimary from "../../components/typography/SubtitleTypographyPrimary";

import RemoveIcon from "@mui/icons-material/Remove";
import { AddIcon } from "@chakra-ui/icons";
import CloseIcon from "@mui/icons-material/Close";
import ShareLink from "../../components/sharelink/sharelink";
import SubtitleTypography from "../../components/typography/SubtitleTypography";
import "react-multi-carousel/lib/styles.css";
import "./Chadhava.scss";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import RoundedButton from "../../components/button/RoundedButton";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { disclaimerText, POP_UP_MESSAGE } from "../../utils/ConstantsMessages";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AlertDialog from "../../components/dialog/AlertDialog";
import {
  formatNumberWithK,
  formatRestrictionDates,
  getDayNameFromShortName,
  getImageListFromTemple,
  getTempleName,
} from "../../utils/HelperFunctions";
import ImageViewerWithBlur from "../../components/imageviewer/ImageViewerWithBlur";
import { LOCALIZATION } from "../../utils/localization";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  LOCALSTORAGE_KEY,
  VIRTUAL_PUJA_BOOKINGCAROUSAL_RESPONSIVE,
} from "../../utils/Constants";
import Carousel from "react-multi-carousel";

const ChadhavaDetailsView: React.FC<any> = (props) => {
  const { t, i18n } = useTranslation();
  const {
    templeProfilePic,
    templeDetails,
    templePersonalInfo,
    handleIncrement,
    handleDecrement,
    filteredChadhavaList,
    navigateToSeeVideoView,
    getAvilableChadhavaDate,
    totalPrice,
    totalPriceDisplay,
    setIsDateTimePicker,
    isSingleDatePuja,
    isDateTimePicker,
    shouldDisableDate,
    setPujaDateAndTime,
    pujaDateAndTime,
    cartInfo,
    setPopupItemAlreadyAddedInCart,
    preprocessingAndProceedToAddmemberScreen,
    popupItemAlreadyAddedInCart,
    currencySymbols,
    isCountryDisabledForBooking,
    isLoading,
  } = props;

  const templeProfilePicture = templeProfilePic();
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [isActiveTemple, setisActiveTemple] = useState<any>(false);
  // Chadhava Add Popup
  const [isPopupOpen, setPopupOpen] = useState(false);
  const popupRef = useRef<HTMLDivElement | null>(null);
  const [
    openAlertDialogForBookingNotAllowed,
    setOpenAlertDialogForBookingNotAllowed,
  ] = useState<any>(false);

  const handleClickOutside = (event: MouseEvent) => {
    if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
      setPopupOpen(false);
    }
  };

  const savedLanguage =
    localStorage.getItem(LOCALSTORAGE_KEY.LANG_CODE) ?? "en";
  const templeTitle = getTempleName(templeDetails, savedLanguage);

  useEffect(() => {
    if (isPopupOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPopupOpen]);

  const [urlToShare, setUrlToShare] = useState<string>("");
  useEffect(() => {
    const hostname =
      window.location.hostname === "localhost"
        ? "http://localhost:3000"
        : "https://" + window.location.hostname;
    setUrlToShare(
      `${hostname}/pagelink?chadhavaOftemple=${templeDetails?.nameKey}`
    );
  }, [templeDetails]);

  const [templeName, setTempleName] = useState<string>("");
  const [templeAddress, setTempleAddress] = useState<string>("");
  const [templeDescriptions, setTempleDescriptions] = useState<string>("");
  const [chadhavaBenefits, setChadhavaBenefits] = useState<any>([]);
  const { city, state } = templePersonalInfo?.address || {};

  useEffect(() => {
    if (templePersonalInfo?.name) {
      setTempleName(templePersonalInfo?.name);
    }
    if (templePersonalInfo?.descriptions) {
      setTempleDescriptions(templePersonalInfo?.descriptions);
    }
  }, [templePersonalInfo]);

  useEffect(() => {
    if (templeDetails?.chadhavaBenefits) {
      setChadhavaBenefits(templeDetails?.chadhavaBenefits);
    }
  }, [templeDetails]);

  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [isExpandedTempleDetails, setIsExpandedTempleDetails] = useState<any>(
    []
  );

  const toggleReadMore = (id: number) => {
    let tempArr = [...isExpandedTempleDetails];
    if (isExpandedTempleDetails.includes(id)) {
      tempArr = tempArr.filter((item: any) => item !== id);
    } else {
      tempArr.push(id);
    }
    setIsExpandedTempleDetails([...tempArr]);
  };
  const [wordLimit, setWordLimit] = React.useState(
    window.innerWidth < 600 ? 10 : 25
  );

  React.useEffect(() => {
    const handleResize = () => {
      setWordLimit(window.innerWidth < 450 ? 12 : 20);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const increment = (item: any) => {
    handleIncrement(item);
  };

  const decrement = (item: any) => {
    handleDecrement(item);
  };

  const addChadhavaItem = (item: any) => {
    if (isCountryDisabledForBooking) {
      setOpenAlertDialogForBookingNotAllowed(true);
    } else {
      props.addChadhavaItem(item);
    }
  };

  const [isAboveFooter, setIsAboveFooter] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const footer = document.querySelector(".footerArea");
      const button = document.querySelector(".sticky-button");
      if (footer && button) {
        const footerRect = footer.getBoundingClientRect();
        const buttonRect = button.getBoundingClientRect();
        if (
          footerRect.top < window.innerHeight &&
          footerRect.bottom >= buttonRect.bottom
        ) {
          setIsAboveFooter(true);
        } else {
          setIsAboveFooter(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  let filteredArray = filteredChadhavaList || [];

  const handleCloseAlertDialogForBookingNotAllowed = () => {
    setOpenAlertDialogForBookingNotAllowed(false);
  };

  const handleSetIsDateTimePicker = (isOpen: boolean) => {
    if (isCountryDisabledForBooking) {
      setOpenAlertDialogForBookingNotAllowed(true);
    } else {
      setIsDateTimePicker(isOpen);
    }
  };

  useEffect(() => {
    let _isActiveTemple = false;
    if (templeDetails) {
      _isActiveTemple =
        templeDetails?.isChadhavaEnabled === false ||
        templeDetails?.isClosed === true
          ? false
          : true;
    }
    setisActiveTemple(_isActiveTemple);
  }, [templeDetails]);

  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  let chadhavaImages =
    getImageListFromTemple(templePersonalInfo, "chadavaPictures") ?? [];

  return (
    <Box className="pageContainer pujaPage">
      <Box className="pujaBlock">
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            md={2}
            lg={3}
            xl={3}
            className="pagehdLeftBlock"
          ></Grid>
          <Grid item xs={12} md={8} lg={6} xl={6}>
            <Box className="pujainfoCenterBlock">
              <Box className="pujaCard">
                <Box className="pujaCardBlock">
                  {/* <Box className="pujaImgBlock">
                    <img src={templeProfilePicture} alt=""></img>
                  </Box> */}
                  {/* <ImageViewerWithBlur
                    id={"idtempleProfilePicture"}
                    url={templeProfilePicture}
                  /> */}
                  <Box style={{ zIndex: -10 }}>
                    {chadhavaImages.length > 0 ? (
                      <Carousel
                        responsive={VIRTUAL_PUJA_BOOKINGCAROUSAL_RESPONSIVE}
                        swipeable={true}
                        draggable={true}
                        showDots={true}
                        keyBoardControl={true}
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                      >
                        {chadhavaImages.map((item2: any, id: any) => {
                          return (
                            <ImageViewerWithBlur
                              key={id}
                              id={id}
                              url={item2.pictureUrl}
                            />
                          );
                        })}
                      </Carousel>
                    ) : null}
                  </Box>
                  <Box className="pujaDescription">
                    <Box className="chadhavaNameLocPrice">
                      <List>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          className="twoItem"
                        >
                          <ListItem>
                            <ListItemAvatar sx={{ minWidth: "35px" }}>
                              <Avatar
                                alt="Temple Icon"
                                className="squreShape temple"
                              >
                                <img src={templeIcon}></img>
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={templeTitle} />
                          </ListItem>
                          <Box className="shareIcon">
                            <ShareLink urlToShare={urlToShare}></ShareLink>
                          </Box>
                        </Stack>

                        <ListItem>
                          <ListItemAvatar sx={{ minWidth: "35px" }}>
                            <Avatar
                              alt="Location Icon"
                              className="squreShape location"
                            >
                              <img src={locationIcon}></img>
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            sx={{ color: "black !important" }}
                            primary={`${city}, ${state}`}
                          />
                        </ListItem>
                        <ListItem>
                          <Box className="devoteeInfomation">
                            <Typography className="countText">
                              <span>
                                {`${
                                  filteredArray && filteredArray.length > 0
                                    ? formatNumberWithK(
                                        filteredArray.length * 135
                                      )
                                    : formatNumberWithK(13 * 145)
                                } `}
                                +
                              </span>{" "}
                              {t(
                                LOCALIZATION.devotees_booked_Chadhava_from_this_temple
                              )}
                            </Typography>
                          </Box>
                        </ListItem>
                        <ListItem>
                          <Typography className="disclaimerText">
                            <ErrorOutlineIcon />
                            {/* {disclaimerText.textMessage} */}
                            {t(
                              LOCALIZATION.Prices_may_vary_depending_on_your_Country
                            )}
                          </Typography>
                        </ListItem>
                      </List>

                      {/* <Box className=" desktopOnly">
                        <Stack
                          direction="row"
                          className="btnAddChadhava"
                          spacing={2}
                          sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                            margin:"0 3%",
                          }}
                        >
                          <Typography >₹{`${props?.totalPrice}`}/-</Typography>
                          <Box>
                            <Button
                              type="button"
                              onClick={() => {
                                props.setIsDateTimePicker(true);
                                if (props?.isSingleDatePuja) {
                                  //bookedSingleDatePuja()
                                } else {
                                  props.setIsDateTimePicker(true);
                                }

                                // props.navigateToPujaDetailsAdditionScreen()
                              }}
                            >
                              Continue
                            </Button>
                          </Box>
                        </Stack>
                      </Box> */}
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box className="chadhavaItemTitle" mb={2}>
                <SubtitleTypographyPrimary
                  title={t(LOCALIZATION.Chadhava_Items)}
                ></SubtitleTypographyPrimary>
              </Box>
              {isLoading === false &&
                filteredArray?.map((item: any, index: number) => (
                  <Box
                    className={`chadhavaDetailsBlock chadhavaItems ${
                      item?.disabled === true || isActiveTemple === false
                        ? "boxDisable"
                        : ""
                    }`}
                    key={"ChadhavaItem" + index}
                  >
                    <Box className="chadhavaItem">
                      <Box className="chadhavaLeft">
                        <Box className="mostOfferTag">
                          {item?.isMostOfferedTag && (
                            <span className="offerItem">
                              {" "}
                              {t(LOCALIZATION.Most_offered)}{" "}
                            </span>
                          )}
                        </Box>
                        <Typography sx={{ fontWeight: 500 }}>
                          <span className="itemNameInfo">{item?.name}</span>
                        </Typography>

                        <Typography>
                          {/* {getAvilableChadhavaDate()} */}
                          {/* {formatRestrictionDates(item.restrictionChadavaDate)?.toString()} */}
                          {getDayNameFromShortName(item.restrictionChadavaDate)}
                        </Typography>

                        {/* {item?.disabled === true && (
                          <Typography sx={{ color: "red" }}>
                           
                            {formatRestrictionDates(item.restrictionChadavaDate)?.toString()}
                            {getDayNameFromShortName(formatRestrictionDates(item.restrictionChadavaDate))}
                          </Typography>
                        )} */}

                        <ParagraphTypography
                          title={
                            isExpandedTempleDetails.includes(item.id)
                              ? item?.itemDescription || ""
                              : `${
                                  item?.itemDescription
                                    ?.split(" ")
                                    .slice(0, wordLimit)
                                    .join(" ") || ""
                                }`
                          }
                        />
                        {item?.itemDescription.split(" ")?.length >
                          wordLimit && (
                          <Button
                            variant="text"
                            color="primary"
                            onClick={() => toggleReadMore(item.id)}
                            className="readMoreLessBtn"
                          >
                            {isExpandedTempleDetails.includes(item.id)
                              ? "Read Less"
                              : "Read More"}
                          </Button>
                        )}
                        <TitleTypography
                          title={`${currencySymbols} ${
                            item?.priceInfo?.finalPriceOv
                              ? item?.priceInfo?.finalPriceOv
                              : item?.priceInfo?.finalPrice
                          }`}
                        />
                        {/* <CalculateOverSeasPrice  inrPrice={item?.priceInfo?.finalPrice} priceFor="chadhava"/> */}
                      </Box>
                      <Box className="chadhavaRight">
                        <Box className="itemImg">
                          <img src={item?.image}></img>
                        </Box>
                        {item?.count === 0 ? (
                          <Box className="btnAdd">
                            <Button
                              className="chadhavaAdd"
                              variant="outlined"
                              onClick={(e) => {
                                e.preventDefault();
                                addChadhavaItem(item);
                              }}
                            >
                              {t(LOCALIZATION.ADD)}
                            </Button>
                          </Box>
                        ) : (
                          <Box className="addItemsBtns">
                            <ButtonGroup className="btnsGroup">
                              <Button
                                variant="contained"
                                onClick={(e) => {
                                  e.preventDefault();
                                  decrement(item);
                                }}
                              >
                                <RemoveIcon fontSize="small" />
                              </Button>
                              <Button variant="contained">{item?.count}</Button>
                              <Button
                                variant="contained"
                                onClick={() => increment(item)}
                              >
                                <AddIcon fontSize="small" />
                              </Button>
                            </ButtonGroup>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                ))}
              {isLoading === true && (
                <Box className="loadingCircle">
                  <CircularProgress color="primary" />
                </Box>
              )}

              <Box className="overViewText">
                <SubtitleTypographyPrimary
                  title={t(LOCALIZATION.Temple_Details)}
                ></SubtitleTypographyPrimary>
                <ParagraphTypography
                  title={`${templeDescriptions}`}
                ></ParagraphTypography>
              </Box>

              <Box className="templeDekhoVideo">
                <SubtitleTypographyPrimary
                  title={t(LOCALIZATION.About_Temple_Dekho)}
                ></SubtitleTypographyPrimary>
                <Typography>
                  {t(LOCALIZATION.Please_watch_our_feature_video)}
                  <span>{t(LOCALIZATION.Temple_Dekho)}</span>{" "}
                  {t(LOCALIZATION.app)}.
                </Typography>
                <Button
                  onClick={() => navigateToSeeVideoView()}
                  variant="contained"
                  className="ChadhavSeeVideoBtn"
                >
                  {t(LOCALIZATION.See_VIDEO)}
                </Button>
              </Box>

              <Box className="pujaBenefits">
                <SubtitleTypographyPrimary
                  title={t(LOCALIZATION.Benefits_of_Chadhava)}
                ></SubtitleTypographyPrimary>
                {chadhavaBenefits.map((item: any) => {
                  return (
                    <Box className="benefitsCard" key={item?.benefitId}>
                      <SubtitleTypography
                        title={`${item.name}`}
                      ></SubtitleTypography>
                      <Box className="benefitCardImg">
                        <img src={`${item.url}`} alt=""></img>
                      </Box>
                      {item.description && (
                        <ParagraphTypography
                          title={`${item.description}`}
                        ></ParagraphTypography>
                      )}
                    </Box>
                  );
                })}
              </Box>

              <Box className="chadhavaProccessSteps">
                <SubtitleTypographyPrimary
                  title={t(LOCALIZATION.Chadhava_Steps)}
                ></SubtitleTypographyPrimary>
                <List>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Box className="listCounter">
                          <Typography
                            component="span"
                            className="pujaStepsListCount"
                          >
                            1
                          </Typography>

                          <Typography>
                            {t(
                              LOCALIZATION.Select_The_Chadhava_Offering_From_The_List
                            )}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Box className="listCounter">
                          <Typography
                            component="span"
                            className="pujaStepsListCount"
                          >
                            2
                          </Typography>

                          <Typography>
                            {t(
                              LOCALIZATION.Select_your_preferred_date_for_the_Chadhava_offering
                            )}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Box className="listCounter">
                          <Typography
                            component="span"
                            className="pujaStepsListCount"
                          >
                            3
                          </Typography>

                          <Typography>
                            {t(
                              LOCALIZATION.Complete_your_booking_with_a_secure_online_payment
                            )}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Box className="listCounter">
                          <Typography
                            component="span"
                            className="pujaStepsListCount"
                          >
                            4
                          </Typography>

                          <Typography>
                            {" "}
                            {t(
                              LOCALIZATION.Our_Temple_Partner_An_Experienced_Priest
                            )}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={
                        <Box className="listCounter">
                          <Typography
                            component="span"
                            className="pujaStepsListCount"
                          >
                            5
                          </Typography>

                          <Typography>
                            {t(
                              LOCALIZATION.You_Will_Receive_Chadhava_Video_On_Your_WhatsApp
                            )}
                            <strong>
                              {" "}
                              {t(
                                LOCALIZATION.Chadhava_Items_Are_Offered_Directly_To_The_Deity
                              )}
                            </strong>
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                </List>
              </Box>

              <Box className="chadhavaFaq">
                <SubtitleTypographyPrimary
                  title={t(LOCALIZATION.Frequently_Asked_Questions)}
                ></SubtitleTypographyPrimary>

                <Box className="chadhavaFaqList">
                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel1"}
                    onChange={handleChange1("panel1")}
                    sx={{
                      "&.Mui-expanded::before": {
                        opacity: 1,
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel1" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel1"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                      }}
                    >
                      {t(
                        LOCALIZATION.Why_should_I_choose_Temple_Dekho_for_temple_Chadhava
                      )}
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      {t(
                        LOCALIZATION.Why_should_I_choose_Temple_Dekho_for_temple_ChadhavaAnswer
                      )}
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel2"}
                    onChange={handleChange1("panel2")}
                    sx={{
                      "&.Mui-expanded::before": {
                        opacity: 1,
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel2" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel2"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel2-content"
                      id="panel2-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                      }}
                    >
                      {t(LOCALIZATION.What_is_Chadhava)}
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      {t(LOCALIZATION.What_is_ChadhavaAnswer)}
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel3"}
                    onChange={handleChange1("panel3")}
                    sx={{
                      "&.Mui-expanded::before": {
                        opacity: 1,
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel3" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel3"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel3-content"
                      id="panel3-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                      }}
                    >
                      {t(LOCALIZATION.Who_designated_to_offer_the_Chadhava)}
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      {t(
                        LOCALIZATION.Who_designated_to_offer_the_ChadhavaAnswer
                      )}
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel4"}
                    onChange={handleChange1("panel4")}
                    sx={{
                      "&.Mui-expanded::before": {
                        opacity: 1,
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel4" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel4"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel4-content"
                      id="panel4-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                      }}
                    >
                      {t(LOCALIZATION.What_are_the_benefits_of_Chadhava)}
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      {t(LOCALIZATION.What_are_the_benefits_of_ChadhavaAnswer)}
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel5"}
                    onChange={handleChange1("panel5")}
                    sx={{
                      "&.Mui-expanded::before": {
                        opacity: 1,
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel5" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel5"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel5-content"
                      id="panel5-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                      }}
                    >
                      {t(
                        LOCALIZATION.I_dont_know_my_Gotram_can_I_offer_Chadhava
                      )}
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      {t(
                        LOCALIZATION.I_dont_know_my_Gotram_can_I_offer_ChadhavaAnswer
                      )}
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel6"}
                    onChange={handleChange1("panel6")}
                    sx={{
                      "&.Mui-expanded::before": {
                        opacity: 1,
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel6" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel6"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel6-content"
                      id="panel6-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                      }}
                    >
                      {t(LOCALIZATION.Could_I_receive_any_proof_of_my_Chadhava)}
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      {t(
                        LOCALIZATION.Could_I_receive_any_proof_of_my_ChadhavaAnswer
                      )}
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel7"}
                    onChange={handleChange1("panel7")}
                    sx={{
                      "&.Mui-expanded::before": {
                        opacity: 1,
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel7" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel7"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel7-content"
                      id="panel7-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                      }}
                    >
                      {t(
                        LOCALIZATION.Who_should_I_reach_out_to_for_more_information
                      )}
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      {t(
                        LOCALIZATION.Who_should_I_reach_out_to_for_more_details_FAQ_Answer
                      )}
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel8"}
                    onChange={handleChange1("panel8")}
                    sx={{
                      "&.Mui-expanded::before": {
                        opacity: 1,
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel8" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel8"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel8-content"
                      id="panel8-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                      }}
                    >
                      {t(
                        LOCALIZATION.Can_I_choose_the_temple_and_types_of_chadhava
                      )}
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      {t(
                        LOCALIZATION.Can_I_choose_the_temple_and_types_of_chadhavaAnswer
                      )}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel9"}
                    onChange={handleChange1("panel9")}
                    sx={{
                      "&.Mui-expanded::before": {
                        opacity: 1,
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel9" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel9"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel9-content"
                      id="panel9-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                      }}
                    >
                      {t(
                        LOCALIZATION.How_do_you_ensure_the_security_and_sanctity_of_my_offering
                      )}
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      {t(
                        LOCALIZATION.How_do_you_ensure_the_security_and_sanctity_of_my_offeringAnswer
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>

              {totalPrice > 0 && isActiveTemple === true && (
                <Box
                  className="chadhavaContinueBtn desktopOnly sticky-button"
                  sx={{ position: `${isAboveFooter ? "absolute" : "fixed"}` }}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography>
                      {" "}
                      {`${currencySymbols} ${
                        totalPriceDisplay ? totalPriceDisplay : totalPrice
                      }`}
                      /-
                    </Typography>
                    {/* <CalculateOverSeasPrice  inrPrice={Number(props?.totalPrice)} priceFor="chadhava-continue"/> */}
                    <Button
                      className={
                        totalPrice === 0
                          ? "desktopContinueBtnGrey"
                          : "desktopContinueBtn"
                      }
                      onClick={() => handleSetIsDateTimePicker(true)}
                      disabled={totalPrice === 0}
                    >
                      {" "}
                      {t(LOCALIZATION.Continue_to_Book)}
                    </Button>
                  </Stack>
                </Box>
              )}

              <Box className="blessContinueChadhava"></Box>
              {totalPrice > 0 && isActiveTemple && (
                <Box className="blessContinue">
                  <Box className="viewBless">
                    <img src={viewBless}></img>
                  </Box>
                  <Box className="continueCart">
                    <Box className="continueCenterBlock">
                      <Box className="continueLt">
                        {`${currencySymbols} ${
                          totalPriceDisplay ? totalPriceDisplay : totalPrice
                        }`}
                        /-
                        {/* <CalculateOverSeasPrice  inrPrice={Number(props?.totalPrice)} priceFor="chadhava-continue"/> */}
                      </Box>

                      <Box className="continueRt">
                        <Button
                          type="button"
                          disabled={totalPrice === 0}
                          className={
                            totalPrice === 0 ? "continueBtnDsbl" : "continueBtn"
                          }
                          onClick={() => {
                            if (isSingleDatePuja) {
                              //bookedSingleDatePuja()
                            } else {
                              handleSetIsDateTimePicker(true);
                            }
                          }}
                        >
                          {t(LOCALIZATION.Continue_to_Book)}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            lg={3}
            xl={3}
            className="pagehdRightBlock"
          ></Grid>
        </Grid>
        <Dialog
          className="dateTimingSelection"
          onClose={() => setIsDateTimePicker(false)}
          open={isDateTimePicker}
        >
          <Box className="dateTimePopup">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className="calendarTopInfo"
            >
              <TitleTypography
                title={t(LOCALIZATION.Select_Date)}
              ></TitleTypography>
              <IconButton
                aria-label="close"
                onClick={() => setIsDateTimePicker(false)}
                className="topCloseIconBtn"
                sx={{
                  position: "absolute",
                  right: 14,
                  top: 14,
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>

            <Box className="calendarInfo">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  shouldDisableDate={shouldDisableDate}
                  disablePast={true}
                  onChange={(value: any) => {
                    setPujaDateAndTime({
                      ...pujaDateAndTime,
                      pujaDate: value,
                    });
                  }}
                />
              </LocalizationProvider>
            </Box>
            <Box className="btnContinue">
              <RoundedButton
                title={t(LOCALIZATION.Continue)}
                disabled={
                  pujaDateAndTime && pujaDateAndTime?.pujaDate ? false : true
                }
                onClick={() => {
                  setIsDateTimePicker(false);
                  if (cartInfo && cartInfo.length > 0) {
                    setPopupItemAlreadyAddedInCart(true);
                  } else {
                    preprocessingAndProceedToAddmemberScreen();
                  }
                  //
                }}
              ></RoundedButton>
            </Box>
          </Box>
        </Dialog>

        <Dialog
          // className="dateTimingSelection"
          onClose={() => setPopupItemAlreadyAddedInCart(false)}
          open={popupItemAlreadyAddedInCart}
        >
          <Box style={{ padding: "1em" }}>
            <Typography>
              {t(LOCALIZATION.A_puja_already_added_in_your_cart)}
            </Typography>
            <Box sx={{ textAlign: "end" }}>
              <Button onClick={() => setPopupItemAlreadyAddedInCart(false)}>
                {t(LOCALIZATION.No)}
              </Button>
              <Button
                onClick={() => preprocessingAndProceedToAddmemberScreen()}
              >
                {t(LOCALIZATION.Yes)}
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>
      <AlertDialog
        open={openAlertDialogForBookingNotAllowed}
        onClose={handleCloseAlertDialogForBookingNotAllowed}
        message={
          "Oops! Bookings are not permitted in your country. We apologize for the inconvenience. Please note that bookings are accepted only in India, Great Britain, USA, and Canada."
        }
      />
    </Box>
  );
};

export default ChadhavaDetailsView;
