import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, replace } from "react-router-dom";
import { useEffect, useState } from "react";
import ParentView from "../../Views/PrarentView/ParentView";
import PromotionalChadhavaView from "../../Views/Chadhava/PromotionalChadhavaView";
import { getPromotionalChadhavaByNameKey } from "../../store/actions/ChadhawaAction";

function PromotionalChadhavaScreen(props: any) {
  const location = useLocation();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const [selectedPromotionalChadhava, setSelectedPromotionalChadhava] =
    useState<any>(null);
  const [urlToShare, setUrlToShare] = useState<any>("");
  const priceConversionState = useSelector(
    (state: any) => state.priceConversion
  );
  const {
    rate = 1,
    currencySymbols = "₹",
    isOverseas = false,
    platformFeeForChadhava,
  } = priceConversionState.priceConversionRate || {};
  
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const promotionalChadhavaId = params.get("specialChadhavaNameKey");
    if (promotionalChadhavaId) {
      dispatch(getPromotionalChadhavaByNameKey(promotionalChadhavaId as string,isOverseas,platformFeeForChadhava,rate))
      .then((res:any)=>{
        if(res){
              const hostname =
          window.location.hostname === "localhost"
            ? "http://localhost:3000"
            : "https://" + window.location.hostname;
        setUrlToShare(
          `${hostname}/pagelink?specialChadhavaNameKey=${res?.specialChadhavaNameKey}`
        );
          setSelectedPromotionalChadhava({ ...res})
        } else{
          navigate("/");
        }
        
      })
      .catch((err:any)=>{
        console.log("Error:",err)
      })
    } else{
      navigate("/");
    }

  }, [location.search,priceConversionState.priceConversionRate]);


  const handleClickOnChadhavaAdd = (templeNameKey:string, chadhavaItem:any)=>{
    if(templeNameKey){
      navigate(`/chadhavaDetails/${templeNameKey}`, {state: { chadhavaItem: { ...chadhavaItem } }, replace: true} )
    }
  }

  const handleClickOnMoreDetails = (templeNameKey:string)=>{
    if(templeNameKey){
      navigate(`/chadhavaDetails/${templeNameKey}`, {state: { chadhavaItem: null }, replace: true})
    }
  }
  return (
    <ParentView>
      <PromotionalChadhavaView
       promotionalChadhavaDetails = {selectedPromotionalChadhava}
       urlToShare={urlToShare}
       handleClickOnChadhavaAdd={handleClickOnChadhavaAdd}
       handleClickOnMoreDetails={handleClickOnMoreDetails}
      />
    </ParentView>
  );
}

export default PromotionalChadhavaScreen;
