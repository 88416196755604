import { createSlice } from "@reduxjs/toolkit";

const mangeOnboardingBenefitsSlice = createSlice({
  name: "manageOnboardingBenefits",
  initialState: {
    isLoading: false,
    error: "",
    manageOnboardingBenefitsList: [],
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setManageOnboardingBenefitsList: (state, action) => {
      state.manageOnboardingBenefitsList = action.payload;
    },
  },
});

export const { setLoading, setError, setManageOnboardingBenefitsList } =
mangeOnboardingBenefitsSlice.actions;

export default mangeOnboardingBenefitsSlice.reducer;
